import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker'; // Needed for Touch functionality of Material Components
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminDoctorService } from './pages/apps/admin-doctor/admin-doctor.service';
import { AdminUploadsViewService } from './pages/apps/admin-uploads-view/admin-uploads-view.service';
import { TranscodificationServiceProvider } from '@app.services';
import { BrowserModule } from '@angular/platform-browser';
import { NgxFileDropModule } from 'ngx-file-drop';
import { DashboardPatientComponent } from './pages/apps/dashboard-patient/dashboard-patient.component';
import { DialogShapley, TableauDeriveComponent } from './pages/apps/tableau-derive/tableau-derive.component';
import { HomeComponent } from './pages/apps/home/home.component';
import { LoginService } from './pages/custom-pages/login/login.service';
import { FileImportComponent } from './pages/apps/dashboard-import/file-import/file-import.component';
import { DashboardImportComponent } from './pages/apps/dashboard-import/dashboard-import.component';
import { ImportedFilesComponent } from './pages/apps/dashboard-import/imported-files/imported-files.component';
import { ExamResultComponent } from './pages/apps/dashboard-patient/exam-result/exam-result.component';
import { AdminDoctorComponent } from './pages/apps/admin-doctor/admin-doctor.component';
import { AdminUploadsViewComponent } from './pages/apps/admin-uploads-view/admin-uploads-view.component';
import { DashboardLastUploadsComponent } from './pages/apps/dashboard-last-uploads/dashboard-last-uploads.component';
import { TranscodificationLOINCComponent } from './pages/apps/transcodification-loinc/transcodification-loinc.component';
import { FileImportLoincComponent } from './pages/apps/transcodification-loinc/file-import-loinc/file-import.component';
import { OsWindowModule } from './os/os-window/os-window.module';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { EventListComponent } from './pages/apps/dashboard-patient/event-list/event-list.component';
import { MeasureListComponent } from './pages/apps/dashboard-patient/measure-list/measure-list.component';
import { RegisterSnackBarComponent } from './pages/custom-pages/register/register.component';
import { SharedModule } from '@app.shared';
import { ComponentsModule } from '@app.components';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { MotionDesignComponent } from './pages/custom-pages/motion-design/motion-design.component';

export function MyPaginatorIntl() {
    const paginatorIntl = new MatPaginatorIntl();
    paginatorIntl.itemsPerPageLabel = `Nombre d'éléments par page`;
    paginatorIntl.nextPageLabel = `Page suivante`;
    paginatorIntl.previousPageLabel = `Page précédente`;
    paginatorIntl.firstPageLabel = `Première page`;
    paginatorIntl.lastPageLabel = `Dernière page`;
    return paginatorIntl;
}

@NgModule({
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        BrowserModule,
        HttpClientModule,
        ComponentsModule,
        SharedModule,
        FormsModule,
        NgxFileDropModule,
        ReactiveFormsModule,
        ScrollingModule,
        CoreModule,
        AppRoutingModule,
        ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
        OsWindowModule,
    ],
    providers: [
        TranscodificationServiceProvider,
        LoginService,
        AdminDoctorService,
        AdminUploadsViewService,
        { provide: LOCALE_ID, useValue: 'fr' },
        { provide: MatPaginatorIntl, useValue: MyPaginatorIntl() }
    ],
    declarations: [
        FileImportComponent,
        FileImportLoincComponent,
        AppComponent,
        HomeComponent,
        TranscodificationLOINCComponent,
        TableauDeriveComponent,
        DashboardPatientComponent,
        DialogShapley,
        RegisterSnackBarComponent,
        DashboardImportComponent,
        ImportedFilesComponent,
        ExamResultComponent,
        EventListComponent,
        MeasureListComponent,
        AdminDoctorComponent,
        AdminUploadsViewComponent,
        DashboardLastUploadsComponent ],
    bootstrap: [ AppComponent ]
})
export class AppModule {
}
