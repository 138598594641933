<h2>Mesures cliniques</h2>
<div class="table__div">
  <bio-grid [columnDefs]="columnDefs"
            [rowData]="measureList"
            (rowClick)="edit($event, eventEditTpl)"
  ></bio-grid>
</div>
<button mat-raised-button color="primary" (click)="add(eventTpl)">Ajouter</button>

<ng-template #eventTpl>
  <app-measure-detail [measure]="newMeasure" (requestSave)="create($event)"></app-measure-detail>
</ng-template>

<ng-template #eventEditTpl>
  <app-measure-detail [measure]="editedMeasure" [editMode]="true" (requestSave)="update($event)"></app-measure-detail>
</ng-template>
