import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExamResults, IFamilyGroup, ParameterChartData, ReferentialParameter, toExamResult } from '@app.models';
import { RestApiService } from '../rest.api.service';
import { CategoryAxis, Legend, RadarChart, RadarColumnSeries, RadarCursor, RadarSeries, ValueAxis } from '@amcharts/amcharts4/charts';
import { color as amColor, create as amCreate, Scrollbar } from '@amcharts/amcharts4/core';

@Injectable({
    providedIn: 'root'
})
export class ParameterService {

    referentialParameterList: ReferentialParameter[] = [];

    constructor( private restApi: RestApiService ) {
    }

    getApplicationParameters(): Observable<ReferentialParameter[]> {
        return this.restApi.$get<ReferentialParameter[]>('api/get-references-parameter');
    }

    getReferientielParameter( parameterName: string, patientId: number ): Observable<ParameterChartData> {
        return this.restApi.$post<ParameterChartData>('api/get-chart-data', {
            parameter: parameterName,
            patient: patientId
        });
    }

    getUnlinkTableGroupList(): IFamilyGroup[] {
        return [
            { id: 'micro', label: 'Microbiologie', child: [] },
            { id: 'auto', label: 'Auto-immunité', child: [] },
            { id: 'hla', label: 'HLA', child: [] },
            { id: 'fertilite', label: 'Fertilité', child: [] },
        ];
    }

    getFamilyGroupList(): IFamilyGroup[] {
        return [
            {
                id: 'hemato',
                label: 'Hématologie',
                child: [
                    'NUMÉRATION SANGUINE', 'FORMULE LEUCOCYTAIRE'
                ]
            },
            {
                id: 'biochimie',
                label: 'Biochimie',
                child: [
                    'IONOGRAMME',
                    'LIPIDE', 'HEMOSTASE', 'ELECTROPHORESE DES PROTIDES', 'IMMUNOGLOBULINE', 'BIOCHIMIE',
                    'HORMONOLOGIE', 'ENZYMOLOGIE',
                    'PROTEINES-MARQUEURS',
                ]
            },
            // {
            //     id: 'medicaments',
            //     label: 'Médicaments',
            //     child: [
            //         'ALLERGOLOGIE', 'MESURES CLINIQUES'
            //     ]
            // },
        ];
    }

    standardDeviationToColor( std ) {
        let color = 'rgb(188, 188, 188)';
        if (Math.abs(std) > 2) {
            let red_color = 255;
            // on regle la couleur du rouge ne fonction de l'ecart type, red 255 = 2 ecart type, 10 ecart type = noir
            const value_encoded = Math.min(10, Math.abs(std));
            red_color = Math.round(255 - (value_encoded - 2) * 255 / 8);
            color = 'rgb(' + red_color + ', 0, 0)';
        }
        if (std < 2 && std > -2) {
            color = 'rgb(115, 205, 65)';
        }

        if ((std < 2 && std > 1) || (std < -1 && std > -2)) {

            color = 'rgb(255, 171, 0)';

        }
        return (color);
    }

    getExamResultData( list: ExamResults[] ) {
        const examResultsData = list.map(x => {
            const result = toExamResult(x);
            result.config = { fill: amColor(this.standardDeviationToColor(x.value_cr)) };
            return result;
        });
        examResultsData.sort(this.compareReverse);
        return examResultsData;
    }

    createRadarChart( examResultsData ) {
        const chart = amCreate('chartdiv', RadarChart);
        const data = this.getExamResultData(examResultsData);
        chart.hiddenState.properties.opacity = 0;
        /* Add data */
        data.sort(this.compare);
        chart.data = data;

        /* Create axes */
        const categoryAxis = chart.xAxes.push(new CategoryAxis() as any);
        categoryAxis.dataFields.category = 'parameter';

        const valueAxis = chart.yAxes.push(new ValueAxis() as any);

        valueAxis.startValue = -3;
        valueAxis.endValue = 3;

        /* Create and configure series */
        const seriesOpti = chart.series.push(new RadarSeries() as any);
        seriesOpti.dataFields.valueY = 'optimum';
        seriesOpti.dataFields.categoryX = 'parameter';
        seriesOpti.name = 'Optimum';
        seriesOpti.strokeWidth = 1.5;
        seriesOpti.stroke = amColor('rgb(115, 205, 65)');
        seriesOpti.zIndex = 2;

        const seriesSup = chart.series.push(new RadarSeries() as any);
        seriesSup.dataFields.valueY = 'sup';
        seriesSup.dataFields.categoryX = 'parameter';
        seriesSup.name = 'LSR';
        seriesSup.strokeWidth = 1.5;
        seriesSup.stroke = amColor('rgb(255,0,0 )');
        seriesSup.zIndex = 2;


        const seriesInf = chart.series.push(new RadarSeries() as any);
        seriesInf.dataFields.valueY = 'inf';
        seriesInf.dataFields.categoryX = 'parameter';
        seriesInf.name = 'LIR';
        seriesInf.strokeWidth = 2;
        seriesInf.stroke = amColor('rgb(255,0,0 )');
        seriesInf.zIndex = 2;


        const series2 = chart.series.push(new RadarColumnSeries() as any);
        series2.dataFields.valueY = 'value_cr';
        series2.dataFields.test = 'value_text';
        series2.dataFields.categoryX = 'parameter';
        series2.name = 'Valeur CR';
        series2.strokeWidth = 0;

        series2.columns.template.radarColumn.configField = 'config';

        series2.columns.template.tooltipText = 'Analyse: {categoryX}\nValeur Centrée Réduite: {valueY}\n Valeur: {test}';
        series2.tooltip.getFillFromObject = false;
        series2.tooltip.background.fill = amColor('rgb(55,117,166 )');
        series2.tooltip.autoTextColor = false;
        series2.tooltip.label.fill = amColor('#FFFFFF');

        series2.sequencedInterpolation = true;
        series2.sequencedInterpolationDelay = 100;
        chart.legend = new Legend();

        chart.cursor = new RadarCursor();

        chart.scrollbarY = new Scrollbar();
        chart.scrollbarY.exportable = false;

        chart.scrollbarX = new Scrollbar();
        chart.scrollbarX.exportable = false;


        valueAxis.tooltip.disabled = true;
        categoryAxis.renderer.labels.template.location = 0.5;
        categoryAxis.renderer.tooltipLocation = 0.5;

        chart.events.on('ready', function () {
            valueAxis.zoomToValues(-4, 4
            );
        });

        return chart;
    }

    compare( a, b ) {
        if (Math.abs(a.value_cr) < Math.abs(b.value_cr)) {
            return -1;
        }
        if (Math.abs(a.value_cr) > Math.abs(b.value_cr)) {
            return 1;
        }
        return 0;
    }

    compareReverse( a, b ) {
        if (Math.abs(a.value_cr) > Math.abs(b.value_cr)) {
            return -1;
        }
        if (Math.abs(a.value_cr) < Math.abs(b.value_cr)) {
            return 1;
        }
        return 0;
    }

    async init() {
        const list = await this.getApplicationParameters().toPromise();
        console.log(list)
        this.referentialParameterList = list;
    }

}
