import { CdkDragEnd, CdkDragMove } from '@angular/cdk/drag-drop';
import {
    AfterViewInit,
    Component,
    Directive,
    ElementRef, EventEmitter,
    Input,
    OnChanges,
    OnInit, Output,
    Renderer2,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { OsConfigData } from '../os-config/commons';
import { OsConfigService } from '../os-config/os-config.service';
import { clamp, osWindow, Point, setHeight, setStyle, setWidth } from './os-window.utils';

const MIN_HEIGHT = 200;
const MIN_WIDTH = 200;

@Directive({
    selector: '[window-title], [osWindowTitle]',
    exportAs: 'OsWindowTitle'
})
// tslint:disable-next-line:directive-class-suffix
export class OsWindowTitle {
}

@Directive({
    selector: 'os-window-content, [window-content], [osWindowContent]',
    exportAs: 'WindowContent'
})
// tslint:disable-next-line:directive-class-suffix
export class OsWindowContent {
}

@Component({
    selector: 'os-window',
    templateUrl: './os-window.component.html',
    styleUrls: [
        './os-window.component.scss',
        '../os-config/themes/main.scss'
    ]
})
export class OsWindowComponent implements OnInit, OnChanges, AfterViewInit {

    // References parent html element from component
    @ViewChild('osWindowParent') osWindowParent: ElementRef;
    @Output() afterResize: EventEmitter<osWindow> = new EventEmitter<osWindow>();
    @Output() afterClose: EventEmitter<osWindow> = new EventEmitter<osWindow>();

    // Stores data from OsThemeComponent
    globalConfigData: OsConfigData = {
        theme: 'arc',
        variant: 'light'
    };

    // z-index of the current window
    lastZIndex = 1;

    // Declares new OsWindow interface
    win: osWindow;
    positionStr: string[];

    //////////////////////
    ////    Inputs    ////
    //////////////////////

    //
    //   Component theme  //
    // Anchor stores temporary point of the current resize CdkDragMove event
    anchor: Point = { x: 0, y: 0 };
    newPosition: Point = { x: 0, y: 0 };
    initialHeight: number;
    initialWidth: number;
    mousePos: Point = { x: 0, y: 0 };
    @Input() startPosition: Point;

    constructor(
        private componentElement: ElementRef,
        private renderer: Renderer2,
        private globalConfigService: OsConfigService
    ) {

        //  Initializing osWindow interface
        this.win = {
            element: this.componentElement,
            minHeight: MIN_HEIGHT,
            minWidth: MIN_WIDTH,

            height: 0,
            width: 0,
            transform: '',

            setPosition: { x: 0, y: 0 },
            position: { x: 0, y: 0 },

            resize: {
                n: { x: 0, y: 0 },
                ne: { x: 0, y: 0 },
                e: { x: 0, y: 0 },
                se: { x: 0, y: 0 },
                s: { x: 0, y: 0 },
                sw: { x: 0, y: 0 },
                w: { x: 0, y: 0 },
                nw: { x: 0, y: 0 },
            },

            state: {
                zIndex: 0,
                maximized: false,
                minimized: false
            },

            rules: {
                disableResize: false,
                minimizable: true,
                maximizable: true,
                closable: true
            }
        };
    }

    //
    //   Size & position  ///

    _theme: string;

    @Input()
    get theme(): string {
        return this._theme;
    }

    set theme( v: string ) {
        this._theme = v;
    }

    _variant: string;

    @Input()
    get variant(): string {
        return this._variant;
    }

    set variant( v: string ) {
        this._variant = v;
    }

    @Input()
    get minHeight(): Number {
        return this.win.minHeight;
    }

    set minHeight( v: Number ) {
        this.win.minHeight = clamp(v || MIN_HEIGHT);
    }

    @Input()
    get minWidth(): Number {
        return this.win.minWidth;
    }

    set minWidth( v: Number ) {
        this.win.minWidth = clamp(v || MIN_WIDTH);
    }

    @Input()
    get height(): Number {
        return this.win.height;
    }

    //
    //   Rules  //

    set height( v: Number ) {
        this.win.height = clamp(v || this.win.minHeight);
    }

    @Input()
    get width(): Number {
        return this.win.width;
    }

    set width( v: Number ) {
        this.win.width = clamp(v || this.win.minWidth);
    }

    @Input()
    get position(): string {
        return;
    }

    set position( v: string ) {
        this.positionStr = v.split(' ', 2);
    }

    @Input()
    get resizable(): boolean {
        return this.win.rules.disableResize;
    }

    set resizable( v: boolean ) {
        this.win.rules.disableResize = !v;
    }

    @Input()
    get minimizable(): boolean {
        return this.win.rules.minimizable;
    }

    set minimizable( v: boolean ) {
        this.win.rules.minimizable = v;
    }

    @Input()
    get maximizable(): boolean {
        return this.win.rules.maximizable;
    }

    set maximizable( v: boolean ) {
        this.win.rules.maximizable = v;
    }

    @Input()
    get closable(): boolean {
        return this.win.rules.closable;
    }

    set closable( v: boolean ) {
        this.win.rules.closable = v;
    }

    ngOnInit(): void {
    }

    // When maximized and then dragged the window demaximizes

    ngAfterViewInit() {

        // Global theme config
        this.globalConfigData = this.globalConfigService.getConfig();

        const win = this.win;
        // Setting element as <div #osWindow> elementRef
        win.element = this.osWindowParent;

        // Setting theme of component
        if (this._theme !== '' && this._theme !== undefined && this._variant !== '' && this._variant !== undefined) {

            this.renderer.addClass(win.element.nativeElement, `${this._theme}-${this._variant}`);
        } else {

            this.renderer.addClass(win.element.nativeElement, `${this.globalConfigData.theme}-${this.globalConfigData.variant}`);
        }

        // Initial width & height, also returns corrected value if bellow minimal
        win.width = setWidth(win.element, win.width, win.minWidth);
        win.height = setHeight(win.element, win.height, win.minHeight);

        // Minimizable?
        if (!win.rules.minimizable) {
            setStyle(win.element, '--minimizeButton', 'none');
        }

        // Maximizable?
        if (!win.rules.maximizable) {
            setStyle(win.element, '--maximizeButton', 'none');
        }

        // Closable?
        if (!win.rules.closable) {
            setStyle(win.element, '--closeButton', 'none');
        }

        // Sets initial position
        this.positionWindow();

        // Resizable?
        if (win.rules.disableResize) {
            const elem = win.element;
            setStyle(elem, '--cursorN', 'auto');
            setStyle(elem, '--cursorNE', 'auto');
            setStyle(elem, '--cursorE', 'auto');
            setStyle(elem, '--cursorSE', 'auto');
            setStyle(elem, '--cursorS', 'auto');
            setStyle(elem, '--cursorSW', 'auto');
            setStyle(elem, '--cursorW', 'auto');
            setStyle(elem, '--cursorNW', 'auto');
        }
    }

    ngOnChanges( changes: SimpleChanges ): void {
    }

    ////////////////////////
    //     Resize logic    //
    ////////////////////////

    //     Position logic    //
    positionWindow() {
        const win = this.win;
        const pos0 = this.positionStr ? this.positionStr[0] : 'center';
        switch (pos0) {
            case 'left':
                win.position.x = 0;
                break;

            case 'center':
                win.position.x = (window.innerWidth / 2 - win.width / 2);
                break;

            case 'right':
                win.position.x = (window.innerWidth - win.width);
                break;

            default:
                win.position.x = 0;
                break;
        }

        // To hide the window element we need to set it top: -100% in scss,
        // so we later need to calculate everything + innerHeight
        const pos1 = this.positionStr ? this.positionStr[1] : 'center';
        switch (pos1) {
            case 'top':
                win.position.y = window.innerHeight;
                break;

            case 'center':
                win.position.y = window.innerHeight + (window.innerHeight / 2 - win.height / 2);
                break;

            case 'bottom':
                win.position.y = window.innerHeight + (window.innerHeight - win.height);
                break;

            default:
                win.position.y = window.innerHeight;
                break;
        }
        if (this.startPosition) {
            win.position = this.startPosition;
        }

        win.setPosition = {
            x: win.position.x,
            y: win.position.y
        };

    }

    //     Control Logic    //
    minimize() {

    }

    maximize() {
        if (this.win.rules.maximizable) {
            if (this.win.state.maximized == false) {

                this.renderer.addClass(this.win.element.nativeElement.firstChild, 'maximized');

                this.win.setPosition = { x: 0, y: window.innerHeight };

                this.win.state.maximized = true;
                this.win.rules.disableResize = true;
            } else {
                this.renderer.removeClass(this.win.element.nativeElement.firstChild, 'maximized');

                this.win.setPosition = {
                    x: this.win.position.x,
                    y: this.win.position.y
                };

                this.win.state.maximized = false;
                this.win.rules.disableResize = false;
            }
        }
    }

    //  and puts itself aligned with the mouse position
    demaximize() {
        if (this.win.state.maximized == true) {
            this.win.position = {
                x: (this.mousePos.x - this.win.width / 2),
                y: (this.mousePos.y + window.innerHeight - 20)
            };
            this.maximize();
        }
    }

    close() {
        this.componentElement.nativeElement.remove();
        this.afterClose.emit(this.win);
    }

    storeMousePos( event: MouseEvent ) {
        this.mousePos = {
            x: event.x,
            y: event.y
        };
        this.focus();
    }

    storeWindowPos( event: CdkDragEnd ) {
        this.win.position = event.source.getFreeDragPosition();
        this.correctEndPosition(event);
    }

    // Sets some variables when the resize drag starts, we use them later
    startResize() {
        this.initialHeight = this.win.height;
        this.initialWidth = this.win.width;

        this.newPosition = {
            x: this.win.position.x,
            y: this.win.position.y
        };
    }

    resize( dragEvent: CdkDragMove, direction: string ) {

        switch (direction) {
            // Height, Y
            case 'n':
                this.anchor = dragEvent.source.getFreeDragPosition();

                // Checks that the new position and dimesions produce a minHeight lower than the required
                if ((this.initialHeight - this.anchor.y) >= this.win.minHeight) {
                    this.newPosition = {
                        x: this.win.position.x,
                        y: this.win.position.y + this.anchor.y
                    };

                    this.win.height = this.initialHeight - this.anchor.y;
                    this.win.height = setHeight(this.win.element, this.win.height, this.win.minHeight);
                }

                // Sets the new position
                this.win.setPosition = {
                    x: this.newPosition.x,
                    y: this.newPosition.y
                };

                // Devuleve el div de resize a su posicion 0 0
                this.win.resize.n = { x: 0, y: 0 };

                break;

            // Height, Width, Y
            case 'ne':
                this.anchor = dragEvent.source.getFreeDragPosition();

                // Checks that the new position and dimesions produce a minHeight lower than the required
                if ((this.initialHeight - this.anchor.y) >= this.win.minHeight) {
                    this.newPosition = {
                        x: this.win.position.x,
                        y: this.win.position.y + this.anchor.y
                    };

                    this.win.height = this.initialHeight - this.anchor.y;
                    this.win.height = setHeight(this.win.element, this.win.height, this.win.minHeight);
                }

                // Sets the new position
                this.win.setPosition = {
                    x: this.newPosition.x,
                    y: this.newPosition.y
                };

                // Devuleve el div de resize a su posicion 0 0
                this.win.resize.ne = { x: 0, y: 0 };

                this.win.width = (this.initialWidth + this.anchor.x);
                this.win.width = setWidth(this.win.element, this.win.width, this.win.minWidth);

                break;

            // Width
            case 'e':
                this.anchor = dragEvent.source.getFreeDragPosition();

                // Devuleve el div de resize a su posicion 0 0
                this.win.resize.e = { x: 0, y: 0 };

                this.win.width = (this.initialWidth + this.anchor.x);
                this.win.width = setWidth(this.win.element, this.win.width, this.win.minWidth);

                break;

            // Width, Height
            case 'se':
                this.anchor = dragEvent.source.getFreeDragPosition();

                // Devuleve el div de resize a su posicion 0 0
                this.win.resize.se = { x: 0, y: 0 };

                this.win.width = (this.initialWidth + this.anchor.x);
                this.win.width = setWidth(this.win.element, this.win.width, this.win.minWidth);

                this.win.height = this.initialHeight + this.anchor.y;
                this.win.height = setHeight(this.win.element, this.win.height, this.win.minHeight);

                break;

            // Height
            case 's':
                this.anchor = dragEvent.source.getFreeDragPosition();

                // Devuleve el div de resize a su posicion 0 0
                this.win.resize.s = { x: 0, y: 0 };

                this.win.height = this.initialHeight + this.anchor.y;
                this.win.height = setHeight(this.win.element, this.win.height, this.win.minHeight);

                break;

            // Height, Width, X
            case 'sw':
                this.anchor = dragEvent.source.getFreeDragPosition();

                // Checks that the new position and dimesions produce a minHeight lower than the required
                if ((this.initialWidth - this.anchor.x) >= this.win.minWidth) {
                    this.newPosition = {
                        x: this.win.position.x + this.anchor.x,
                        y: this.win.position.y
                    };

                    this.win.width = this.initialWidth - this.anchor.x;
                    this.win.width = setWidth(this.win.element, this.win.width, this.win.minWidth);
                }

                // Sets the new position
                this.win.setPosition = {
                    x: this.newPosition.x,
                    y: this.newPosition.y
                };

                // Devuleve el div de resize a su posicion 0 0
                this.win.resize.sw = { x: 0, y: 0 };

                this.win.height = (this.initialHeight + this.anchor.y);
                this.win.height = setHeight(this.win.element, this.win.height, this.win.minHeight);

                break;

            // Width, X
            case 'w':
                this.anchor = dragEvent.source.getFreeDragPosition();

                // Checks that the new position and dimesions produce a minHeight lower than the required
                if ((this.initialWidth - this.anchor.x) >= this.win.minWidth) {
                    this.newPosition = {
                        x: this.win.position.x + this.anchor.x,
                        y: this.win.position.y
                    };

                    this.win.width = this.initialWidth - this.anchor.x;
                    this.win.width = setWidth(this.win.element, this.win.width, this.win.minWidth);
                }

                // Sets the new position
                this.win.setPosition = {
                    x: this.newPosition.x,
                    y: this.newPosition.y
                };

                // Devuleve el div de resize a su posicion 0 0
                this.win.resize.w = { x: 0, y: 0 };

                break;

            // Width, Height, X, Y
            case 'nw':
                this.anchor = dragEvent.source.getFreeDragPosition();

                // Checks that the new position and dimesions produce a minHeight lower than the required
                if ((this.initialWidth - this.anchor.x) >= this.win.minWidth) {
                    this.newPosition.x = (this.win.position.x + this.anchor.x);

                    this.win.width = this.initialWidth - this.anchor.x;
                    this.win.width = setWidth(this.win.element, this.win.width, this.win.minWidth);
                }

                if ((this.initialHeight - this.anchor.y) >= this.win.minHeight) {
                    this.newPosition.y = (this.win.position.y + this.anchor.y);

                    this.win.height = this.initialHeight - this.anchor.y;
                    this.win.height = setHeight(this.win.element, this.win.height, this.win.minHeight);
                }

                // Sets the new position
                this.win.setPosition = {
                    x: this.newPosition.x,
                    y: this.newPosition.y
                };

                // Devuleve el div de resize a su posicion 0 0
                this.win.resize.nw = { x: 0, y: 0 };

                break;
        }
    }

    // When the resize drag has ended sets the newPosition as the stored position
    endResize() {
        this.win.position = {
            x: this.newPosition.x,
            y: this.newPosition.y
        };
        this.afterResize.emit(this.win);
    }

    //////////////////////////////
    //   Other user interaction  //
    //////////////////////////////

    // When a window is clicked we want to change it's z-index value and apply some styles
    focus() {

        // First we increase the z-index of the clicked window
        this.lastZIndex = this.globalConfigService.getZIndex();

        if (this.win.state.zIndex != this.lastZIndex) {
            this.lastZIndex++;
            this.globalConfigService.setZIndex(this.lastZIndex);

            this.win.state.zIndex = this.lastZIndex;
            setStyle(this.win.element, '--zIndex', `${this.win.state.zIndex}`);
        }

        // After that we remove the 'focused' class from all the windows
        const focused = document.getElementsByClassName('focused');
        let i = 0;
        while (i < focused.length) {
            this.renderer.removeClass(focused[i], 'focused');
            i++;
        }

        // We add the 'focused' class to the current window
        this.renderer.addClass(this.win.element.nativeElement.firstChild, 'focused');
    }

    // When releasing the os-window the user may leave it outside of the browser window
    // which would make it imposible to interact with the component again,
    // this makes the window 'bounce' back into sight
    correctEndPosition( event: CdkDragEnd ) {
        // Fix for Y position, the window-bar will always be visible
        if (this.win.position.y < window.innerHeight) {

            this.win.position.y = window.innerHeight;

            this.win.setPosition = {
                x: this.win.position.x,
                y: this.win.position.y
            };

        } else if (this.win.position.y > (window.innerHeight * 2 - 40)) {

            this.win.position.y = (window.innerHeight * 2 - 40);

            this.win.setPosition = {
                x: this.win.position.x,
                y: this.win.position.y
            };
        }

        //  Fix for X position, a quarter of the window will always be visible
        if (this.win.position.x < -(this.win.width / 4 * 3)) {

            this.win.position.x = -(this.win.width / 4 * 3);

            this.win.setPosition = {
                x: this.win.position.x,
                y: this.win.position.y
            };

        } else if (this.win.position.x > (window.innerWidth - this.win.width / 4)) {

            this.win.position.x = (window.innerWidth - this.win.width / 4);

            this.win.setPosition = {
                x: this.win.position.x,
                y: this.win.position.y
            };
        }
        this.afterResize.emit(this.win);
    }
}


