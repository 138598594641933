import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { difference, filter, flatten, map, max, min, sortBy, uniq } from '@app.utils';
import { MatTableDataSource } from '@angular/material/table';
import { IFamilyGroup, ParameterChartData, Patient, ResultDerive } from '@app.models';
import { ActivatedRoute } from '@angular/router';
import { DialogService, ParameterService, PatientService } from '@app.services';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-tableau-derive',
    templateUrl: './tableau-derive.component.html',
    styleUrls: [ './tableau-derive.component.scss' ]
})
export class TableauDeriveComponent implements OnInit, OnChanges {
    @Input() patient: Patient;
    @Input() estimation: 0 | 1;
    @Output() requestOpen: EventEmitter<IFamilyGroup> = new EventEmitter<IFamilyGroup>();
    isCovid = false;
    greyOld = true;
    covidParameterList: string[];
    covidDCParameterList = [];
    resultDerive: ResultDerive[];
    hasData: boolean;
    patientid: any;
    text: string;
    derives: string[];
    familyGroupList: IFamilyGroup[];
    unlinkTableGroupList: IFamilyGroup[];
    familyList: string[];
    showTable: boolean;
    selectedParam: string;
    deriveBasse: ResultDerive[];
    deriveImportanteBasse: ResultDerive[];
    deriveBonneBasse: ResultDerive[];
    deriveExcellente: ResultDerive[];
    deriveHaute: ResultDerive[];
    deriveImportanteHaute: ResultDerive[];
    deriveBonneHaute: ResultDerive[];
    derivePossibleHaute: ResultDerive[];
    derivePossibleBasse: ResultDerive[];
    colors: any[];
    dataSource: MatTableDataSource<ResultDerive>;
    shapley_filter;
    score_clicked = false;
    date_start;
    date_end;
    hover_cell: any = { derive: '', famille: '' };
    modalDataResult: ParameterChartData;
    covid_scores: { label, group, risque_relatif }[] = [
        // { label: 'G1 label', group: 'G1', risque_relatif: 2 }
    ];

    constructor( private route: ActivatedRoute,
                 private patientService: PatientService,
                 private dialogService: DialogService,
                 private parameterService: ParameterService ) {
    }

    async ngOnInit() {
        this.patientid = this.route.snapshot.paramMap.get('id');
        if (!this.patient) {
            this.patient = await this.patientService.getPatientById(this.patientid).toPromise();
        }
        await this.getListeCorrelations(this.patient.id);
        this.covid_scores = this.patient.covid_score_event.scores || [];
        if (this.covid_scores.length > 0) {
            await this.showShapley(this.covid_scores[0]);
        }
        this.dataSource = new MatTableDataSource(this.resultDerive);
        this.showTable = true;
    }

    ngOnChanges( changes: SimpleChanges ) {
    }

    async showShapley( score ) {
        let res = [];
        if (score.parametre == 'SCORE_COVID_DECES') {
            res = filter(this.patient.covid_score_event.shapley, [ 'event', 'deces' ]);
        } else if (score.parametre == 'SCORE_COVID_REA') {
            res = filter(this.patient.covid_score_event.shapley, [ 'event', 'reanimation' ]);
        } else if (score.parametre == 'SCORE_COVID_VENT') {
            res = filter(this.patient.covid_score_event.shapley, [ 'event', 'ventilation' ]);
        } else if (score.parametre == 'SCORE_COVID_TRANSFU') {
            res = filter(this.patient.covid_score_event.shapley, [ 'event', 'transfusion' ]);
        } else {
            res = [];
        }
        this.shapley_filter = res;
        this.covidDCParameterList = res.map(x => x.feature);
        this.score_clicked = true;
        await this.openShapleyDialog({
            shapley_filter: res,
            parameter_list: this.covidDCParameterList,
            score: score
        });
    }

    initDerives(): void {
        this.covidParameterList = [
            'HEMOGLOBINE POC',
            'SODIUM POC',
            'CHLORURES POC',
            'PCO2 POC',
            'POTASSIUM POC',
            'OXYHEMOGLOBINE POC',
            'STANDARD BICARBONATES POC',
            'LACTATE POC',
            'EXCES DE BASE POC',
            'HEMOGLOBINE COMPOSITE',
            'CHLORE COMPOSITE',
            'SODIUM COMPOSITE',
            'PH POC',
            'BICARBONATE COMPOSITE',
            'PLAQUETTE',
            'POTASSIUM COMPOSITE',
            'HEMOGLOBINE',
            'HEMATIE',
            'ALBUMINE',
            'SODIUM',
            'POLY EOSINOPHILE',
            'CHLORE',
            'HEMATOCRITE',
            'POLY EOSINOPHILES',
            'VGM',
            'BICARBONATE',
            'PROTIDE TOTAUX',
            'CRP',
            'MAGNESIUM',
            'MONOCYTE',
            'FIBRINOGENE',
            'POTASSIUM',
            'TGO',
            'GAMMA GT',
            'PHOSPHATASE ALCALINE',
            'BILIRUBINE CONJUGUEE',
            'TAUX DE PROTHROMBINE',
            'CALCIUM',
            'TROU ANIONIQUE CALCULE',
            'ACIDE URIQUE',
            'TGP',
            'LIPASEMIE',
            'BILIRUBINE TOTALE'
        ];
        this.deriveHaute = this.getDeriveHaute();
        this.deriveBasse = this.getDeriveBasse();
        this.deriveImportanteHaute = this.getDeriveImportanteHaute();
        this.deriveExcellente = this.getDeriveExcellente();
        this.deriveBonneHaute = this.getDeriveBonneHaute();
        this.deriveImportanteBasse = this.getDeriveImportanteBasse();
        this.deriveBonneBasse = this.getDeriveBonneBasse();
        this.deriveImportanteBasse = this.getDeriveImportanteBasse();
        this.deriveBonneBasse = this.getDeriveBonneBasse();
        this.derivePossibleHaute = this.getPossibleDeriveHaute();
        this.derivePossibleBasse = this.getPossibleDeriveBasse();
        this.familyGroupList = this.parameterService.getFamilyGroupList();
        this.unlinkTableGroupList = this.parameterService.getUnlinkTableGroupList();
        this.familyList = this.getFamilyList(this.familyGroupList);
        this.derives = [ 'Supérieur valeur seuil', 'Derive Importante haute', 'Derive haute',
            'Optimum', 'Derive basse', 'Derive Importante basse', 'Inférieur valeur seuil' ];
        // this.colors = [ { derive: 'Supérieur valeur seuil', color: 'brown', size: 19 , background_color:'rgba(165,42,42,0.2)'},
        //     { derive: 'Derive Importante basse', color: 'red', size: 16 , background_color:'rgba(255,0,0,0.2)'},
        //     { derive: 'Derive basse', color: 'orange', size: 13 , background_color:'rgba(255,165,0,0.2)'},
        //     { derive: 'Optimum', color: '#72cd3f', size: 11, background_color:'rgba(114,205,63,0.2)' },
        //     { derive: 'Derive haute', color: 'orange', size: 13 , background_color:'rgba(255,165,0,0.2)'},
        //     { derive: 'Derive Importante haute', color: 'red', size: 16, background_color:'rgba(255,0,0,0.2)' },
        //     { derive: 'Inférieur valeur seuil', color: 'brown', size: 19, background_color:'rgba(165,42,42,0.2)' } ];

        this.colors = [
            {
                id: 0,
                derive: 'Inférieur valeur seuil',
                derive_label: 'Hors valeur seuil',
                color: 'brown',
                size: 19,
                background_color: 'rgba(165,42,42,0.1)',
                class_gradient: 'derive-hv-basse-gradient'
            },
            {
                id: 1,
                derive: 'Derive Importante basse',
                derive_label: 'Écart --',
                color: 'red',
                size: 16,
                background_color: 'rgba(255,0,0,0.1)',
                class_gradient: 'derive-importante-basse-gradient'
            },
            {
                id: 2,
                derive: 'Derive basse',
                derive_label: 'Écart -',
                color: '#ff5610',
                size: 16,
                background_color: 'rgba(255,127,0,0.1)',
                class_gradient: 'derive-basse-gradient'
            },
            {
                id: 3,
                derive: 'Optimum',
                derive_label: 'Optimum personnalisé',
                color: 'rgba(129, 210, 152, 1)',
                size: 11,
                background_color: 'rgba(129, 210, 152, 0.1)',
                class_gradient: 'optimum-gradient'
            },
            {
                id: 4,
                derive: 'Derive haute',
                derive_label: 'Écart +',
                color: '#ff5610',
                size: 16,
                background_color: 'rgba(255,127,0,0.1)',
                class_gradient: 'derive-haute-gradient'
            },
            {
                id: 5,
                derive: 'Derive Importante haute',
                derive_label: 'Écart ++',
                color: 'red',
                size: 16,
                background_color: 'rgba(255,0,0,0.1)',
                class_gradient: 'derive-importante-haute-gradient'
            },
            {
                id: 6,
                derive: 'Supérieur valeur seuil',
                derive_label: 'Hors valeur seuil',
                color: 'brown',
                size: 19,
                background_color: 'rgba(165,42,42,0.1)',
                class_gradient: 'derive-hv-haute-gradient'
            } ].reverse();
        this.hasData = (this.resultDerive != undefined && this.resultDerive.length > 0);
    }

    hoverFunction( derive, famille ) {
        this.hover_cell = { derive: derive, famille: famille };
    }

    async getListeCorrelations( patientId ) {
        this.resultDerive = await this.patientService.getListCorrelations(patientId).toPromise();
        console.log(this.resultDerive)
        const date_start = min(this.resultDerive.map(x => x.date));
        const date_end = max(this.resultDerive.map(x => x.date));
        this.date_start = new FormControl(new Date(date_start));
        this.date_end = new FormControl(new Date(date_end));
        this.initDerives();
    }

    getShapleyPositif() {
        return filter(this.shapley_filter, function ( o ) {
            return (o.phi > 0);
        }).map(x => x.feature);
    }

    isUrgent( resultat ) {
        if (resultat.urgent) {
            return true;
        } else {
            return false;
        }
    }

    derives_filtered() {
        if (this.estimation) {
            return this.derives.filter(x => x !== 'Optimum');
        } else {
            return this.derives;
        }
    }

    getShapleyNegatif() {
        return filter(this.shapley_filter, function ( o ) {
            return (o.phi <= 0);
        }).map(x => x.feature);
    }


    getColorDeriveGradientClass( derive ) {
        return filter(this.colors, [ 'derive', derive ])[0].class_gradient;
    }

    getDeriveBasse(): ResultDerive[] {
        return filter(this.resultDerive, [ 'etat_derive', 'Derive basse' ]);
    }

    getDeriveHaute(): ResultDerive[] {
        return filter(this.resultDerive, [ 'etat_derive', 'Derive haute' ]);
    }

    getDeriveImportanteBasse(): ResultDerive[] {
        return filter(this.resultDerive, [ 'etat_derive', 'Derive Importante basse' ]);
    }

    getDeriveImportanteHaute(): ResultDerive[] {
        return filter(this.resultDerive, [ 'etat_derive', 'Derive Importante haute' ]);
    }

    getDeriveBonneBasse(): ResultDerive[] {
        return filter(this.resultDerive, [ 'etat_derive', 'Bon bas' ]);
    }

    getPossibleDeriveBasse(): ResultDerive[] {
        return filter(this.resultDerive, [ 'etat_derive', 'Possible derive basse' ]);
    }

    getPossibleDeriveHaute(): ResultDerive[] {
        return filter(this.resultDerive, [ 'etat_derive', 'Possible derive haute' ]);
    }

    getDeriveBonneHaute(): ResultDerive[] {
        return filter(this.resultDerive, [ 'etat_derive', 'Bon haut' ]);
    }

    getDeriveExcellente(): ResultDerive[] {
        return filter(this.resultDerive, [ 'etat_derive', 'Excellent' ]);
    }

    private getFamilyList( familyGroupList: IFamilyGroup[] ): string[] {
        const family_in_derives = uniq(map(this.resultDerive, 'famille'));
        const order_famille = flatten(familyGroupList.map(x => x.child));
        const no_order_famille = difference(family_in_derives, order_famille);
        const families = order_famille.concat(no_order_famille);
        // families = families.filter(x=>family_in_derives.includes(x) )
        if (!families.includes('AUTRES')) {
            families.push('AUTRES');
        }
        return families;
    }

    getParametersByDeriveAndFamilles( derive: string, famille: string ): ResultDerive[] {
        
        const paramFiltered = filter(this.resultDerive, { estimation: this.estimation });
        
        let res = filter(paramFiltered, { etat_derive: derive, famille: famille });
        // if(famille == 'ELECTROPHORESE DES PROTIDES'){
        //     console.log(res)
        // }
        return res
    }

    getColorDerive( derive: string, alpha = 1 ) {
        return filter(this.colors, [ 'derive', derive ])[0].color;
    }

    getColorDeriveBackground( derive: string, famille: string, alpha = 1 ) {
        if (derive == this.hover_cell.derive && famille == this.hover_cell.famille) {
            return filter(this.colors, [ 'derive', derive ])[0].background_color;
        }
    }

    /**
     * get color of text of a parameter in the table
     * @param result
     */
    getColorDeriveParameter( result ) {
        // grey if derive is too old
        const moreRecentDate = new Date(max(map(this.resultDerive, x => x.date)));
        const resultDate = new Date(result.date);
        moreRecentDate.setDate(moreRecentDate.getDate() - 21);
        if (resultDate < moreRecentDate && this.greyOld) {
            return ('rgb(150,150,150)');
        } else {
            return (this.getColorDerive(result.etat_derive));
        }
    }

    getBorderStyleParameter( result ) {
        if (this.estimation) {
            if (this.isCovid && this.patient.rf_parameters.map(x => x.parameter).includes(result.parameter)
                && result.estimation == this.estimation) {
                return ('2px solid red');
            } else {
                return ('none');
            }
        } else {
            if (this.isCovid && this.getShapleyPositif().includes(result.parameter) && result.estimation == this.estimation) {
                return ('2px solid red');
            } else if (this.isCovid && this.getShapleyNegatif().includes(result.parameter) && result.estimation == this.estimation) {
                return ('2px solid green');
            } else {
                return ('none');
            }
        }

        if (this.isCovid && this.covidParameterList.includes(result.param)) {
            return ('2px solid #3F51B5');
        }
    }


    showChartIcon( derive, famille ) {
        return false;
        return derive == this.hover_cell.derive && famille == this.hover_cell.famille;
    }

    showChartIconEstimation( derive, famille ) {
        console.log(derive);
        console.log(famille);
        console.log(this.hover_cell);
        const res = derive == this.hover_cell.derive && famille == this.hover_cell.famille && this.estimation;
        console.log(res);
        return res;
    }

    emptyHoverCell() {
        this.hover_cell = { derive: '', famille: '' };
    }

    random_risk( result, derive ) {
        const condition = this.isCovid && this.score_clicked && this.covidDCParameterList.includes(result.parameter)
            && result.estimation == this.estimation;
        if (condition) {
            const score_shapley = filter(this.shapley_filter, [ 'feature', result.parameter ]).map(x => x.proba);
            return (result.libelle);
        } else {
            if (this.estimation && derive == 'Optimum') {
                return ('');
            } else {
                return (result.libelle);
            }

        }
        if (this.isCovid && this.covidParameterList.includes(result.param)) {
            return ('2px solid #3F51B5');
        }
    }

    getBorderBottom( derive: string ) {
        let result = '3px dotted #d8dfe3';
        if (derive == 'Inférieur valeur seuil') {
            result = 'none';
        }
        return (result);
    }

    getSizeFontDerive( derive: string ) {
        return filter(this.colors, [ 'derive', derive ])[0].size;
    }

    getColorBackGroundChip( score ) {
        if (score.group == 'faible') {
            return ('rgb(60, 179, 113)');
        }
        if (score.group == 'équivoque') {
            return ('rgba(255, 165, 0, 0.6)');
        }
        if (score.group == 'fort') {
            return ('rgba(255, 0, 0, 0.6)');
        }
    }

    getOpacityParameter( resultParameter ) {
        let res = 1;
        if (this.isCovid && !this.covidDCParameterList.includes(resultParameter.parameter)
            && resultParameter.estimation == this.estimation && this.estimation == 0) {
            res = 0.33;
        }
        return (res);
    }

    getBorderRadiusBottom( derive, class_obj: string = 'none' ) {
        let result = '0px';
        if (derive == 'Inférieur valeur seuil') {
            result = '25px';
        }
        if (class_obj == 'color_cell' && derive == 'Inférieur valeur seuil') {
            result = '25px';
        }
        return (result);
    }

    getBorderRadiusTop( derive, class_obj: string = 'none' ) {
        let result = '0px';
        if (derive == 'Supérieur valeur seuil') {
            result = '25px';
        }
        return (result);
    }

    closeGraphique() {
        this.selectedParam = undefined;
        this.showTable = true;
    }

    async openDialog( resultat, modalTemplate ) {
        this.selectedParam = resultat.parameter;
        this.modalDataResult = await this.parameterService
            .getReferientielParameter(this.selectedParam, this.patient.id).toPromise();
        this.modalDataResult.estimation = this.estimation;
        await this.dialogService.openTemplate(modalTemplate);
    }

    async openShapleyDialog( shapley_result ) {
        await this.dialogService.openComponent(DialogShapley, {
            shapley_result: shapley_result
        });
    }

    open( familyGroup: IFamilyGroup ) {
        if (!this.estimation) {
            this.requestOpen.emit(familyGroup);
        }
    }

}


@Component({
    selector: 'app-dialog-shapley',
    templateUrl: 'dialog-shapley.html',
})
// tslint:disable-next-line:component-class-suffix
export class DialogShapley implements OnInit {

    shapley_result: { shapley_filter, score: { risque_relatif, label, group } };

    getColor( colorName ) {
        if (colorName == 'red') {
            return ('rgba(255, 0, 0, 0.6)');
        }
        if (colorName == 'green') {
            return ('rgb(147, 250, 165)');
        }
    }

    ngOnInit() {
        this.shapley_result.shapley_filter = sortBy(this.shapley_result.shapley_filter,
            [ function ( o ) {
                return o.phi;
            } ]).reverse();
    }

    filterShapley( type ) {
        if (type == '+') {
            return filter(this.shapley_result.shapley_filter, o => o.phi > 0);
        }
        if (type == '-') {
            return filter(this.shapley_result.shapley_filter, o => o.phi <= 0).reverse();
        }
    }

}
