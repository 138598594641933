import { NgModule } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MatIconRegistry } from '@angular/material/icon';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarConfig } from '@angular/material/snack-bar';
import { DialogComponent } from './dialog/dialog.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../shared/material-components.module';
import { SidenavModule } from './layout/sidenav/sidenav.module';
import { LayoutComponent } from './layout/layout.component';
import { OsWindowModule } from '../os/os-window/os-window.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MaterialModule,
        // Core
        SidenavModule,
        OsWindowModule,
    ],
    providers: [
        MatIconRegistry,
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'fill'
            } as MatFormFieldDefaultOptions
        },
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: {
                duration: 5000,
                horizontalPosition: 'end',
                verticalPosition: 'bottom'
            } as MatSnackBarConfig
        }
    ],
    declarations: [
        DialogComponent,
        LayoutComponent
    ]
})
export class CoreModule {
}
