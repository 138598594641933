import { AfterViewInit, Component, Input, OnInit, TemplateRef } from '@angular/core';
import { Chart, ChartOptions } from 'chart.js';
import 'chartjs-adapter-date-fns';
import zoomPlugin from 'chartjs-plugin-zoom';
import * as ChartAnnotation from 'chartjs-plugin-annotation';
import { IColorsDerive, ISegment, MeasureParameter, ParameterChartData, Patient, PatientEvents, ReferentialParameter } from '@app.models';
import { filter, guessTimeInterval, IPeriodType, ITimeGuessed, map, max, min, TPeriodType, zoomOptions } from '@app.utils';
import { DialogService, PatientService } from '@app.services';
import { fr } from 'date-fns/locale';

const toTime = d => d instanceof Date ? d.getTime() : d;

@Component({
    selector: 'app-graphe-derive',
    templateUrl: './graphe-derive.component.html',
    styleUrls: [ './graphe-derive.component.css' ]
})
export class GrapheDeriveComponent implements OnInit, AfterViewInit {
    @Input() item: string;
    @Input() patient: { id, derives, SEXE, events };
    @Input() parameter: string;
    @Input() seuils: any;
    @Input() res: ParameterChartData;

    eventList: Partial<PatientEvents>[];
    referentialParameter: ReferentialParameter;
    lineChart: Chart & { options: any };
    mesures: MeasureParameter[];
    mesuresVal: number[];
    mesuresDate: string[];
    mean: number;
    ectype: number;
    colors: IColorsDerive[];
    segments: ISegment[];
    tolHaute: number;
    tolBasse: number;
    title: string;
    dateStartXaxis: string;
    dateEndXaxis: string;
    filterMesure: number[];
    showEvent = true;
    no_graph = false;
    string_no_derive = 'Pas de modèle - Utilisation des tolérances';
    derives_infos;
    ref_model_patient;
    uncertainties;
    dialogEvent;
    yIsLog = false;
    periodList: IPeriodType[] = [
        { id: 'hour', label: 'Heure', seconds: 60 * 60 },
        { id: 'day', label: 'Jour', seconds: 24 * 60 * 60 },
        { id: 'month', label: 'Mois', seconds: 31 * 24 * 60 * 60 },
        { id: 'year', label: 'Année', seconds: 365 * 24 * 60 * 60 }
    ];
    colorsDerive = [ { derive: 'Supérieur valeur seuil', color: 'brown', size: 19, background_color: 'rgba(165,42,42,0.2)' },
        { derive: 'Derive Importante basse', color: 'red', size: 16, background_color: 'rgba(255,0,0,0.2)' },
        { derive: 'Derive basse', color: 'rgba(255,0,0,0.2)', size: 13, background_color: 'rgba(255,0,0,0.2)' },
        { derive: 'Optimum', color: '#72cd3f', size: 11, background_color: 'rgba(114,205,63,0.2)' },
        { derive: 'Derive haute', color: 'rgba(255,0,0,0.2)', size: 13, background_color: 'rgba(255,0,0,0.2)' },
        { derive: 'Derive Importante haute', color: 'red', size: 16, background_color: 'rgba(255,0,0,0.2)' },
        { derive: 'Inférieur valeur seuil', color: 'brown', size: 19, background_color: 'rgba(165,42,42,0.2)' } ];
    periodTypeId: TPeriodType;
    timeGuessed: ITimeGuessed;
    timeGuessedFull: ITimeGuessed;
    newEvent: Partial<PatientEvents>;

    constructor( private patientService: PatientService, private dialogService: DialogService ) {
    }

    async ngOnInit() {
        this.derives_infos = filter(this.patient.derives, [ 'parameter[0]', this.parameter ]);
        Chart.pluginService.register(ChartAnnotation);
        Chart.pluginService.register(zoomPlugin);
        this.newEvent = {
            description: null,
            event_date: new Date(),
            patient: this.patient.id,
        };
        const res = this.res;

        this.uncertainties = this.res.data_patient.filter(x => x.lwr && x.upr);
        if (res.ref[0].parameter === 'DOES_NOT_EXIST_IN_REF') {
            this.no_graph = true;
        } else {
            this.referentialParameter = res.ref[0];
            this.setTolerances();
            this.mesures = res.data_patient;

            const refOptimizePatient = res.ref_optim[0];
            this.ref_model_patient = refOptimizePatient;
            this.ectype = refOptimizePatient.mad;
            this.mean = refOptimizePatient.mediane;
            this.title = this.createChartTitle(refOptimizePatient.model);

            this.colors = res.color_derive;
            this.segments = res.segments;
            this.mesuresVal = map(this.mesures, 'value');
            this.mesuresDate = map(this.mesures, 'date');
            this.timeGuessed = guessTimeInterval(this.mesuresDate.slice(0, 2));
            this.timeGuessedFull = guessTimeInterval(this.mesuresDate);
            this.periodTypeId = this.periodList.find(x => x.id == this.timeGuessed.type)?.id || 'day';
        }
        this.eventList = await this.patientService.getPatientEventsById(this.patient).toPromise();
        this.addEventLines(this.eventList);
    }

    ngAfterViewInit() {
        if (!this.no_graph) {
            this.drawChart(this.timeGuessed);
        }
    }

    setPeriodType( periodId ) {
        const guessedTime = guessTimeInterval(this.mesuresDate);
        const maxDate = new Date(guessedTime.max);
        const periodDuration = this.periodList.find(x => x.id == periodId)?.seconds;
        const minDate = new Date(toTime(maxDate) - periodDuration * 1000);
        this.updateTicks(minDate, maxDate);
    }

    async addEvent( eventTpl: TemplateRef<any> ) {
        this.dialogEvent = await this.dialogService.openTemplate(eventTpl);
    }

    async saveEvent( event: Partial<PatientEvents> ) {
        const newEvent = await this.patientService.createEvent$(event).toPromise();
        this.eventList.push(newEvent);
        this.drawChart(this.timeGuessed);
        this.dialogEvent.close();
    }

    setLogarithmicY() {
        this.lineChart.options.scales.yAxes[0].type = !this.yIsLog ? 'logarithmic' : 'linear';
        this.lineChart.update();
        this.yIsLog = !this.yIsLog;
    }

    createChartTitle( modelName ): string {
        if (modelName === 'SEXE') {
            return ('Optimisé par défaut par le SEXE');
        }
        return this.referentialParameter.libelle + ' (' + this.referentialParameter.unit + ')';
    }

    getPointStyle() {
        if (this.res.estimation) {
            return ('star');
        } else {
            return ('circle');
        }
    }

    usePointStyle() {
        if (this.res.estimation) {
            return (true);
        } else {
            return (false);
        }
    }


    constructChart( xminTick, xmaxTick ): void {
        const canvas: any = document.getElementById('graphCanvas');
        const ctx = canvas.getContext('2d');
        const minTick = max([ 0, min([ this.mean - 3 * this.ectype,
            min(this.mesuresVal) - this.ectype, min(this.mesures.map(x => x.min)) - 0.5 * this.ectype ]) ]);
        const maxTick = max([
            this.mean + 3 * this.ectype, max(this.mesuresVal) + this.ectype, max(this.mesures.map(x => x.max)) + 0.5 * this.ectype
        ]);

        let annotation_derive = {
            drawTime: 'afterDatasetsDraw',
            annotations: [
        // let annotation_derive = {
        //     drawTime: 'afterDatasetsDraw',
        //     annotations: [
                // {
                //     type: 'box',
                //     drawTime: 'beforeDatasetsDraw',
                //     yScaleID: 'y-axis-0',
                //     yMin: this.mean + 0.5 * this.ectype,
                //     yMax: this.mean + this.ectype,
                //     backgroundColor: 'rgba(0, 255, 0, 0.1)'
                // },
                {
                    type: 'box',
                    drawTime: 'beforeDatasetsDraw',
                    yScaleID: 'y-axis-0',
                    yMin: this.mean - 1 * this.ectype,
                    yMax: this.mean + 1 * this.ectype,
                    backgroundColor: 'rgba(0, 255, 0, 0.1)'
                },
                // {
                //     type: 'box',
                //     drawTime: 'beforeDatasetsDraw',
                //     yScaleID: 'y-axis-0',
                //     yMin: this.mean - 0.5 * this.ectype,
                //     yMax: this.mean - this.ectype,
                //     backgroundColor: 'rgba(0, 255, 0, 0.1)'
                // },
                // {
                //     type: 'box',
                //     drawTime: 'beforeDatasetsDraw',
                //     yScaleID: 'y-axis-0',
                //     yMin: this.mean + 0.5 * this.ectype,
                //     yMax: this.mean + this.ectype,
                //     backgroundColor: 'rgba(0, 255, 0, 0.1)'
                // },
                {
                    type: 'box',
                    drawTime: 'beforeDatasetsDraw',
                    yScaleID: 'y-axis-0',
                    yMin: this.mean + this.ectype,
                    yMax: this.mean + 2 * this.ectype,
                    backgroundColor: 'rgba(265, 165, 0, 0.1)'
                },
                {
                    type: 'box',
                    drawTime: 'beforeDatasetsDraw',
                    yScaleID: 'y-axis-0',
                    yMin: this.mean - this.ectype,
                    yMax: this.mean - 2 * this.ectype,
                    backgroundColor: 'rgba(265, 165, 0, 0.1)'
                },
                {
                    type: 'box',
                    drawTime: 'beforeDatasetsDraw',
                    yScaleID: 'y-axis-0',
                    yMin: this.mean - 2 * this.ectype,
                    yMax: this.mean - 3 * this.ectype,
                    backgroundColor: 'rgba(265, 0, 0, 0.1)'
                },
                {
                    type: 'box',
                    drawTime: 'beforeDatasetsDraw',
                    yScaleID: 'y-axis-0',
                    yMin: this.mean + 2 * this.ectype,
                    yMax: this.mean + 3 * this.ectype,
                    backgroundColor: 'rgba(265, 0, 0, 0.1)'
                }, {
                    drawTime: 'afterDraw',
                    id: 'a-line-1', // optional
                    type: 'line',
                    mode: 'horizontal',
                    scaleID: 'y-axis-0',
                    value: this.mean,
                    borderColor: 'rgba(198,255,76)',
                    borderWidth: 2,
                },
                {
                    type: 'box',
                    drawTime: 'beforeDatasetsDraw',
                    yScaleID: 'y-axis-0',
                    yMin: this.mean - 3 * this.ectype,
                    yMax: min([ this.mean - 1000 * this.ectype, min(this.mesuresVal) ]),
                    backgroundColor: 'rgba(165, 42, 42, 0.1)'
                },
                {
                    type: 'box',
                    drawTime: 'beforeDatasetsDraw',
                    yScaleID: 'y-axis-0',
                    yMin: this.mean + 3 * this.ectype,
                    yMax: max([ this.mean + 1000 * this.ectype, max(this.mesuresVal) ]),
                    backgroundColor: 'rgba(165, 42, 42, 0.1)'
                }
            ],
        };
        if (this.ref_model_patient.model === this.string_no_derive) {
             annotation_derive = {
                drawTime: 'afterDatasetsDraw',
                annotations: []
            };
            for (const mesureRef of this.mesures) {
                console.log(mesureRef);
                const model = {
                    type: 'box',
                    // optional drawTime to control layering, overrides global drawTime setting
                    drawTime: 'beforeDatasetsDraw',
                    xScaleID: 'x-axis-0',
                    // ID of the Y scale to bind onto
                    yScaleID: 'y-axis-0',
                    // Left edge of the box. in units along the x axis
                    xMin: mesureRef.date_start,
                    // Right edge of the box
                    xMax: mesureRef.date_end,
                    // Top edge of the box in units along the y axis
                    yMax: mesureRef.max,
                    // Bottom edge of the box
                    yMin: mesureRef.min,
                    // Stroke color
                    // Stroke width
                    // Fill color
                    backgroundColor: 'rgba(0, 255, 0, 0.1)',
                };

                annotation_derive.annotations.push(model);

                const interHVSsup = {
                    type: 'box',
                    // optional drawTime to control layering, overrides global drawTime setting
                    drawTime: 'beforeDatasetsDraw',
                    xScaleID: 'x-axis-0',
                    // ID of the Y scale to bind onto
                    yScaleID: 'y-axis-0',
                    // Left edge of the box. in units along the x axis
                    xMin: mesureRef.date_start,
                    // Right edge of the box
                    xMax: mesureRef.date_end,
                    // Top edge of the box in units along the y axis
                    yMax: max([ this.mean + 1000 * this.ectype, max(this.mesuresVal) ]),
                    // Bottom edge of the box
                    yMin: mesureRef.max,
                    // Stroke color
                    // Stroke width
                    // Fill color
                    backgroundColor: 'rgba(265, 0, 0, 0.1)',
                };

                annotation_derive.annotations.push(interHVSsup);

                const interHVSinf = {
                    type: 'box',
                    // optional drawTime to control layering, overrides global drawTime setting
                    drawTime: 'beforeDatasetsDraw',
                    // ID of the X scale to bind onto
                    xScaleID: 'x-axis-0',
                    // ID of the Y scale to bind onto
                    yScaleID: 'y-axis-0',
                    // Left edge of the box. in units along the x axis
                    xMin: mesureRef.date_start,
                    // Right edge of the box
                    xMax: mesureRef.date_end,
                    // Top edge of the box in units along the y axis
                    yMax: mesureRef.min,
                    // Bottom edge of the box
                    yMin: min([ this.mean - 1000 * this.ectype, min(this.mesuresVal) ]),
                    // Stroke color
                    // Stroke width
                    // Fill color
                    backgroundColor: 'rgba(265, 0, 0, 0.1)',
                };

                annotation_derive.annotations.push(interHVSinf);
            }


        }

        this.lineChart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: this.mesuresDate,
                datasets: [ {
                    pointStyle: this.getPointStyle(),
                    label: this.parameter,
                    fill: false,
                    data: this.filterMesure,
                    borderColor: [
                        'rgba(0,0, 0,1)',
                    ],
                    pointRadius: 5,
                    pointHoverRadius: 8,
                    pointBackgroundColor: 'rgba(135,206, 250,1)',
                } ]
            },

            options: {
                animation: {
                    duration: 0 // general animation time
                },
                hover: {
                    animationDuration: 0 // duration of animations when hovering an item
                },
                responsiveAnimationDuration: 0,

                plugins: {
                    zoom: zoomOptions(),
                    legend: {

                        labels: {
                            usePointStyle: false
                        }
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
                title: {
                    display: true,
                    text: this.title
                },
                legend: {
                    display: false,
                    position: 'bottom'
                },
                showLines: false,
                scales: {
                    yAxes: [ {

                        gridLines: {
                            color: 'rgba(0, 0, 0, 0)',
                        },
                        scaleLabel: {
                            display: true,
                            labelString: this.res.ref[0].unit

                        }
                        , ticks: {
                            min: minTick,
                            max: maxTick,
                            maxTicksLimit: 8,
                            autoSkip: true,
                            autoSkipPadding: 50,
                            callback: function ( value, index, ticks ) {
                                const length_ticks = ticks.length;
                                if (index != 0 && index != length_ticks - 1) {
                                    return value;
                                } else {
                                    return '';
                                }

                            }

                        },
                    } ],
                    xAxes: [ {
                        adapters: {
                            date: {
                                locale: fr,
                            }
                        },
                        ticks: {
                            maxTicksLimit: 10,
                            min: xminTick,
                            max: xmaxTick,
                        },
                        type: 'time',
                        time: {
                            displayFormats: {
                                'millisecond': 'HH:ii:ss dd MMM yyyy',
                                'second': 'HH:ii:ss dd MMM yyyy',
                                'minute': 'HH:ii dd MMM yyyy',
                                'hour': 'HH:ii dd MMM yyyy',
                                'day': 'dd MMM yyyy',
                                'week': ' dd MMM yyyy',
                                'month': 'MMM yyyy',
                                'quarter': 'MMM yyyy',
                                'year': 'yyyy',
                            }
                        },
                        gridLines: {
                            color: 'rgba(0, 0, 0, 0)',
                            display: false
                        }
                    } ],
                },
                annotation: annotation_derive
            } as ChartOptions
        });
        // add segment into chart
        for (const segment of this.segments) {
            this.addSegment(segment);
        }

        for (const mesureRef of this.mesures) {
            this.addSegmentRef(mesureRef);
        }

         for (const uncertainty of this.uncertainties) {
             this.addUncertainty(uncertainty);
         }
        if (this.showEvent) {
            if (this.eventList) {
                this.addEventLines(this.eventList);
            }
        }
    }

    toggleLabel( ctx, event ) {
        console.log(ctx);
        console.log(event);
        // const oneThirdWidth = ctx.element.width / 3;
        // const chart = ctx.chart;
        // const annotationOpts = this.lineChart.options.plugins.annotation.annotations.annotation;
        // annotationOpts.label.enabled = !annotationOpts.label.enabled;
        // annotationOpts.label.position = (event.x / ctx.chart.chartArea.width * 100) + '%';
        // this.lineChart.update();
    }

    resetZoom() {
        this.drawChart(this.timeGuessed);
    }

    zoomShowAll() {
        const fullTimeGuessed = guessTimeInterval(this.mesuresDate);
        fullTimeGuessed.padding = 10;
        this.periodTypeId = this.periodList.find(x => x.id == fullTimeGuessed.type)?.id || 'day';
        console.log(fullTimeGuessed);
        this.drawChart(fullTimeGuessed);
    }

    drawChart( timeGuessed: ITimeGuessed ) {
        this.filterMesure = this.mesuresVal;
        let min_max_interval = timeGuessed.max - timeGuessed.min;
        // cas ou l'on a qu'une seule mesure
        if (min_max_interval == 0) {
            min_max_interval = 86400000;
        }
        const dateStartXaxisDate = new Date(timeGuessed.min - min_max_interval / timeGuessed.padding);
        this.dateStartXaxis = dateStartXaxisDate.toString();
        const dateEndXaxisDate = new Date(timeGuessed.max + min_max_interval / timeGuessed.padding);
        this.dateEndXaxis = dateEndXaxisDate.toString();
        if (this.lineChart) {
            this.lineChart.destroy();
        }
        this.constructChart(dateStartXaxisDate, dateEndXaxisDate);
    }

    addEventLines( eventList ) {
        for (const elem of eventList) {
            const lineToAdd = {
                drawTime: 'afterDatasetsDraw',
                type: 'line',
                mode: 'vertical',
                scaleID: 'x-axis-0',
                value: new Date(elem.event_date),
                borderWidth: 1,
                borderColor: '#5EC4E1',
                linetype: 'dotted',
                label: {
                    content: elem.event_label,
                    enabled: true,
                    position: 'bottom'
                }
            };
            this.lineChart.options.annotation.annotations.push(lineToAdd);
        }
        this.lineChart.update();
    }

    addSegment( segment: ISegment ) {
        const rgba = filter(this.colors, { 'Idderive': segment.nuance });
        const red = map(rgba, 'rgbaR')[0];
        const blue = map(rgba, 'rgbaB')[0];
        const green = map(rgba, 'rgbaG')[0];
        const color = 'rgba(' + red + ',' + green + ',' + blue + ',1)';
        // const color = filter(this.colorsDerive, [ 'derive', segment.etat_derive ])[0].color;
        const model = {
            type: 'box',
            // optional drawTime to control layering, overrides global drawTime setting
            drawTime: 'afterDatasetsDraw',
            // ID of the X scale to bind onto
            xScaleID: 'x-axis-0',
            // ID of the Y scale to bind onto
            yScaleID: 'y-axis-0',
            // Left edge of the box. in units along the x axis
            xMin: segment.debut_x,
            // Right edge of the box
            xMax: segment.fin_x,
            // Top edge of the box in units along the y axis
            yMax: segment.debut_y,
            // Bottom edge of the box
            yMin: segment.debut_y,
            // Stroke color
            borderColor: color,
            // Stroke width
            borderWidth: 3,
            // Fill color
            backgroundColor: 'green',
        };


        this.lineChart.options.annotation.annotations.push(model);
        this.lineChart.update();
    }

    addSegmentRef( segment: MeasureParameter ) {
        const color = 'red';
        if (segment.min !== null) {
            const model = {
                type: 'box',
                // optional drawTime to control layering, overrides global drawTime setting
                drawTime: 'afterDatasetsDraw',
                // ID of the X scale to bind onto
                xScaleID: 'x-axis-0',
                // ID of the Y scale to bind onto
                yScaleID: 'y-axis-0',
                // Left edge of the box. in units along the x axis
                xMin: segment.date_start,
                // Right edge of the box
                xMax: segment.date_end,
                // Top edge of the box in units along the y axis
                yMax: segment.min,
                // Bottom edge of the box
                yMin: segment.min,
                // Stroke color
                borderColor: color,
                // Stroke width
                borderWidth: 4,
                borderDash: [ 5, 5 ],
                // Fill color
                backgroundColor: 'green',
            };

            this.lineChart.options.annotation.annotations.push(model);

            const modelVerticalUp = {
                type: 'box',
                // optional drawTime to control layering, overrides global drawTime setting
                drawTime: 'afterDatasetsDraw',
                // ID of the X scale to bind onto
                xScaleID: 'x-axis-0',
                // ID of the Y scale to bind onto
                yScaleID: 'y-axis-0',
                // Left edge of the box. in units along the x axis
                xMin: segment.date_end,
                // Right edge of the box
                xMax: segment.date_end,
                // Top edge of the box in units along the y axis
                yMax: segment.min_next,
                // Bottom edge of the box
                yMin: segment.min,
                // Stroke color
                borderColor: color,
                // Stroke width
                borderWidth: 4,
                borderDash: [ 5, 5 ],
                // Fill color
                backgroundColor: 'green',
            };


            this.lineChart.options.annotation.annotations.push(modelVerticalUp);
        }


        if (segment.max !== null) {

        const modelupr = {
            type: 'box',
            // optional drawTime to control layering, overrides global drawTime setting
            drawTime: 'afterDatasetsDraw',
            // ID of the X scale to bind onto
            xScaleID: 'x-axis-0',
            // ID of the Y scale to bind onto
            yScaleID: 'y-axis-0',
            // Left edge of the box. in units along the x axis
            xMin: segment.date_start,
            // Right edge of the box
            xMax: segment.date_end,
            // Top edge of the box in units along the y axis
            yMax: segment.max,
            // Bottom edge of the box
            yMin: segment.max,
            // Stroke color
            borderColor: color,
            // Stroke width
            borderWidth: 4,
            borderDash: [ 5, 5 ],
            // Fill color
            backgroundColor: 'green',
        };


        this.lineChart.options.annotation.annotations.push(modelupr);


        const modelVerticalDown = {
            type: 'box',
            // optional drawTime to control layering, overrides global drawTime setting
            drawTime: 'afterDatasetsDraw',
            // ID of the X scale to bind onto
            xScaleID: 'x-axis-0',
            // ID of the Y scale to bind onto
            yScaleID: 'y-axis-0',
            // Left edge of the box. in units along the x axis
            xMin: segment.date_end,
            // Right edge of the box
            xMax: segment.date_end,
            // Top edge of the box in units along the y axis
            yMax: segment.max_next,
            // Bottom edge of the box
            yMin: segment.max,
            // Stroke color
            borderColor: color,
            // Stroke width
            borderWidth: 4,
            borderDash: [ 5, 5 ],
            // Fill color
            backgroundColor: 'green',
        };


        this.lineChart.options.annotation.annotations.push(modelVerticalDown);
    }
        this.lineChart.update();
    }


    addUncertainty( uncertainty ) {

        const model = {
            type: 'box',
            // optional drawTime to control layering, overrides global drawTime setting
            drawTime: 'afterDatasetsDraw',
            // ID of the X scale to bind onto
            xScaleID: 'x-axis-0',
            // ID of the Y scale to bind onto
            yScaleID: 'y-axis-0',
            // Left edge of the box. in units along the x axis
            xMin: uncertainty.date,
            // Right edge of the box
            xMax: uncertainty.date,
            // Top edge of the box in units along the y axis
            yMax: uncertainty.upr,
            // Bottom edge of the box
            yMin: uncertainty.lwr,
            // Stroke color
            // Stroke width
            borderWidth: 1,
            // Fill color
        };


        this.lineChart.options.annotation.annotations.push(model);
        this.lineChart.update();
    }

    setTolerances() {
        if (this.seuils && this.seuils.min && this.seuils.max && this.seuils.unit === this.referentialParameter.unit) {
            this.tolBasse = this.seuils.min;
            this.tolHaute = this.seuils.max;
        } else {
            if (this.patient.SEXE[0] === 'F') {
                this.tolBasse = this.referentialParameter.Tolerance_labo_base_Femme;
                this.tolHaute = this.referentialParameter.Tolerance_labo_haute_Femme;
            } else {
                this.tolBasse = this.referentialParameter.Tolerance_labo_base_Homme;
                this.tolHaute = this.referentialParameter.Tolerance_labo_haute_Homme;
            }
        }
    }

    zoomOut() {
        this.zoom(2);
    }

    zoomIn() {
        this.zoom(0.5);
    }

    prev() {
        this.shift(-0.5);
    }

    next() {
        this.shift(0.5);
    }

    shift( coeff: number ) {
        const xTicks = this.xAxe.ticks;
        const minD = xTicks.min;
        const maxD = xTicks.max;
        const rangeSrc = (toTime(maxD) - toTime(minD)) * coeff;
        this.updateTicks(new Date(toTime(minD) + rangeSrc), new Date(toTime(maxD) + rangeSrc));
    }

    private get xAxe(): { ticks: { min, max } } {
        return this.lineChart.options.scales.xAxes[0];
    }

    private zoom( coeff = 0.5 ) {
        const xTicks = this.xAxe.ticks;
        const minD = xTicks.min;
        const maxD = xTicks.max;
        if (!maxD || !minD) {
            return;
        }
        const rangeSrc = toTime(maxD) - toTime(minD);
        const rangeDst = rangeSrc * coeff;
        const diff = (rangeDst - rangeSrc) / 2;
        // limite xmin and xmax zoom
        const date_min = toTime(minD) - diff;
        const date_max = toTime(maxD) + diff;
        const min_max_interval = (this.timeGuessedFull.max - this.timeGuessedFull.min) / 10;
        this.updateTicks(
            new Date(max([ date_min, this.timeGuessedFull.min - min_max_interval ])),
            new Date(min([ date_max, this.timeGuessedFull.max + min_max_interval ]))
        );
    }

    private updateTicks( minDate: Date, maxDate: Date ) {
        const xAxe = this.xAxe;
        xAxe.ticks.min = minDate;
        xAxe.ticks.max = maxDate;
        this.lineChart.update();
    }

}
