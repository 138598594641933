import { Injectable } from '@angular/core';
import { RestApiService } from '../rest.api.service';
import { Observable } from 'rxjs';

@Injectable()
export class TranscodificationServiceProvider {

    constructor( public apiRestService: RestApiService ) {
    }

    post_transcodification( parameter_label: string, values, return_all_loinc_random ): Observable<any> {
        return this.apiRestService.$post<any>('api/post-transcodifications', {
            parameter_label,
            values
        });
    }

    get_loinc_all( filter_value: string ): Observable<any> {
        return this.apiRestService.$post<any>('api/get-loinc-all');
    }

    post_loinc_filter( filter_value: string ): Observable<any> {
        return this.apiRestService.$post<any>('api/post-loinc-filter', {
            filter:filter_value
        });
    }

    post_get_available_file( client: string ): Observable<any> {
        return this.apiRestService.$post<any>('api/post-loinc-resultat-files', {
            client:client
        });
    }

    post_get_loinc_table( client: string, fileName:string ): Observable<any> {
        return this.apiRestService.$post<any>('api/post-loinc-table', {
            client:client, 
            file_name: fileName
        });
    }

    post_save_choice_loinc( client: string, fileName:string, id:string, loinc, comment, state): Observable<any> {
        return this.apiRestService.$post<any>('api/post-save-choice-loinc', {
            client: client, 
            file_name: fileName,
            id: id,
            loinc: loinc,
            comment: comment,
            state: state
        });
    }

    post_update_comment_multiple_loinc( client: string, fileName:string, ids:string[], state): Observable<any> {
        return this.apiRestService.$post<any>('api/post-update-comment-multiple-loinc', {
            client: client, 
            file_name: fileName,
            ids: ids,
            state: state
        });
    }

    post_get_transcodification( client: string, fileName:string , id:string, howMany:number, return_all=0): Observable<any> {
        return this.apiRestService.$post<any>('api/post-get-transcodification', {
            client:client, 
            file_name: fileName,
            id: id,
            howMany:howMany,
            return_all:return_all
        });
    }

    post_get_all_loinc(): Observable<any> {
        return this.apiRestService.$get<any>('api/get-all-loinc',);
    }

    post_density( values ): Observable<any[]> {
        return this.apiRestService.$post<any[]>('api/post-density', { values });
    }

}
