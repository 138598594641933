import { Observable, Subject } from 'rxjs';
import { RestApiService } from './rest.api.service';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    newEmergencies$: Observable<number>;

    private emergencies: Subject<number> = new Subject<number>();
    private lastDate: number;
    private timeout: number;

    constructor( private restApi: RestApiService ) {
        this.newEmergencies$ = this.emergencies.asObservable();
    }

    private getUrgentNotReadCount$( userId: number ): Observable<any> {
        return this.restApi.$get(`api/get-urgent-not-read-count/${userId}`);
    }

    async init( userId: number ) {
        const list = await this.getUrgentNotReadCount$(userId).toPromise();
        this.emergencies.next(list.nb_urgent);
        this.listenFor(userId);
    }

    private listenFor( userId: number ) {
        this.lastDate = Math.floor((new Date()).getTime() / 1000);
        this.timeout = setTimeout(async () => {
            try {
                const list = await this.getUrgentNotReadCount$(userId).toPromise();
                this.emergencies.next(list.nb_urgent);
            } catch (err) {
            }
            this.listenFor(userId);
        }, 30000);
    }

}
