export class PatientEvents {
    id: number;
    event_label: string;
    description: string;
    event_type: string;
    event_type_label: string;
    patient: number;
    event_date: string|Date;
    event_duration: number;
    start_date: string|Date;
    end_date: string|Date;
    created_at: string;
    modified_at: string;
}
