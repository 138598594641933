import { Component, OnInit } from '@angular/core';
import { AuthService, NotificationService } from '@app.services';
import { SidenavItem } from './sidenav-item/sidenav-item.interface';
import { Router } from '@angular/router';

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: [ './sidenav.component.scss' ],
})
export class SidenavComponent implements OnInit {

    homeItem: SidenavItem = {
        name: 'Accueil',
        routeOrFunction: '/home',
        icon: 'home',
    };
    patientItem: SidenavItem = {
        name: 'Patients',
        routeOrFunction: '/patients',
        icon: 'people',
    };
    items: SidenavItem[];
    disconnectItem = {
        name: 'Deconnexion',
        routeOrFunction: () => this.logout(),
        icon: 'logout',
    };
    emergenciesCount = 0;
    constructor( private authService: AuthService,
                 private notificationService: NotificationService,
                 private router: Router ) {
    }

    async ngOnInit() {
        const menu = [];
        const u = this.authService.getUser();
        if (u) {
            this.notificationService.newEmergencies$.subscribe(x => {
                this.emergenciesCount = x;
            });
            await this.notificationService.init(u.id);
        }
        if (this.authService.isAdmin()) {
            menu.push({
                name: 'Import',
                routeOrFunction: '/import',
                icon: 'move_to_inbox',
            });
            menu.push({
                name: 'Transcodification LOINC',
                routeOrFunction: 'transcodification-loinc',
                icon: 'notifications',
            });
            menu.push({
                name: 'Notifications',
                icon: 'notifications',
            });
            menu.push({
                name: 'Admin - Labels et unités',
                routeOrFunction: '/label',
                icon: 'emoji_symbols',
            });
            menu.push({
                name: 'Admin - Docteurs',
                routeOrFunction: '/admin-doctor-view',
                icon: 'people',
            });
            menu.push({
                name: 'Admin - Suivi des uploads',
                routeOrFunction: '/admin-uploads-view',
                icon: 'poll',
            });
            this.items = menu;
        }
    }

    async logout() {
        await this.authService.logout();
        await this.router.navigate([ '/login' ]);
    }

}
