<svg class="svgMenu"><use xlink:href="assets/sprite.svg#back" /></svg>
<div class="card">
  <img class="blbLogo" src="/assets/img/logo_text.png">
  <div class="padding" [formGroup]="form">
    <div>
      <mat-form-field>
        <mat-label>{{ 'NAME' | translate }}</mat-label>
        <input matInput required formControlName="name">
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'FIRST_NAME' | translate }}</mat-label>
        <input matInput required formControlName="firstName">
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'EMAIL' | translate }}</mat-label>
        <input matInput required formControlName="email">
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'PASSWORD' | translate }}</mat-label>
        <input matInput [type]="inputType" formControlName="password" required>
        <button type="button" mat-icon-button matSuffix (click)="toggleVisibility()" matTooltip="Toggle Visibility">
          <mat-icon>{{visible ? 'visibility' : 'visibility_off'}}</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'PASSWORD_CONFIRM' | translate }}</mat-label>
        <input matInput [type]="inputType" formControlName="passwordConfirm" required>
        <button type="button" mat-icon-button matSuffix (click)="toggleVisibility()" matTooltip="Toggle Visibility">
          <mat-icon>{{visible ? 'visibility' : 'visibility_off'}}</mat-icon>
        </button>
        <!-- <mat-hint>{{ 'PLEASE_REPEAT_PASSWORD_ABOVE' | translate }}</mat-hint> -->
      </mat-form-field>
    </div>
    <button color="primary" [disabled]="form.invalid" mat-raised-button
            (click)="send()">
      {{ 'CREATE_ACCOUNT' | translate }}
    </button>
    <h4 *ngIf="response!=''">{{response}}</h4>
    <p class="secondary-text text-center">
      {{ 'ALREADY_HAVE_ACCOUNT' | translate }}<br/>
      <a [routerLink]="['/login']">{{ 'SIGN_IN_HERE'| translate }}</a>
    </p>
  </div>
</div>
