import { Pipe, PipeTransform } from '@angular/core';
import { MESSAGES } from '@app.messages';

@Pipe({
    name: 'translate',
})
export class TranslatePipe implements PipeTransform {

    public transform( value: any, ...args: any[] ) {
        return MESSAGES[value] || value;
    }

}
