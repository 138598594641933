import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OsWindowComponent, OsWindowContent, OsWindowTitle } from './os-window.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
    declarations: [
        OsWindowComponent,
        OsWindowContent,
        OsWindowTitle
    ],
    imports: [
        CommonModule,
        DragDropModule,
    ],
    exports: [
        OsWindowComponent,
        OsWindowContent,
        OsWindowTitle
    ]
})
export class OsWindowModule {
}
