<h4 mat-dialog-title>
  {{shapley_result.score.label}}: {{shapley_result.score.group}}
  (x{{shapley_result.score.risque_relatif | number : '1.1-1'}})
</h4>

<div class="fxBetween">
  <div class="fxGrow">
    <div class="card">
      <div class="row">
        <mat-chip [style.background-color]="getColor('red')">Paramètres facteurs de risques</mat-chip>
      </div>
      <div class="card">
        <div>
          <p *ngFor="let score of filterShapley('+')"><b>{{score.libelle}} :</b>    {{score.value}} {{score.unit}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="fxGrow">
    <div class="card">
      <div class="row">
        <mat-chip [style.background-color]="getColor('green')">Paramètres protecteurs</mat-chip>
      </div>
      <div class="card">
        <div>
          <p *ngFor="let score of filterShapley('-')"><b>{{score.libelle}} :</b>   {{score.value}} {{score.unit}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
