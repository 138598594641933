
<div class="page__container">
  <div class="container">


    <mat-expansion-panel hideToggle >
      <mat-expansion-panel-header>
        <mat-panel-title>
          Paramètrage
        </mat-panel-title>
        <mat-panel-description>
          Client: {{clientForm.value}} | Fichier: {{ fileForm.value}}
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="panel-container">
        <mat-form-field class="mat-form-small" appearance="fill">
          <mat-label>Client</mat-label>
          <mat-select [formControl]="clientForm">
            <mat-option *ngFor="let cl of client_list" [value]="cl">
              {{cl}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="mat-form-small">
          <mat-form-field appearance="fill" *ngIf="clientForm.valid" class="matForm ">
            <mat-label>Fichier résultat à transcoder</mat-label>
            <mat-select  [formControl]="fileForm" >
              <mat-option *ngFor="let fi of file_list" [value]="fi">
                {{fi}}
              </mat-option>
            </mat-select>
           
          </mat-form-field>
        </div>
      
        <div class="mat-form-small">
          <mat-form-field appearance="fill" *ngIf="clientForm.valid" class="matForm ">
            <mat-label>Nombre de propositions faites par le moteur</mat-label>
            <mat-select  [formControl]="howManyForm" >
              <mat-option *ngFor="let fi of [5,10,20,50,100,500,5500]" [value]="fi">
                {{fi}}
              </mat-option>
            </mat-select>
           
          </mat-form-field>
        </div>
        <!-- <div *ngIf="dataRaw">
          <h3>KPI</h3>
          <p>Label transcodé avec un seul loinc: {{countTranscodedOneChoice()}} / {{countElementToTranscode()}}  ( {{countTranscodedOneChoice()/countElementToTranscode()*100 | number: '1.0-2'}}%)</p>
          <p>
            Label transcodé avec plusieurs plusieurs loinc possibles : {{countTranscodedSeveralChoice()}} / {{countElementToTranscode()}} ( {{countTranscodedSeveralChoice()/countElementToTranscode()*100 | number: '1.0-2'}}%)</p>
         
        </div> -->

      </div>
    
    </mat-expansion-panel>


    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    <!-- <app-file-import-loinc></app-file-import-loinc> -->
   


  <mat-form-field appearance="standard" class="mat-form-small">
    <mat-label>Rechercher un code Loinc ...</mat-label>
    <input matInput (keyup)="applyFilterLoincToTranscode($event)" placeholder="" #input>
  </mat-form-field>
  <div class="example-button-row">
    <button mat-raised-button  color="primary" (click)="emptyChecked()">Tout désélectionner</button>   
    <button mat-raised-button color="primary"  mat-button [matMenuTriggerFor]="menu">Statut</button>
    
  
    <button mat-raised-button color="primary" (click)="seeKPI(modalKPI)">Voir KPI</button>
   
  </div>

  <mat-form-field  matTooltip="Sélectionner un code loinc assignera directement le code à toutes les lignes sélectionnées" appearance="fill" class="matForm ">
    <mat-label >Choisir un code Loinc à attribuer</mat-label>
    <mat-select  [formControl]="loinc_to_assign_to_checked" >
      <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Filtrer la liste de codes loinc</mat-label>
        <input matInput placeholder="Ex. 594-5" [formControl]="filterLoincToAssign" >
      </mat-form-field>
      <cdk-virtual-scroll-viewport 
      itemSize="25" 
      [ngStyle]="{height: '200px'}">
        <mat-option 
          *cdkVirtualFor="let cl of all_loinc_code_filter"
          [value]="cl.loinc"> 
            {{cl.loinc}}:{{cl.composant_francais}}({{cl.nabm}})
        </mat-option>
    </cdk-virtual-scroll-viewport>
      <!-- <mat-option *ngFor="let cl of all_loinc_code" [value]="cl">
        {{cl}}
      </mat-option> -->
    </mat-select>
   
  </mat-form-field>

 
<mat-menu #menu="matMenu">
  <button *ngFor="let state of state_list" mat-menu-item (click)="updateStatusAll(state)">{{state}}</button>
</mat-menu>

  <div class = "fichier_transco">
    <table mat-table  matSort  [dataSource]="dataSource" class="mat-elevation-z8">
  

      checkbox

      <ng-container matColumnDef="checkbox">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let element" (click)="$event.stopPropagation();"> 
          <mat-checkbox class="example-margin" [checked]="isElementSelected(element)" [disabled]="false" (change)="addToCheckedElement(element)"></mat-checkbox>
         </td>
      </ng-container>
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> id</th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container>

      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Statut</th>
        <td mat-cell *matCellDef="let element"> {{element.state}} </td>
      </ng-container>
    
    
      <ng-container matColumnDef="LIBELLE_ANALYSE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> LIBELLE_ANALYSE</th>
        <td mat-cell *matCellDef="let element"> {{element.LIBELLE_ANALYSE}} </td>
      </ng-container>
    
    
      <ng-container matColumnDef="SYNONYME">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>SYNONYME</th>
        <td mat-cell *matCellDef="let element"> {{element.SYNONYME}} </td>
      </ng-container>
    
    
      <ng-container matColumnDef="LIBELLE_UNITE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> LIBELLE_UNITE</th>
        <td mat-cell *matCellDef="let element"> {{element.LIBELLE_UNITE}} </td>
      </ng-container>
      <ng-container matColumnDef="LIBELLE_MILIEU">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> LIBELLE_MILIEU</th>
        <td mat-cell *matCellDef="let element"> {{element.LIBELLE_MILIEU}} </td>
      </ng-container>
      <ng-container matColumnDef="CODE_NABM">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> CODE_NABM</th>
        <td mat-cell *matCellDef="let element"> {{element.CODE_NABM}} </td>
      </ng-container>
      <ng-container matColumnDef="NABM">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> LIBELLE_NABM</th>
        <td mat-cell *matCellDef="let element"> {{element.nabm_libelle}} </td>
      </ng-container>
      <ng-container matColumnDef="loinc">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Loinc choisi</th>
        <td mat-cell *matCellDef="let element"> {{element.loinc}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumnsFileTransco;  sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsFileTransco;" (click)="openModaltransco(row,modalTpl)"></tr>
    </table>
 
    
    
  </div>
  <mat-paginator #paginator="matPaginator" [pageSize]=40 [pageSizeOptions]="[20,40,100,200]" showFirstLastButtons></mat-paginator> 
   
  </div>
</div>

<!-- <ng-container *ngIf="estimation"> <div class="fxBetween" class="title_estimation"> ESTIMATION <mat-icon>analytics</mat-icon></div></ng-container> -->
<ng-template #modalKPI class = "dialog-no-x-scroll">

  <p *ngFor="let kp of kpi">{{kp.state}}: {{kp.ratio | percent : '1.1-2' }} ({{kp.n_value}}/{{kp.total}})</p>
</ng-template>


<ng-template #modalTpl class = "dialog-no-x-scroll">
 <h1>{{transcoded.LIBELLE_ANALYSE}} - {{transcoded.LIBELLE_MILIEU}} - {{transcoded.LIBELLE_UNIT}} - {{transcoded.ECHELLE}} </h1> <br>
  <button mat-raised-button color="primary" (click)="goToNext()">Element non transcodé suivant</button>
  <button mat-raised-button color="primary" (click)="goToNextTranscoded()">Element déja transcodé suivant</button>
  <h1 color="danger" *ngIf="loinc_current_result">{{loinc_current_result.error}}</h1>
  <!-- <p>
    LIBELLE_ANALYSE : <b>{{transcoded.LIBELLE_ANALYSE}}</b>
  </p>
  <p>
    GRANDEUR : <b>{{transcoded.GRANDEUR}}</b>
  </p>
  <p>
    LIBELLE_MILIEU : <b>{{transcoded.LIBELLE_MILIEU}}</b>
  </p>
  <p>
    TECHNIQUE : <b>{{transcoded.TECHNIQUE}}</b>
  </p>
  <p>
    ECHELLE : <b>{{transcoded.ECHELLE}}</b>
  </p>
  <p>
    CODE_NABM : <b>{{transcoded.CODE_NABM}}</b>
  </p>
  <p>
    LIBELLE_UNITE :<b> {{transcoded.LIBELLE_UNITE}}</b>
  
  </p>
   -->
  <mat-form-field class="mat-form-small" appearance="fill" [hideRequiredMarker]="true">
    <mat-label>Commentaire</mat-label>
    <textarea matInput [formControl]="commentForm" placeholder="Ex. J'hésite entre tel et tel loinc... manque unité pour décider"></textarea>
   
  </mat-form-field>
 

  <mat-form-field class="mat-form-small" appearance="standard">
    <mat-label>Rechercher un code Loinc ...</mat-label>
    <input matInput (keyup)="applyFilterLoinc($event)" placeholder="" #input>
  </mat-form-field>

  <mat-form-field class="mat-form-small" appearance="fill">
    <mat-label>Statut transcodification</mat-label>
    <mat-select [formControl]="stateForm">
      <mat-option *ngFor="let cl of state_list" [value]="cl">
        {{cl}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-raised-button color="primary" (click)="saveComment()">Sauvegarder commentaire/status</button>
  <button mat-raised-button color="primary" (click)="useAllLoincResult()">Charger tous les loincs</button>
<div class="table-loinc">
  <table    mat-table  matSort [dataSource]="loinc_results" class="mat-elevation-z8 ">

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Selection</th>
      <td mat-cell *matCellDef="let element">  <mat-icon *ngIf="isChecked(element)">done</mat-icon> </td>
    </ng-container>
    
    <ng-container matColumnDef="loinc_code">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> LOINC</th>
      <th mat-header-cell *matHeaderCellDef mat-sort-header> LOINC</th>
      <td mat-cell *matCellDef="let element"> {{element.loinc}} </td>
    </ng-container>

    <ng-container matColumnDef="label_french">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> <b>{{transcoded.LIBELLE_ANALYSE}}</b><br> ( Composant Français )</th>
      <td mat-cell *matCellDef="let element"> {{element.composant_francais}} </td>
    </ng-container>
    <ng-container matColumnDef="synonymes">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Synonyme</th>
      <td mat-cell *matCellDef="let element"> {{element.synonymes}} </td>
    </ng-container>

    <ng-container matColumnDef="chapitre">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Chapitre</th>
      <td mat-cell *matCellDef="let element"> {{element.chapitre}} </td>
    </ng-container>
    <ng-container matColumnDef="milieu">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> <b>{{transcoded.LIBELLE_MILIEU}}</b> <br>(Milieu bio) </th>
      <td mat-cell *matCellDef="let element"> {{element.milieu_biologique}} </td>
    </ng-container>
    <ng-container matColumnDef="grandeur">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> <b>{{transcoded.ECHELLE}}</b> (<br>Type de grandeur)</th>
      <td mat-cell *matCellDef="let element"> {{element.grandeur}} </td>
    </ng-container>
    <ng-container matColumnDef="unit">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> <b>{{transcoded.LIBELLE_UNITE}}</b> (Unité)</th>
      <td mat-cell *matCellDef="let element">   {{element.unit}} </td>
    </ng-container>
    <ng-container matColumnDef="nabm">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> <b>{{transcoded.CODE_NABM}}</b><br> (NABM)</th>
      <td mat-cell *matCellDef="let element"> {{element.nabm}} </td>
    </ng-container>
    <ng-container matColumnDef="libelle_nabm">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Libelle NABM</th>
      <td mat-cell *matCellDef="let element"> {{element.libelle_nabm}} </td>
    </ng-container>

    
    <ng-container matColumnDef="technique">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Technique</th>
      <td mat-cell *matCellDef="let element"> {{element.technique}} </td>
    </ng-container>

    <ng-container matColumnDef="temps">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Temps</th>
      <td mat-cell *matCellDef="let element"> {{element.temps}} </td>
    </ng-container>

    <ng-container matColumnDef="distance">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Proximité calculée</th>
      <td mat-cell *matCellDef="let element"> {{element.match}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns ; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="chooseLoinc(row)"></tr>
    
    
  </table>

 
</div>
<mat-paginator #paginatorLoinc="matPaginator" [pageSize]="getPageSizeTranscoResult()" [pageSizeOptions]="[20,40,100,200]" showFirstLastButtons></mat-paginator> 
   

  
  <!-- <div class="row_choices" *ngIf="choices_select">
    <form [formGroup]="loincForm" >
      <mat-form-field appearance="fill">
        <mat-label>Echelle</mat-label>
        <mat-select formControlName="echelle" (change)="changeEchelle($event)">
          <mat-option *ngFor="let echelle_i of choices_select.echelle" [value]="echelle_i">
            {{echelle_i}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Milieu Biologique</mat-label>
        <mat-select formControlName="milieu_bio">
          <mat-option *ngFor="let milieu_i of choices_select.milieu_bio" [value]="milieu_i">
            {{milieu_i}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Type d'unité</mat-label>
        <mat-select formControlName="grandeur">
          <mat-option *ngFor="let grandeur of choices_select.grandeur" [value]="grandeur">
            {{grandeur}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Famille d'examen</mat-label>
        <mat-select formControlName="chapitre">
          <mat-option *ngFor="let chapitre of choices_select.chapitre" [value]="chapitre">
            {{chapitre}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Technique</mat-label>
        <mat-select formControlName="technique">
          <mat-option *ngFor="let technique of choices_select.technique" [value]="technique">
            {{technique}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
    <div class="example-button-container">
      <button mat-fab color="primary" aria-label="Example icon button with a delete icon" (click)=reinit_filters()>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div> -->
<!-- 
  <div class="fileSelect">
    <mat-form-field appearance="fill" *ngIf="clientForm.valid" class="matForm">
      <mat-label>Nombre de proposition faite par le moteur</mat-label>
      <mat-select  [formControl]="howManyForm" >
        <mat-option *ngFor="let fi of [5,10,20,50,100,500,5500]" [value]="fi">
          {{fi}}
        </mat-option>
      </mat-select>
     
    </mat-form-field>
  </div> -->
</ng-template>
