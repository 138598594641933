
  
<div class=" title">
  
  <mat-slide-toggle color="warn" [(ngModel)]="greyOld">
    Griser anciennes analyses
  </mat-slide-toggle>
  <!-- <p *ngIf="estimation"  class="title_estimation"> ESTIMATIONS <mat-icon>analytics</mat-icon></p> -->

  <mat-chip-list *ngIf="isCovid">
    <mat-chip (click)="showShapley(score)"
              [style.background-color]="getColorBackGroundChip(score)"
              *ngFor="let score of covid_scores">
      {{score.label}}: {{score.group}} (x{{score.risque_relatif | number : '1.1-1'}})
    </mat-chip>
  </mat-chip-list>
   
</div>

<div class="table__container">
  <div *ngIf="estimation"  class="title_estimation"> ESTIMATIONS <mat-icon>analytics</mat-icon></div>
  <table *ngIf="!!showTable && hasData"  class="table">
    <thead>
    <tr>
      <th style="width:30px;"></th>
      <th style="width:10px;padding:5px;"></th>
      <th *ngFor="let gr of familyGroupList" class="group__th" [attr.colspan]="gr.child.length" >
        <div class="fxBetween" (click)="open(gr)">
          <mat-icon>menu_open</mat-icon>
          <div class="label">{{gr.label}}</div>
        </div>
      </th>
    </tr>
   
    <tr>
      <th style="width:30px;"></th>
      <th style="width:10px;padding:5px;"></th>
      <th *ngFor="let famille of familyList; let i = index"
          class="bg-cell" [class.odd]="i % 2 == 1 && !estimation" [class.odd_est]="i % 2 == 1 && estimation "><b>{{famille}}</b></th>
    </tr>
    </thead>
    <tbody>

    <tr *ngFor="let deriveObj of colors" class="border_bottom">
      <td [style.color]="getColorDerive(deriveObj.derive)"
          [style.font-size.px]="getSizeFontDerive(deriveObj.derive)"
          class="derive__cell">{{deriveObj.derive_label}}</td>
      <td  [class]="getColorDeriveGradientClass(deriveObj.derive)"
          [style.border-bottom-left-radius]="getBorderRadiusBottom(deriveObj.derive,'color_cell')"
          [style.border-bottom-right-radius]="getBorderRadiusBottom(deriveObj.derive,'color_cell')"
          [style.border-top-left-radius]="getBorderRadiusTop(deriveObj.derive,'color_cell')"
          [style.border-top-right-radius]="getBorderRadiusTop(deriveObj.derive,'color_cell')"
      >
      </td>

      <td nowrap *ngFor="let famille of familyList; let i = index"
          class="bg-cell" [class.odd]="i % 2 == 1 && !estimation" [class.odd_est]="i % 2 == 1 && estimation "
          [style.border-bottom]="getBorderBottom(deriveObj.derive)"
          [style.border-bottom-left-radius]="getBorderRadiusBottom(deriveObj.derive)"
          [style.border-bottom-right-radius]="getBorderRadiusBottom(deriveObj.derive)"
          [style.background]="getColorDeriveBackground(deriveObj.derive,famille)"
          on-mouseover='hoverFunction(deriveObj.derive,famille)'
          (mouseout)='emptyHoverCell()'
      >
        <ng-container *ngFor="let resultat of getParametersByDeriveAndFamilles(deriveObj.derive,famille)">
          <div [style.color]="getColorDeriveParameter(resultat)" style="border-radius: 5px;"
               [style.border]="getBorderStyleParameter(resultat)" [style.font-size.px]="getSizeFontDerive(deriveObj.derive)"
               [style.opacity]="getOpacityParameter(resultat)"
               
               (click)="openDialog(resultat, modalTpl)">
            <ng-container  class="parameter_result" *ngIf="resultat.estimation==estimation">
              <div  class="pointer" matTooltipClass="test" matTooltipPosition="left" matTooltipHideDelay="0" matTooltipShowDelay="0" md-autohide
                   matTooltip="{{resultat.date | date: 'fullDate'}} 
                   Résultat Analyse : {{resultat.moyenne}} {{resultat.unit}} ">

                <span matBadge="!" matBadgeOverlap="false" matBadgeColor="warn" [matBadgeHidden]="!isUrgent(resultat)">
                  {{random_risk(resultat, deriveObj.derive) | uppercase }}
                </span>  <mat-icon *ngIf="showChartIcon(deriveObj.derive,famille)">query_stats</mat-icon>
                <!-- <mat-icon class="icon-small" [ngStyle]="{'color':showChartIconEstimation(derive,famille)? getColorDeriveParameter(resultat):'#DCDCDC'}" *ngIf="estimation">schedule_filled</mat-icon> -->
              </div>
            </ng-container>
          </div>
        </ng-container>
      </td>
    </tr>
    </tbody>
  </table>

  <div class="unlink__groups" *ngIf="!estimation" >
    <div class="group__th" *ngFor="let gr of unlinkTableGroupList" >
      <div class="fxBetween" (click)="open(gr)" >
        <mat-icon>menu_open</mat-icon>
        <div class="label">{{gr.label}}</div>
      </div>
    </div>
  </div>
  
</div>

<!-- <ng-container *ngIf="estimation"> <div class="fxBetween" class="title_estimation"> ESTIMATION <mat-icon>analytics</mat-icon></div></ng-container> -->
<ng-template #modalTpl>
  <app-graphe-derive
          [patient]="patient"
          [parameter]="selectedParam"
          [res]="modalDataResult"
  ></app-graphe-derive>
</ng-template>
