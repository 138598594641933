import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RestApiService } from '@app.services';
import { FileSystemFileEntry } from 'ngx-file-drop';
import { clearFormArray } from '@app.utils';

@Component({
    selector: 'app-file-import',
    templateUrl: './file-import.component.html',
    styleUrls: [ './file-import.component.css' ]
})
export class FileImportComponent implements OnInit {
    form: FormGroup;
    fileContentArray: FormArray;
    listePatient: any[];
    filteredList: any[];
    loaded: string[] = [];
    public files: string[] = [];
    checkedFiles: any[] = [];
    loading = false;
    droppedFiles = 0;
    loadedFiles = 0;
    progressBarValue = 0;
    name = '';
    url_tovalidateupload = 'api/getuploadswithnochoice';
    @ViewChild('fileInput', { static: true }) fileInput: ElementRef;

    constructor( private fb: FormBuilder,
                 private restApiService: RestApiService ) {
        this.createForm();
    }

    ngOnInit() {
    }

    updateProgressBar() {
        if (this.droppedFiles > 0) {
            this.progressBarValue = this.loadedFiles * 100 / this.droppedFiles;
        } else {
            this.progressBarValue = 0;
        }
    }

    createForm() {
        this.form = this.fb.group({
            fileContentArray: this.fb.array([], Validators.required)
        });
        this.fileContentArray = this.form.get('fileContentArray') as FormArray;
    }

    createFileContentForm() {
        const fileContentForm = this.fb.group({
            doctor: [ '', Validators.required ],
            file: [ '', Validators.required ],
            from_file: [ '', Validators.required ],
            hprim_format: [ '', Validators.required ],
            hprim_status: [ '', Validators.required ],
            patient: [ '', ],
            patient_status: [ '', ],
            size: [ '', Validators.required ],
            to_import: [ '', Validators.required ],
            hprim_header: [ '', Validators.required ],
        });
        return (fileContentForm);
    }

    onFileChange( event ) {
        clearFormArray(this.fileContentArray);
        if (event.target.files && event.target.files.length > 0) {
            for (const file of event.target.files) {
                this.droppedFiles++;
                this.updateProgressBar();
                this.addFileContent(file);

            }
        }
    }

    send() {
        this.loading = true;
        this.loaded = [];
        for (const el of this.fileContentArray.controls) {
            const doctor = el.get('doctor').value;
            const file = el.get('from_file').value;
            const size = el.get('size').value;
            const to_import = el.get('to_import').value;
            const data = { doctor: '', file: '', size: '', to_import: '' };
            data.doctor = doctor;
            data.file = file;
            data.size = size;
            data.to_import = to_import;
            this.restApiService.$post<any>('api/upload_choice', data).subscribe(res => {
                this.fileContentArray.removeAt(this.fileContentArray.value.findIndex(arr => arr.file === res[0].file));
            });
        }
    }

    clearFile() {
        this.fileInput.nativeElement.value = '';
        this.loadedFiles = 0;
        this.droppedFiles = 0;
        this.updateProgressBar();
        clearFormArray(this.fileContentArray);
    }


    addElementInFileContentForm( fileContentForm, el ) {
        fileContentForm.get('doctor').setValue(el.doctor);
        fileContentForm.get('file').setValue(el.file);
        fileContentForm.get('from_file').setValue(el.from_file);
        fileContentForm.get('hprim_format').setValue(el.hprim_format);
        fileContentForm.get('hprim_status').setValue(el.hprim_status);
        fileContentForm.get('patient_status').setValue(el.patient_status);
        fileContentForm.get('size').setValue(el.size);
        fileContentForm.get('to_import').setValue(el.to_import);
        fileContentForm.get('hprim_header').setValue(el.hprim_header);
        return (fileContentForm);
    }

    public addFileContent( file: File ) {
        this.files.push(file.name);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            if (typeof reader.result === 'string') {
                const res = reader.result.split(',')[1];
                const data = { patient: '', fileContent: '', fileName: '' };
                data.fileContent = res;
                data.fileName = file.name;
                this.restApiService.$post<String>('api/upload', data).subscribe(x => {
                    this.addCheckedFile(x);
                });
            }
        };
    }

    public addCheckedFile( checkedFile ) {
        const res = checkedFile;
        res.selected = res.upload_status.hprim_format;
        this.checkedFiles.push(res);
        let fileContentForm = this.createFileContentForm();
        res.upload_status[0].hprim_header = res.hprim_header;

        res.upload_status[0].to_import = res.upload_status[0].hprim_format;
        fileContentForm = this.addElementInFileContentForm(fileContentForm, res.upload_status[0]);
        // //console.log('filecontentform', fileContentForm);
        this.fileContentArray.push(fileContentForm);
        this.loadedFiles++;
        this.updateProgressBar();
    }


    public dropped( event ) {
        for (const droppedFile of event.files) {
            if (droppedFile.fileEntry.isFile) {
                this.droppedFiles++;
                this.updateProgressBar();
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                // //console.log(fileEntry.file.length)
                fileEntry.file(( file: File ) => {
                    this.addFileContent(file);

                });

            }

        }
    }


    uploadFileclick() {
        // //console.log('test click');
        this.fileInput.nativeElement.click();

    }

    public fileOver( event ) {
    }

    public fileLeave( event ) {
    }

}
