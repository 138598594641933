import { Pipe, PipeTransform } from '@angular/core';
import { format, parse } from '@app.utils';

@Pipe({
    name: 'amDateFormat',
})
export class DateFormatPipe implements PipeTransform {

    public transform( value: Date | string, dateFormat?: string ) {
        const date = value instanceof Date ? value : parse(value);
        return format(date, dateFormat || 'yyyy-MM-dd');
    }

}
