import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TranscodificationServiceProvider } from '@app.services';
import {MatTableDataSource} from '@angular/material/table';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogService } from 'app/services';
import { isNotEmptyNorUndefined, unique } from '@app.utils';
import { ITransco } from 'app/models';

@Component({
    selector: 'app-transcodification-loinc',
    templateUrl: './transcodification-loinc.component.html',
    styleUrls: [ './transcodification-loinc.component.css' ]
})
export class TranscodificationLOINCComponent implements OnInit {

    @Input() parameter_label?: string;
    clientForm = new FormControl(localStorage.getItem('loinc_client') ? localStorage.getItem('loinc_client') : '',[Validators.required]);
    client=''
    fileForm = new FormControl(localStorage.getItem('loinc_file') ? localStorage.getItem('loinc_file') : '',[Validators.required]);
    stateForm =  new FormControl('',[Validators.required]);
    comment = ''
    file='';
    kpi;
    statut_loinc='';
    howMany = 10;
    transcoded;
    client_list = ['EUROFINS','TEST']
    state_list = ['Non concerné - libellé autre','Non concerné - probablement hors circuit de biologie','Choix multiples','Choix unique','À transcoder','À vérifier - doute']
    file_list = []
    transcodification_file;
    values = '';
    all_loinc;
    all_loinc_code;
    all_loinc_code_filter;
    checked;
    dataRaw;
    state;
    loading = false;
    loinc_checked=[];
    checkedElement :any[]=[];
    loinc_transcoded;
    loinc_results;
    filterValue='';
    howManyForm = new FormControl(Number(localStorage.getItem('loinc_n_to_show')) ? Number(localStorage.getItem('loinc_n_to_show')) : 10, [Validators.required])
    commentForm = new FormControl('', [Validators.required])
    loinc_to_assign_to_checked = new FormControl('')
    filterLoincToAssign = new FormControl('');
    //transcodification result for a given element to transcode
    loinc_current_result= undefined;
    unit;
    dataSource;
    displayedColumns: string[] = ['select', 'loinc_code', 'label_french', 'distance', 'technique', 'temps', 'chapitre','milieu','grandeur','nabm','libelle_nabm','synonymes','unit' ];
    displayedColumnsFileTransco: string[] = ['checkbox','id', 'state','LIBELLE_ANALYSE', 'LIBELLE_UNITE', 'LIBELLE_MILIEU',
'CODE_NABM','NABM','loinc' ];

    loincForm;

    choices_select = {
        technique: [],
        chapitre: [],
        grandeur: [],
        milieu_bio: [],
        echelle: []
    };

    // current option selection
    density_values;

    @ViewChild('paginator') paginator: MatPaginator;
    @ViewChild('paginatorLoinc') paginatorLoinc: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor( private transcodificationService: TranscodificationServiceProvider,
        private dialogService: DialogService, ) {
    }

    ngOnInit() {
        if (!this.parameter_label) {
            this.parameter_label = 'Globule rouge';
        }
        this.createLoincForm();
        //this.transcodeCurrentParameter();
       
        //init values from localStorage
       
    }

    ngAfterViewInit() {
        this.file = localStorage.getItem('loinc_file')?localStorage.getItem('loinc_file'):''
        this.client = localStorage.getItem('loinc_client')?localStorage.getItem('loinc_client'):'EUROFINS'
        
        if(localStorage.getItem('loinc_client')){
          
            this.client= localStorage.getItem('loinc_client')
            this.getFileListByClient(this.client)
            if(localStorage.getItem('loinc_file')){
                this.file= localStorage.getItem('loinc_file')
                this.getFileTable(this.client,this.file)
            }
        }

       
       

        //initialise les observeurs de changement des formulaire
        this.onChangesClient()
        this.onChangesFile()
        this.onChangesHowManyForm()
        this.onChangesState()
        this.onChangesLoincToAssign()
        this.onChangesfilterLoincToAssign()
        this.getTranscoResult(this.client, this.file, 1, 5600, true)
      }



    computeAllKPI(){
        let res: any[]
        let all_state = this.dataSource.data.map(x=>x.state).filter(unique).sort()
        console.log(all_state)
        res = all_state.map(x => this.getKPI(x))
        this.kpi = res
    }

    getKPI(KPIType){
        let n_value = this.dataSource.data.filter(x=>x.state ===KPIType ).length
        let total = this.dataSource.data.length
        let ratio = n_value/total
        const res = {n_value:n_value, total:total, ratio:ratio, state:KPIType}
        console.log(res)
        return(res)
    }
    updateStatusAll(state){
        const ids = this.checkedElement.map(x=>x.id)
        this.transcodificationService.post_update_comment_multiple_loinc(this.client, this.file,ids , state).subscribe(x=>{
            
        })
        this.dataSource.data.forEach(el => {
            if(ids.includes(el.id) ){
                el.state = state
            }
        });
        
    }

    getPageSizeTranscoResult(){
       
        let res= 20
        if(this.loinc_results.filteredData){
             res = Math.min(40, this.loinc_results.filteredData.length )
        }
        

        return(res)
    }
      
   isElementSelected(element){
    return(this.checkedElement.filter(x=>x.id === element.id).length>0)
   } 
    emptyChecked(){
        this.checkedElement = []
    }
    addToCheckedElement(element){
        const alreadyChecked = this.checkedElement.filter(x=>x.id === element.id).length>0
        if(!alreadyChecked){
            this.checkedElement.push(element)
            console.log(this.checkedElement)
        }else{
            this.checkedElement = this.checkedElement.filter(x=>x.id !== element.id)
            console.log(this.checkedElement)
        }
        
    }
    countTranscodedOneChoice(){
        if (this.dataRaw){
            var res = this.dataRaw.filter(x=> x.loinc !==null )
            res = res.filter(x=> this.valueToArray(x.loinc).length == 1).length

        }else{
             res = 0
        }
       

        return(res)
    }

    countElementToTranscode(){
        if (this.dataRaw){
            return(this.dataRaw.length)
        }else{
            return(1)
        }
    }

    countTranscodedSeveralChoice(){
        if (this.dataRaw){
            var res = this.dataRaw.filter(x=> x.loinc !==null )

            res = res.filter(x=> this.valueToArray(x.loinc).length > 1).length

        }else{
             res = 0
        }
        return(res)
    }

    applyFilterLoinc(event: Event) {

        const filterValue = (event.target as HTMLInputElement).value;
        
        this.filterValue = filterValue.trim().toLowerCase()
        console.log(this.filterValue)
        console.log(this.loinc_results)
        this.loinc_results.filter = this.filterValue
        
        
      }

      applyFilterLoincToTranscode(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        
        let filterValue_ = filterValue.trim().toLowerCase()
        
        this.dataSource.filter = filterValue_
        
        
      }

    chooseLoinc(row){
        if(this.loinc_checked.includes(row.loinc)){
            this.loinc_checked = this.loinc_checked.filter(x => x !== row.loinc)
            this.loinc_checked = this.loinc_checked.filter( x => x!== undefined)
            this.dataSource.data.forEach(el => {
                if(el.id === this.transcoded.id){
                    el.loinc = this.loinc_checked
                }
            });
            if(this.loinc_checked.length === 0 ){
                this.stateForm.setValue('')
                this.updateStateInDataSource('')
            }
            this.transcodificationService.post_save_choice_loinc(this.client, this.file, this.transcoded.id, this.loinc_checked,this.commentForm.value, this.stateForm.value).subscribe()
          
            //this.createDataSource(this.dataRaw)
        }else{
            this.loinc_checked.push(row.loinc)
            if(this.loinc_checked.length ==1){
                this.stateForm.setValue('Choix unique')
                this.updateStateInDataSource('Choix unique')
            }else if (this.loinc_checked.length >1){
                this.stateForm.setValue('Choix multiples')
                this.updateStateInDataSource('Choix multiples')
            }
            this.transcodificationService.post_save_choice_loinc(this.client, this.file, this.transcoded.id,  this.loinc_checked,this.commentForm.value,this.stateForm.value).subscribe()
            this.dataSource.data.forEach(el => {
                if(el.id === this.transcoded.id){
                    el.loinc = this.loinc_checked
                }
            });
            //this.createDataSource(this.dataRaw)
        }
        
    }


    getFileListByClient(client){
        this.loading = true
        this.transcodificationService.post_get_available_file(client).subscribe(
            x=>{
                
                 if(!Array.isArray(x)){
                     this.file_list = [x]
                 }else{
                     this.file_list = x
                 }
               
                this.loading = false
            })
        }
    getFileTable(client, file ){
            this.loading = true
            this.dataRaw = []
            this.transcodificationService.post_get_loinc_table(client, file).subscribe(
                x => {
                    x.forEach(x=>{if(x.loinc !==  null){x.loinc =  x.loinc.split("|")}})
                    console.log(x)
                    this.dataRaw = x
                    this.createDataSource(x)
                    this.transcodification_file = x
                    this.loading=false

                }

            ) 
    }

    createDataSource(x){
        this.dataSource = new MatTableDataSource(x);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort
        return(this.dataSource)
    }
    isChecked(element){
        if(this.loinc_checked.includes(element.loinc)){ 
            return true
        }else{
            return false
        }
    }
    
    set_loinc_variables(loinc_results_wrapper, change_loinc_checked = true){
        let x= loinc_results_wrapper
        this.loinc_current_result = x
        if(x.error=== null){
         this.choices_select = {
             technique: this.valueToArray(x.technique),
             chapitre: this.valueToArray(x.chapitre),
             grandeur: this.valueToArray(x.grandeur),
             milieu_bio: this.valueToArray(x.milieu_bio),
             echelle: this.valueToArray(x.echelle)
              };
         if(x.previous_choice){
             if(change_loinc_checked){
                this.loinc_checked = this.valueToArray(x.previous_choice.loinc)
                this.commentForm.setValue(x.previous_choice.comment)
                this.stateForm.setValue(x.previous_choice.state)
             }
             
             
         }else {
             this.stateForm.setValue('A transcoder')
             this.commentForm.setValue('')
         }
        }else{
        
        }
    }
    getTranscoResult(client = this.client, file = this.file, id = this.transcoded.id, howMany = this.howManyForm.value, save_as_default_result = false ){
                this.loading = true
                let return_all=0
                if(save_as_default_result===true){
                     return_all=1
                }
                this.transcodificationService.post_get_transcodification(client, file, id, howMany,return_all).subscribe(
                    x => {
                        console.log(x)
                       if(save_as_default_result){
                        this.all_loinc_code = x.table.sort((a, b) => (a.loinc > b.loinc) ? 1 : -1)
                        this.all_loinc_code_filter = this.all_loinc_code
                        this.all_loinc = x
                       }

                        this.set_loinc_variables(x)
                        this.get_loinc_transcoded()
                        this.loading=false 
                    }
                )
    }
    
    useAllLoincResult(){
        this.loading = true
        this.howManyForm.setValue(this.all_loinc.length)
        this.set_loinc_variables(this.all_loinc, false)
        this.get_loinc_transcoded()

        //reinitialize filter of matdatasource
        this.loinc_results.filter= this.filterValue;
        //this.loinc_checked = []
        this.loading=false
    }    
  
    valueToArray(x){
        if(!Array.isArray(x)){
            x = [x]
        }else{
            x = x
        }
        return(x)
    }
    
    initTranscoResult(element){

        this.transcoded = element
        this.choices_select = undefined; 
        this.loinc_current_result = undefined; 
        this.loinc_checked = [];
        this.filterValue = ''
        this.getTranscoResult()
       
        //this.commentForm.setValue(element.comment ? element.comment : '')
        
        
    }

    async openModaltransco( element, modalTemplate ) {
        this.initTranscoResult(element)
        if(![5,10,20,50,100,500,5500].includes(this.howManyForm.value)){
            this.howManyForm.setValue(50)
        }
        console.log(element)
        this.loinc_results = []
        this.stateForm.setValue('')
        await this.dialogService.openTemplate(modalTemplate,{minWidth:1500, minHeight:500});
    }
  
    async seeKPI(  modalTemplate ) {
        this.computeAllKPI()
        await this.dialogService.openTemplate(modalTemplate);
    }

   

    onChangesClient(){
        
        this.clientForm.valueChanges.subscribe(val=>{
            this.getFileListByClient(val)
            this.client=val
            if(val){
                localStorage.setItem('loinc_client',val)
            }
            
          
        })

    }

    onChangesfilterLoincToAssign(){
        
        this.filterLoincToAssign.valueChanges.subscribe(val=>{
            console.log(val)
            if(val != ''){
                this.all_loinc_code_filter = this.all_loinc_code.filter(function (str) { if(str.loinc === null){
                        return false;
                }else{
                    return str.loinc.toString().indexOf(val) >-1; }});
                console.log(this.all_loinc_code_filter)
            }
            
            
        })

    }

    onChangesState(){
        
        this.stateForm.valueChanges.subscribe(val=>{
            //this.getFileListByClient(val)
            this.state=val
            console.log(this.state)
            //this.transcodificationService.post_save_choice_loinc(this.client, this.file, this.transcoded.id, this.loinc_checked,this.commentForm.value, this.stateForm.value).subscribe()
          
            
          
        })

    }
    onChangesLoincToAssign(){
        
        this.loinc_to_assign_to_checked.valueChanges.subscribe(val=>{
            if(val===''){
                return ''
            }
            //this.getFileListByClient(val)
      
            const ids = this.checkedElement.map(x=>x.id)
            ids.forEach(id=>{

                //get infos of element
                let el = this.dataSource.data.filter(x=>x.id ===id )[0]
                if(el.loinc === null){
                    el.loinc = [val]
                }else if(el.loinc.includes(val)){
                    el.loinc = el.loinc.filter(x => x !==val)
                    el.loinc = el.loinc.filter( x => x!== undefined)
                }else{
                    el.loinc.push(val)
                    el.loinc = el.loinc.filter(x => x !=='')
                }
  
                
                   this.transcodificationService.post_save_choice_loinc(this.client, 
                       this.file, 
                       id, 
                       el.loinc,
                       el.comment, 
                       el.state).subscribe()
          
            })
            this.loinc_to_assign_to_checked.setValue('')
            this.filterLoincToAssign.setValue('')
            
            //this.transcodificationService.post_save_choice_loinc(this.client, this.file, this.transcoded.id, this.loinc_checked,this.commentForm.value, this.stateForm.value).subscribe()
          
            
          
        })

    }

    

    updateStateInDataSource(value){
        this.dataSource.data.forEach(el => {
            if(el.id === this.transcoded.id){
                el.state = value
            }
        });
    }
  
    saveComment(){
        this.transcodificationService.post_save_choice_loinc(this.client, this.file, this.transcoded.id, this.loinc_checked, this.commentForm.value, this.stateForm.value).subscribe()
        
        this.updateStateInDataSource(this.stateForm.value)
        //this.createDataSource(this.dataRaw)
      }



    
    goToNext(){
        var current = this.transcoded.id;
        var res= this.dataRaw.filter(x=>x.loinc==undefined && x.id> this.transcoded.id)[0]
        this.filterValue = ''
        this.initTranscoResult(res)
    }

    goToNextTranscoded(){
        var current = this.transcoded.id;
        this.filterValue = ''
        var res= this.dataRaw.filter(x=>x.loinc!==null && x.id> this.transcoded.id)[0]
        this.initTranscoResult(res)


    }
    onChangesHowManyForm(){
        
        this.howManyForm.valueChanges.subscribe(val=>{
            this.howMany=val
            if(val){
                localStorage.setItem('loinc_n_to_show',val)
            }
          
        })

    }

    onChangesFile(){
        this.fileForm.valueChanges.subscribe(val=>{
            this.file=val
            this.getFileTable(this.client,val)
            if(val){
                localStorage.setItem('loinc_file',val)
            }
            
        })

    }

    createLoincForm() {
        this.loincForm = new FormGroup({
            echelle: new FormControl(''),
            grandeur: new FormControl(''),
            chapitre: new FormControl(''),
            milieu_bio: new FormControl(''),
            technique: new FormControl(''),
        });
    }

    transcodeCurrentParameter(return_all_loinc_random : false) {
        // reinit loincForm
        this.createLoincForm();
        this.loinc_current_result = undefined;
        this.transcodificationService.post_transcodification(this.parameter_label, this.values, return_all_loinc_random).subscribe(res => {
            this.loinc_transcoded = res.table
            this.loinc_current_result = res;
            this.choices_select = {
                technique: this.valueToArray(res.technique),
                chapitre: this.valueToArray(res.chapitre),
                grandeur: this.valueToArray(res.grandeur),
                milieu_bio: this.valueToArray(res.milieu_bio),
                echelle: this.valueToArray(res.echelle)
            };
            console.log(this.choices_select)
        });
    }

    reinit_filters() {
        this.createLoincForm();
    }

    changeEchelle( evt ) {
    }

    changeValue( event ) {
        const value_numeric = this.values.split(',').map(x => parseFloat(x));
        this.transcodificationService.post_density(value_numeric).subscribe(res => {
            this.density_values = res;
        });
    }

   

    get_loinc_transcoded( n_rows = this.howManyForm.value ) {
        const values = this.loincForm.value;
        if(!this.loinc_current_result){
            return([])
        }
        let res;
        if(!this.loinc_current_result.table){
            return([])
        }
        if (!values.milieu_bio && !values.grandeur && !values.chapitre && !values.echelle) {
            res = this.loinc_current_result.table;
        } else {
            res = this.loinc_current_result.table.filter(x => {
                const bool = (values.echelle ? x.echelle == values.echelle : true) &&
                    (values.milieu_bio ? x['milieu_biologique'] == values.milieu_bio : true) &&
                    (values.grandeur ? x['grandeur'] == values.grandeur : true) &&
                    (values.chapitre ? x['chapitre'] == values.chapitre : true);
                return (bool);
            });
        }
        if (n_rows) {
            res = res.slice(0, n_rows - 1);
        }

        let ret  = res;
       
        // //ret.paginator = this.paginatorLoinc;
        
         
        // ret.paginator = this.paginator;
        // ret.sort = this.sort
        this.loinc_results = new MatTableDataSource(ret);
        this.loinc_results.filter = this.filterValue;
        this.loinc_results.paginator = this.paginatorLoinc;
        this.loinc_results.sort = this.sort
        return (ret);
    }

}




