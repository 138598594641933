<table mat-table matSort [dataSource]="datasource">
  <ng-container [matColumnDef]="c.colId" *ngFor="let c of myCols">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{c.headerName}}</th>
    <ng-container *ngIf="c.type == 'text'">
      <td mat-cell *matCellDef="let row">
        {{row[c.field]}} {{c.suffix}}
      </td>
    </ng-container>
    <ng-container *ngIf="c.type == 'date'">
      <td mat-cell *matCellDef="let row">
        {{row[c.field] | date: c.dateFormat }}
      </td>
    </ng-container>
    <ng-container *ngIf="c.type == 'button'">
      <td mat-cell *matCellDef="let row">
        <button mat-icon-button color="warn" (click)="clickOnCell($event, c, row)">
          <mat-icon>{{c.icon}}</mat-icon>
        </button>
      </td>
    </ng-container>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="columns_parameters; sticky:true"></tr>
  <tr (click)="clickOnRow(row)" mat-row *matRowDef="let row; columns: columns_parameters;"></tr>
</table>
<ng-content></ng-content>
