<div class="page__title"></div>
<div class="page__container">
  <div class="fxCenter">
    <h2 class="fxGrow">Ma liste de patients</h2>
    <input matInput class="bio__input" (keyup)="applyFilter(gridCmp, $event.target.value)"
           placeholder="Rechercher les patients...">
  </div>
  <div class="table__container">
    <bio-grid [columnDefs]="displayedColumns"
              [rowData]="filteredList"
              (rowClick)="openPatientDashboard($event)"
              #gridCmp
    >
    </bio-grid>
  </div>
  <mat-paginator [pageSize]=100 [pageSizeOptions]="[20,40,100,200]" showFirstLastButtons></mat-paginator>
</div>
