export class ResultDerive {
    patient: string;
    date: string;
    param: string;
    derive: string;
    moyenne: number;
    etat_derive: string;
    tolerance: string;
    created_at: string;
    estimation: number;
    famille: string;
    unit: string;
}
