<mat-drawer-container class="layout-container">
  <mat-drawer-content class="drawer-content">
    <svg class="svgMenu"><use xlink:href="assets/sprite.svg#back" /></svg>
    <div class="app__container">
      <app-sidenav class="sidenav"></app-sidenav>
      <div class="main__container"
           #scrollContainer
           [@routeAnimations]="o.isActivated && o.activatedRoute.snapshot.url">
        <router-outlet (activate)="onActivate($event, scrollContainer, o)" #o="outlet"></router-outlet>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
