export class PatientListItem {
    id: number;
    label: string;
    name: string;
    firstname: string;
    birthdate: string;
    nsecu: string;
    SEXE: string;
    n_parameters: string;
    n_uploads: string;
    ratio_derive: string;
    last_result: string|Date;
    created_at: string;
    modified_at: string;
}
