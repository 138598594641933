export const zoomOptions = () => ({
    pan: {
        enabled: false,
        modifierKey: 'ctrl',
    },
    zoom: {
        enabled: true,
        drag: {
            enabled: true,
            modifierKey: 'alt'
        },
        wheel: {
            enabled: true,
        },
        mode: 'x',
    }
});
