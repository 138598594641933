import { ParameterService, PatientService, RestApiService } from '@app.services';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ExamResults } from '@app.models';
import { dateSortAscFn, unique } from 'app/utils';

@Component({
    selector: 'app-dashboard-last-uploads',
    templateUrl: './dashboard-last-uploads.component.html',
    styleUrls: [ './dashboard-last-uploads.component.scss' ]
})
export class DashboardLastUploadsComponent implements OnInit, OnDestroy {
    dataSource;
    mesures_ds: MatTableDataSource<any>;
    data_graph = [];
    chart;
    activeChoice = 3;
    differentDates;
    page;
    examResults;
    examPerPage = 150;
    uploadsDisplayed = [];
    examsNotShowed: number;
    selectedExam;
    selectedResult: ExamResults;
    columns_parameters: string[] = [ 'status', 'parameter', 'family', 'value', 'tolerance', 'st_value' ];
    tabs = [{id:1, label:'not_read', libelle:'Non lu', checked:false},
    {id:2, label:'red', libelle:'Lu', checked:false},
    {id:3, label:'all', libelle:'Afficher tout', checked:true}]
    uploads = [];
    mesures: ExamResults[] = [];
    @ViewChild(MatPaginator, { static: true } as any) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true } as any) sort: MatSort;
    radarChart: { dispose(): void };
    showChart = false;

    constructor( private restApiService: RestApiService,
                 private patientService: PatientService,
                 private router: Router,
                 private route: ActivatedRoute,
                 private parameterService: ParameterService ) {
    }

    ngOnInit() {
        this.route.queryParams
            .subscribe(async params => {
                    await this.getUploads(params.patients);
                    this.randomMesures(10);
                    this.data_graph = this.randomIntList(20);
                }
            );
    }

    ngOnDestroy() {
        if (this.radarChart) {
            this.radarChart.dispose();
        }
    }

    mapColors() {
        const len = this.uploads.length;
        for (let i = 0; i < len; i++) {
            const val = this.uploads[i].gravity;
            let color;
            if (val < 2) {
                color = '#72cd3f';
            } else if (val >= 2) {
                color = 'red';

            } else {
                color = '#34a0e0';
            }

            if(this.uploads[i].n_urgent>0){
                color = 'red';
            }
            this.uploads[i]['Color'] = color;
        }
    }

    randomMesures( n ) {
        for (let i = 0; i < n; i++) {
            const newElement: Partial<ExamResults> = {
                parameter: this.makeid(8),
                st_value: this.randomInt(100),
                min: this.randomInt(10),
                max: this.randomInt(50),
                in_tolerance: false,
            };
            this.mesures.push(newElement as any);
        }
    }

    filterByActiveStatus(data = this.uploadsDisplayed){
        
        if(this.activeChoice == 1){
            data =  data.filter(x => x.seen != 1 || x.seen == null)
        }else if (this.activeChoice == 2){
            data = data.filter(x => x.seen == 1)
        }
        return data
    }
    updateActiveChoice($event){
        this.activeChoice= $event.source.value
        const uniqueDates = this.filterByActiveStatus().map(x => x.date).filter(unique);
        this.differentDates = uniqueDates.sort(dateSortAscFn).reverse();
        
    }
    
    filterUploadsOnDate( date ) {
        
        let ret = this.uploadsDisplayed.filter(x => x.date === date )
        .sort(( a, b ) => b.covid_pronostic - a.covid_pronostic);
       
        ret = this.filterByActiveStatus(ret)
        return  ret
    }

    addExamToDisplay() {
        const currentExamsLength = this.uploadsDisplayed.length;
        let limit = this.examPerPage;
        if (this.examsNotShowed < this.examPerPage) {
            limit = this.examsNotShowed;
        }
        if (this.examsNotShowed > 0) {
            for (let i = 0; i < limit; i++) {
                this.uploadsDisplayed.push(this.uploads[currentExamsLength + i]);
            }
            const uniqueDates = this.uploadsDisplayed.map(x => x.date).filter(unique);
            this.differentDates = uniqueDates.sort(dateSortAscFn).reverse();
            this.examsNotShowed = this.uploads.length - this.uploadsDisplayed.length;
        }
        if (this.uploadsDisplayed[this.uploadsDisplayed.length - 1]) {
            this.clickOnExam(this.uploadsDisplayed[this.uploadsDisplayed.length - 1]);
        }
    }

    async getUploads( patients ) {
        const url = patients ? 'api/get-emails/' + patients : 'api/get-emails/none';
        this.uploads = (await this.restApiService.$get<any[]>(url).toPromise()).sort(dateSortAscFn);
        this.examsNotShowed = this.uploads.length;
        this.addExamToDisplay();
        this.mapColors();
    }

    async getExamResults( examId: number ) {
        this.mesures = await this.patientService.getExamResultList(examId).toPromise();
        this.mesures_ds = new MatTableDataSource<any>(this.mesures);
        this.mesures_ds.paginator = this.paginator;
        this.mesures_ds.sort = this.sort;
        if (this.radarChart) {
            this.radarChart.dispose();
        }
        this.radarChart = this.parameterService.createRadarChart(this.mesures);
    }

    loadMoreResults() {
        this.addExamToDisplay();
    }

    async clickOnExam( exam ) {
        this.selectedExam = exam;
        
        if (exam.examId) {
            await this.getExamResults(exam.examId);
            this.selectedExam.seen = 1
        }
    }

    clickOnResult( result ) {
        this.selectedResult = result;
    }

    randomIntList( n ) {
        const result = [];
        for (let i = 0; i < n; i++) {

            result.push(this.randomInt(100));
        }
        return (result);
    }

    openMesures() {
        this.mesures = [];
        //this.randomMesures(20);
        this.mesures_ds = new MatTableDataSource<any>(this.mesures);
        this.mesures_ds.paginator = this.paginator;
        this.mesures_ds.sort = this.sort;
        this.chart.destroy();
    }

    async goToPatient( patientId ) {
        await this.router.navigateByUrl(`patients/${patientId}/dashboard`);
    }

    randomDate( start, end ) {
        return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
    }

    randomInt( n ) {
        return Math.floor(Math.random() * n);
    }

    makeid( length ) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

}
