export class ISegment {
    patient: string;
    param: string;
    debut_x: string;
    debut_y: number;
    fin_x: string;
    fin_y: number;
    nuance: number;
    etat_derive: number;
}
