export class IColorsDerive {
    IdDerive: number;
    Libelle1: string;
    Libelle2: string;
    Couleur: string;
    CouleurR: string;
    rgbaR: string;
    rgbaG: string;
    rgbaB: string;
}
