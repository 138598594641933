import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PatientEvents } from '@app.models';
import { parse, format } from '@app.utils';

@Component({
    selector: 'app-event-detail',
    templateUrl: `event-detail.component.html`,
    styleUrls: [ './event-detail.component.scss' ]
})
export class EventDetailComponent implements OnInit {

    @Input() event: Partial<PatientEvents>;
    @Input() editMode: boolean;
    @Output() requestSave: EventEmitter<Partial<PatientEvents>> = new EventEmitter<Partial<PatientEvents>>();

    typeList = [
        { id: 'nutrition', label: 'Nutrition' },
        { id: 'prise_medicaments', label: 'Prise de médicament' },
        { id: 'hospitalisation', label: 'Hospitalisation' },
        { id: 'actes_medicaux', label: 'Acte médicaux' },
    ];
    eventDate;

    ngOnInit() {
        this.eventDate = parse(this.event.event_date);
    }

    saveEvent( event: Partial<PatientEvents> ) {
        this.requestSave.emit(event);
    }

    setDate( date: Date ) {
        this.event.event_date = format(date);
    }

}
