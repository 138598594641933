import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { PatientEvents } from '@app.models';
import { DialogService, PatientService } from '@app.services';
import { ColDef } from '@app.components';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from '@app.core';
import { MESSAGES } from '@app.messages';

@Component({
    selector: 'app-event-list',
    templateUrl: './event-list.component.html',
    styleUrls: [ './event-list.component.scss' ]
})
export class EventListComponent implements OnInit {

    @Input() patientId: number;
    @Input() eventList: PatientEvents[];
    newEvent: Partial<PatientEvents>;
    editedEvent: PatientEvents;

    columnDefs: ColDef<PatientEvents>[] = [
        { field: 'event_label', headerName: 'Titre' },
        // { field: 'description', headerName: 'Description' },
        { field: 'event_type', headerName: 'Type' },
        { field: 'event_date', headerName: 'Date', type: 'date' },
        { headerName: '#', colId: 'remove', icon: 'delete', onClick: async ( x ) => await this.deleteEvent(x) },
    ];
    private dialogRef: MatDialogRef<DialogComponent, any>;

    constructor( private patientService: PatientService,
                 private dialogService: DialogService ) {
    }

    ngOnInit(): void {
    }

    async addEvent( eventCreateTpl: TemplateRef<any> ) {
        this.newEvent = {
            description: null,
            event_date: new Date(),
            patient: this.patientId,
        };
        this.dialogRef = await this.dialogService.openTemplate(eventCreateTpl);
    }

    async editEvent( event: PatientEvents, eventEditTpl: TemplateRef<any> ) {
        this.editedEvent = event;
        this.dialogRef = await this.dialogService.openTemplate(eventEditTpl);
    }

    async createEvent( event: Partial<PatientEvents> ) {
        const newEvent = await this.patientService.createEvent$(event).toPromise();
        this.eventList = this.eventList.concat(newEvent);
        this.dialogService.createInfo(MESSAGES.EVENT_SAVED);
        this.dialogRef.close();
    }

    async updateEvent( event: PatientEvents ) {
        const eventServer = await this.patientService.updateEvent$(event).toPromise();
        this.eventList = this.eventList.filter(x => x !== event).concat(eventServer);
        this.dialogService.createInfo(MESSAGES.EVENT_SAVED);
        this.dialogRef.close();
    }

    async deleteEvent( event ) {
        await this.patientService.deleteEvent$(event.id).toPromise();
        this.dialogService.createInfo(MESSAGES.EVENT_DELETED);
        this.eventList = this.eventList.filter(x => x !== event);
    }

}
