export class PatientMeasure {
    id: number;
    date: string|Date;
    patient: number;
    parameter: string;
    value: string;
    max: string;
    min: string;
    unit: string;
    exam_resultId: string;
    created_at: string;
    modified_at: string;
}
