import { Component } from '@angular/core';

@Component({
    selector: 'app-loading-info',
    template: `
      <h3>Chargement en cours ...</h3>
      <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
    `
})
export class LoadingInfoComponent {
}
