import { PatientEvents } from './PatientEvents';

export class PatientQuali {
    id: string;
    analyzed: string | boolean;
    parameter;
    value;
    date: string;
    family: string;
}

export class Patient {
    id: number;
    SEXE: string;
    name: string;
    firstname: string;
    label: string;
    birthdate: Date;
    created_at: Date;
    age: number;
    weight?: number;
    size?: number;
    rf_parameters;
    covid_score_event;
    derives: any[];
    events: PatientEvents[];
    quali: PatientQuali[];
}
