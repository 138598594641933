export class ExamResults {
    id: number;
    parameter: string;
    family: string;
    value: number;
    unit: string;
    min: number;
    max: number;
    value2: string;
    min2: string;
    max2: string;
    unit2: string;
    quantitative: string;
    examId: number;
    integrated: string;
    analyzed: string;
    error: string;
    unit_convId: string;
    label_convId: string;
    level_convId: string;
    patientId: string;
    date: string;
    created_at: string;
    modified_at: string;
    st_value: number;
    in_tolerance: boolean;
    has_seuils: boolean;

    inf: number;
    sup: number;
    optimum: number;
    value_text: string;
    value_cr: number;
    value_cr_chart: number;
    config: { fill: any };

    constructor( fields: any ) {
        Object.assign(this, fields);
    }
}

export const toExamResult = a => {
    const x = new ExamResults(a);
    x.value = Number(x.value);
    x.min = Number(x.min);
    x.max = Number(x.max);
    x.value_cr = (x.value - ((x.max + x.min) / 2)) / ((x.max - x.min) / 4);
    if (!isFinite(x.value_cr)) {
        x.value_cr = 0;
    }
    x.inf = -2;
    x.sup = 2;
    x.optimum = 0;
    x.value_text = x.value + ' ' + x.unit;
    if (x.value_cr > 2) {
        x.value_cr_chart = 2;
    }
    if (x.value_cr < -2) {
        x.value_cr_chart = -2;
    }
    return x;
};
