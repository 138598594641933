import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { PatientMeasure } from '@app.models';
import { DialogService, PatientService } from '@app.services';
import { ColDef } from '@app.components';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from '@app.core';
import { MESSAGES } from '@app.messages';

@Component({
    selector: 'app-measure-list',
    templateUrl: './measure-list.component.html',
    styleUrls: [ './measure-list.component.scss' ]
})
export class MeasureListComponent implements OnInit {

    @Input() patientId: number;
    measureList: PatientMeasure[];
    newMeasure: Partial<PatientMeasure>;
    editedMeasure: PatientMeasure;

    columnDefs: ColDef<PatientMeasure>[] = [
        { field: 'date', headerName: 'Date', type: 'date' },
        { field: 'parameter', headerName: 'Paramètre' },
        { field: 'value', headerName: 'Valeur' },
        { field: 'unit', headerName: 'Unité' },
        { field: 'unit', headerName: '#', colId: 'remove', icon: 'delete', onClick: async (x) => await this.delete(x) },
    ];
    private dialogRef: MatDialogRef<DialogComponent, any>;

    constructor( private patientService: PatientService, private dialogService: DialogService ) {
    }

    async ngOnInit() {
        this.measureList = await this.patientService.getMeasureList$(this.patientId).toPromise();
        this.newMeasure = {
            parameter: null,
            date: new Date(),
            patient: this.patientId,
        };
    }

    async add( eventTpl: TemplateRef<any> ) {
        this.dialogRef = await this.dialogService.openTemplate(eventTpl);
    }

    async edit( measure, tpl: TemplateRef<any> ) {
        this.editedMeasure = measure;
        this.dialogRef = await this.dialogService.openTemplate(tpl);
    }

    async create( measure: Partial<PatientMeasure> ) {
        const newMeasure = await this.patientService.createMeasure$(measure).toPromise();
        this.measureList = this.measureList.concat(newMeasure);
        this.dialogService.createInfo(MESSAGES.MEASURE_SAVED);
        this.dialogRef.close();
    }

    async update( measure: PatientMeasure ) {
        const measureServer = await this.patientService.updateMeasure$(measure).toPromise();
        this.measureList = this.measureList.filter(x => x !== measure).concat(measureServer);
        this.dialogService.createInfo(MESSAGES.MEASURE_SAVED);
        this.dialogRef.close();
    }

    async delete( measure: PatientMeasure ) {
        await this.patientService.deleteMeasure$(measure.id).toPromise();
        this.dialogService.createInfo(MESSAGES.MEASURE_DELETED);
    }

}
