<mat-card>
  <h2>Suivi des uploads</h2>

  <div *ngIf="doctors!=[]">

    <mat-form-field>
      <mat-label>Médecin</mat-label>
      <mat-select (selectionChange)="onDoctorChange($event)">

        <mat-option  *ngFor="let option of doctors" [value]="option.doctor">{{ option.name }}</mat-option>
      </mat-select>

    </mat-form-field>
    <button mat-button (click)="removeChoice()">X</button>
    <div *ngIf="rate!=-1">Taux de bon hprim: {{rate}}%</div>
  </div>
<div class="graphique">
  <canvas id="graphCanvas" height="200px" width="400px"></canvas>
</div>

</mat-card>
