<h2>Evénements</h2>
<div class="table__div">
  <bio-grid [columnDefs]="columnDefs"
            [rowData]="eventList"
            (rowClick)="editEvent($event, eventEditTpl)"
  ></bio-grid>
</div>
<button mat-raised-button color="primary" (click)="addEvent(eventCreateTpl)">Ajouter</button>

<ng-template #eventCreateTpl>
  <app-event-detail [event]="newEvent"
                    (requestSave)="createEvent($event)"
  ></app-event-detail>
</ng-template>

<ng-template #eventEditTpl>
  <app-event-detail [event]="editedEvent" [editMode]="true"
                    (requestSave)="updateEvent($event)"
  ></app-event-detail>
</ng-template>
