import { NgModule } from '@angular/core';
import { MaterialModule } from './material-components.module';
import { TranslatePipe } from './pipes/translate.pipe';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { LoadingInfoComponent } from './loading-info/loading-info.component';

@NgModule({
    declarations: [
        LoadingInfoComponent,
        TranslatePipe,
        DateFormatPipe,
    ],
    imports: [
        MaterialModule,
    ],
    exports: [
        LoadingInfoComponent,
        TranslatePipe,
        DateFormatPipe,
        MaterialModule,
    ]
})
export class SharedModule {

}
