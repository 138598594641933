import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { IUser } from '@app.models';

export interface IWinUserVM {
    /** Family group id */
    id: string;
    state?: string;
    opened?: boolean;
    position: { x: number; y: number };
}

@Injectable()
export class UserStateService {

    private user: IUser;
    readonly defaultWindowList: IWinUserVM[] = [
        { id: 'hemato', position: null },
        { id: 'biochimie', position: null },
        { id: 'micro', position: null },
        { id: 'medicaments', position: null },
        { id: 'auto', position: null },
        { id: 'hla', position: null },
        { id: 'fertilite', position: null },
    ];

    private keyFor( patientId ): string {
        return `userWindows-${this.user.id}-${patientId}`;
    }

    constructor( private auth: AuthService ) {
        this.user = auth.getUser();
    }

    saveFor( windowList: IWinUserVM[], patientId: string ) {
        localStorage.setItem(this.keyFor(patientId), JSON.stringify(windowList));
    }

    getFor( patientId: string ): IWinUserVM[] {
        const k = localStorage.getItem(this.keyFor(patientId));
        try {
            return (k ? JSON.parse(k) : null) || this.defaultWindowList;
        } catch (e) {
            return this.defaultWindowList;
        }
    }

}
