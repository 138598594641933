import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ParameterService, PatientService } from '@app.services';
import { isNotEmptyNorUndefined, unique } from '@app.utils';
import { ExamResults, Patient, PatientExam } from '@app.models';

@Component({
    selector: 'app-exam-result',
    templateUrl: './exam-result.component.html',
    styleUrls: [ './exam-result.component.scss' ]
})
export class ExamResultComponent implements OnInit {

    @Input() patient: Patient;

    @ViewChild(MatPaginator, { static: true } as any) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true } as any) sort: MatSort;
    importedExamList: PatientExam[];
    selectExam: PatientExam;
    selectedExamResult: ExamResults[];
    families: any[];
    radarChart: { dispose(): void };

    constructor( private patientService: PatientService,
                 private parameterService: ParameterService ) {
    }

    async ngOnInit() {
        await this.getPatientExams();
    }

    async onChangeExam( event ) {
        await this.loadExamResults(this.selectExam.id);
    }

    private async loadExamResults( examId: number ) {
        this.selectedExamResult = await this.patientService.getExamResultList(examId).toPromise();
        if (this.radarChart) {
            this.radarChart.dispose();
        }
        this.radarChart = this.parameterService.createRadarChart(this.selectedExamResult);
        this.families = this.selectedExamResult.map(x => x.family).filter(unique).filter(isNotEmptyNorUndefined);
    }

    async getPatientExams() {
        const res = this.importedExamList = await this.patientService.getExamListFor(this.patient.id).toPromise();
        this.selectExam = res[res.length - 2];
        await this.loadExamResults(this.selectExam.id);
        // await this.patientService.getPatientExamResults().toPromise();
    }

    getParameterFamily( family ) {
        return this.selectedExamResult.filter(x => {
            return (x.family === family);
        });
    }

    getParameterDerive( type ) {
        if (!this.selectedExamResult) {
            return ([]);
        }
        if (type == 'derive') {
            return this.selectedExamResult.filter(x => {
                return (Math.abs(x.value_cr) < 2 && Math.abs(x.value_cr) > 1);
            });
        }
        if (type == 'HT') {
            return this.selectedExamResult.filter(x => {
                return (Math.abs(x.value_cr) > 2);
            });
        }

        if (type == 'derive pronostic') {
            return this.selectedExamResult.filter(x => {
                return (Math.abs(x.value_cr) < 2 && Math.abs(x.value_cr) > 1);
            });
        }

        if (type == 'HT pronostic') {
            return this.selectedExamResult.filter(x => {
                return (Math.abs(x.value_cr) < 2 && Math.abs(x.value_cr) > 1);
            });
        }

    }

}


