import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared';
import { GridComponent } from './grid/grid.component';
import { EventDetailComponent } from './event-detail/event-detail.component';
import { MeasureDetailComponent } from './measure-detail/measure-detail.component';
import { GrapheDeriveComponent } from './graphe-derive/graphe-derive.component';

@NgModule({
    declarations: [
        GridComponent,
        EventDetailComponent,
        MeasureDetailComponent,
        GrapheDeriveComponent,
    ],
    imports: [
        SharedModule,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
    ],
    exports: [
        GridComponent,
        EventDetailComponent,
        MeasureDetailComponent,
        GrapheDeriveComponent,
    ]
})
export class ComponentsModule {
}
