import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/overlay';
import { DialogComponent } from '@app.core';
import { get } from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';

const reg = /{([^{}]+)}/g;

export function compute( message: string, variables?: any ): string {
    if (!(message && variables && variables.hasOwnProperty !== undefined)) {
        return message;
    }
    let result = message;
    let iteration = 0;
    const nbOfKeys = Object.keys(variables).length;
    while (result.match(reg) && iteration <= nbOfKeys) {
        result = result.replace(reg, ( matched, found ) => {
            const v = get(variables, found);
            return typeof v !== 'undefined' ? v : `{${found}}` as any;
        });
        iteration++;
    }
    return result;
}

@Injectable({
    providedIn: 'root',
})
export class DialogService {

    handlers: (( msg, type ) => void)[] = [
        ( msg, type ) => this.mdSnackBar.open(msg, type),
        // ( msg, type ) => this.logger.write(type as any, msg),
    ];

    constructor( private dialog: MatDialog, private mdSnackBar: MatSnackBar ) {
    }

    createInfo( msg: string, params?: any ) {
        if (params) {
            msg = compute(msg, params);
        }
        return this.create('info', msg);
    }

    openTemplate<T>( cmp: TemplateRef<T>, winOptions = {
        minWidth: 400,
        minHeight: 500,
    } ) {
        return this.openComponent(DialogComponent, {
                content: cmp,
                ...winOptions,
            });
    }

    openComponent<T>( cmp: ComponentType<T>, data: Partial<T> ) {
        const ref = this.dialog.open(cmp, {
            width: '500px',
            height: '500px',
            hasBackdrop: true,
            data
        });
        if (data) {
            for (const k in data) {
                if (data.hasOwnProperty(k)) {
                    ref.componentInstance[k] = data[k];
                }
            }
        }
        return ref;
    }

    private create( type: string, msg: string ) {
        this.handlers.forEach(handler => handler(msg, type));
    }

}
