import { Injectable } from '@angular/core';
import { AuthService } from '@app.services';
import { Router } from '@angular/router';

@Injectable()
export class LoginService {

    private hasErrorLoginVar = false;

    constructor( private router: Router,
                 private authService: AuthService ) {
    }

    hasErrorLogin() {
        return (this.hasErrorLoginVar);
    }

    async login( email: string, password: string ) {
        try {
            const res = await this.authService.login$(email, password).toPromise();
            this.hasErrorLoginVar = false;
            await this.router.navigate([ '/' ]);
        } catch (e) {
            this.hasErrorLoginVar = true;
        }
    }

    async logout() {
        await this.authService.logout();
        await this.router.navigate([ '/login' ]);
    }

}
