import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PatientMeasure, ReferentialParameter } from '@app.models';
import { ParameterService } from '@app.services';
import { parse, format } from '@app.utils';

@Component({
    selector: 'app-measure-detail',
    templateUrl: `./measure-detail.component.html`,
    styleUrls: [ './measure-detail.component.scss' ]
})
export class MeasureDetailComponent implements OnInit {

    @Input() measure: Partial<PatientMeasure>;
    @Input() editMode: boolean;
    @Output() requestSave: EventEmitter<Partial<PatientMeasure>> = new EventEmitter<Partial<PatientMeasure>>();

    parameterList: ReferentialParameter[];
    measureDate;

    constructor( private parameterService: ParameterService ) {
        this.parameterList = this.parameterService.referentialParameterList || [];
    }

    ngOnInit() {
        this.measureDate = parse(this.measure.date);
    }

    save( event: Partial<PatientMeasure> ) {
        event.unit = this.parameterList.filter(x => x.PARAM == event.parameter).map(x=>x.UNITE)[0]
         event.min = ""
         event.max = ""
        this.requestSave.emit(event);
    }

    setDate( date: Date ) {
        this.measure.date = format(date);
    }

}
