import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { ServerError } from './server-error.class';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { AuthService } from './auth.service';

function handleResponse<T>( res: HttpResponse<T> ): T {
    if (!res) {
        throw new ServerError('This request has failed, response in null', null);
    }
    if (res.status < 200 || res.status >= 300 || res.status === 202) {
        throw new ServerError('This request has failed ' + res.status, res);
    }
    if (res.status != 200 && !res) {
        throw new ServerError('This request has failed ' + res.status, res);
    }
    return res.body as T;
}

@Injectable({
    providedIn: 'root',
})
export class RestApiService {

    host = environment.backend;

    private catchServerError = ( err ) => {
        if ([403, 401].indexOf(err.status) > -1) {
            console.error('UI error handling logout err.status is ', err.status);
            this.router.navigate([ 'login' ]);
        }
        try {
            return throwError(err.json());
        } catch (ex) {
            return throwError({ Message: err });
        }
    }

    constructor( private http: HttpClient,
                 private auth: AuthService,
                 private router: Router ) {
    }

    $get<T>( endpoint: string ): Observable<T> {
        return this.http.get<HttpResponse<T>>(this.host + endpoint, this.getHttpOptions()).pipe(
            map(handleResponse),
            catchError(this.catchServerError)
        ) as Observable<T>;
    }

    $post<T, A = any>( endpoint: string, data?: A ): Observable<T> {
        const dataString = JSON.stringify(data || {});
        return this.http.post<HttpResponse<T>>(this.host + endpoint, dataString, this.getHttpOptions()).pipe(
            map(handleResponse),
            catchError(this.catchServerError)
        ) as Observable<T>;
    }

    $put<T, A = any>( endpoint: string, data?: A ): Observable<any> {
        return this.http.put<HttpResponse<T>>(this.host + endpoint, this.getHttpOptions(), data).pipe(
            map(handleResponse),
            catchError(this.catchServerError)
        );
    }

    $delete<T>( endpoint: string ): Observable<any> {
        return this.http.delete<HttpResponse<T>>(this.host + endpoint, this.getHttpOptions()).pipe(
            map(handleResponse),
            catchError(this.catchServerError)
        );
    }

    private getHttpOptions(): { headers: HttpHeaders, observe: 'response' } {
        return {
            headers: this.auth.getAuthHeaders(),
            observe: 'response',
        };
    }

}
