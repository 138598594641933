import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { routeAnimations } from '../animations/route.animations';
import { ParameterService, UserStateService } from '@app.services';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: [ './layout.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    providers: [ UserStateService ],
    animations: [ routeAnimations ]
})
export class LayoutComponent implements OnInit {

    scrollbarDisabled = false;

    constructor( private parameterService: ParameterService ) {
    }

    onActivate( e, scrollContainer, outlet: RouterOutlet ) {
        if (scrollContainer && scrollContainer.nativeElement) {
            if (outlet.activatedRoute.snapshot.data.scrollbarDisabled && !this.scrollbarDisabled) {
                this.scrollbarDisabled = true;
                scrollContainer.nativeElement.classList.add('disable-simplebar');
            }

            if (!outlet.activatedRoute.snapshot.data.scrollbarDisabled && this.scrollbarDisabled) {
                this.scrollbarDisabled = false;
                scrollContainer.nativeElement.classList.remove('disable-simplebar');
            }
        }
    }

    async ngOnInit() {
        await this.parameterService.init();
    }

}
