import { Component, OnInit, ViewChild } from '@angular/core';
import { PatientService } from '@app.services';
import { ActivatedRoute, Router } from '@angular/router';
import { ColDef, GridComponent } from '@app.components';
import { PatientListItem } from '@app.models';
import { MatPaginator } from '@angular/material/paginator';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: [ './home.component.css' ]
})
export class HomeComponent implements OnInit {

    private patientList: PatientListItem[];
    filteredList: PatientListItem[];
    doctorId: string;
    @ViewChild(MatPaginator, { static: true } as any) paginator: MatPaginator;
    @ViewChild(GridComponent, { static: true } as any) gridCmp: GridComponent;

    displayedColumns: ColDef<PatientListItem>[] = [
        { field: 'id', headerName: 'N°' },
        { field: 'name', headerName: 'Nom' },
        { field: 'firstname', headerName: 'Prénom' },
        { field: 'SEXE', headerName: 'Sexe' },
        { field: 'birth', headerName: 'Date de naissance' },
        { field: 'last_result', headerName: 'Date dernier résultat', type: 'date' },
        { field: 'ratio_derive', headerName: '% Paramètre en écart statistique', suffix: '%' },
        { field: 'n_uploads', headerName: 'Nombre d\'analyses' },
        { field: 'n_parameters', headerName: 'Nombre de paramètres' }
    ];

    constructor( private patientService: PatientService,
                 private route: ActivatedRoute,
                 private router: Router ) {
    }

    async ngOnInit() {
        this.doctorId = this.route.snapshot.paramMap.get('id');
        await this.getPatientList(this.doctorId);
    }

    applyFilter( gridCmp, filterValue: string ) {
        gridCmp.setFilter(filterValue);
    }

    openPatientDashboard( patient ) {
        this.router.navigateByUrl(`patients/${patient.id}/dashboard`);
    }

    private async getPatientList( doctorId ) {
        this.filteredList = this.patientList = await (!!doctorId ? this.patientService.getListPatientsByDoctorId(doctorId)
            : this.patientService.getListPatients()).toPromise();
        this.gridCmp.setPaginator(this.paginator);
    }

}
