export type TPeriodType = 'hour' | 'day' | 'month' | 'quarter' | 'year';
import { format as fnsFormat, parse as fnsParse } from 'date-fns';

export function format( date: Date, dateFormat = 'yyyy-MM-dd HH:mm:ss' ) {
    return fnsFormat(date, dateFormat);
}

export function parse( date: Date | string ) {
    return date instanceof Date ? date : fnsParse(date, 'yyyy-MM-dd HH:mm:ss', new Date());
}

export interface IPeriodType {
    id: TPeriodType;
    label: string;
    seconds: number;
}

export interface ITimeGuessed {
    type: TPeriodType;
    /** total period in ms */
    total_period_ms: number;
    min: number;
    max: number;
    padding;
}

export const dateSortAscFn = ( a, b ) => -Date.parse(b) + Date.parse(a);

const timeTypeList: { type: TPeriodType, value: number }[] = [
    { type: 'hour', value: 60 * 60 },
    { type: 'day', value: 60 * 60 * 24 },
    { type: 'month', value: 60 * 60 * 24 * 31 },
    { type: 'year', value: 60 * 60 * 24 * 365 }
];

export function guessTimeInterval( dateList: string[] ): ITimeGuessed {
    const array = (dateList || []);
    const dateListDate: number[] = array
        .map(element => new Date(element).getTime())
        .sort(( a, b ) => a - b);

    if (array.length < 2) {
        return { type: 'day', total_period_ms: 86400000, min: dateListDate[0], max: dateListDate[0], padding: 1 };
    }
    const max = dateListDate[dateListDate.length - 1];
    const min = dateListDate[0];
    const total_period_ms = max - min;
    const bestTimeType = timeTypeList.find(x => total_period_ms / x.value / 1000 <= 1) || timeTypeList[timeTypeList.length - 1];
    return {
        type: bestTimeType.type,
        total_period_ms,
        min,
        max,
        padding: 1
    };
}

// export function guessTimeIntervalOld( dateList: string[] ): ITimeGuessed {
//     if (dateList.length === 1 || dateList.length === 0) {
//         return ({ type: 'day', total_period_ms: 86400000 });
//     }
//     const dateListDate: any[] = dateList.map(element => new Date(element));
//     const res = [];
//     dateListDate.forEach(( value, i ) => {
//         if (i !== 0) {
//             res.push(dateListDate[i] - dateListDate[i - 1]);
//         }
//     });
//
//     const meanDiffTime = mean(res);
//     const tmp = Math.floor(meanDiffTime / 1000);
//
//     // Nombre de secondes entre les 2 dates
//
//     const diff = timeTypeList.map(x => ({...x, value: tmp / x.value}));
//     const infzero = filter(diff, ( o ) => o.value > 1);
//     const valuesInfZero: number[] = map(infzero, 'value');
//
//     if (valuesInfZero.length === 0) {
//         const maxIn = minInArray(map(diff, 'value'));
//         const t = filter(diff, ( o ) => o.value === maxIn);
//         const type_res = t[0].type;
//         return ({
//             type: type_res,
//             total_period_ms: meanDiffTime
//         });
//     } else {
//         const maxIn = minInArray(valuesInfZero);
//         const t = filter(diff, ( o ) => o.value === maxIn);
//         const type_res = t[0].type;
//         return ({
//             type: type_res,
//             total_period_ms: meanDiffTime
//         });
//     }
// }
//
// function minInArray( array: number[] ) {
//     if (array.length === 0) {
//         return undefined;
//     }
//     if (array.length === 1) {
//         return (array[0]);
//     } else {
//         return array.reduce(( a, b ) => Math.min(a, b));
//     }
// }
