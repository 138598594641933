import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestApiService } from '@app.services';

@Injectable()
export class RegisterService {

    constructor(
        private apiRestService: RestApiService ) {
    }

    createAccount( accountForm ): Observable<boolean> {
        return this.apiRestService.$post<boolean>('createAccount', accountForm.getRawValue());
    }

}
