export class MeasureParameter {
    date: string;
    valeur: number;
    patient: string;
    parameter: string;
    min: number;
    date_end: string;
    max: number;
    nuance: number;
    etat_derive: number;
    date_start:string;
    max_next: number;
    min_next: number;
}
