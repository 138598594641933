import { ReferentialParameter } from './ReferentialParameter';
import { PatientParametreOptim } from './PatientParametreOptim';
import { IColorsDerive } from './IColorsDerive';
import { ISegment } from './ISegment';

export class ParameterChartData {
    ref: ReferentialParameter[];
    data_patient: any[];
    ref_optim: PatientParametreOptim[];
    color_derive: IColorsDerive[];
    segments: ISegment[];
    estimation: number;
    parameter_personnalisant: any[];
}
