import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-dialog',
    template: `
      <os-window [width]="width" [height]="height"
                 [minWidth]="minWidth" [minHeight]="minHeight"
                 (afterClose)="close()">
        <div *ngIf="title" osWindowTitle>{{title}}</div>
        <div osWindowContent>
          <ng-container [ngTemplateOutlet]="template || defaultTemplate"></ng-container>
        </div>
      </os-window>
      <ng-template #defaultTemplate>
        {{content}}
      </ng-template>
    `,
    styles: [
        `
        :host {
            display: block;
            position: relative;
        }
        [mat-icon-button] {
            position: absolute;
            right: -16px;
            top: -16px;
        }
        [osWindowContent] {
            padding: 0 24px;
        }
        `
    ]
})
export class DialogComponent implements OnInit {
    @Input() title: TemplateRef<any> | string;
    @Input() content: TemplateRef<any> | string;
    template: TemplateRef<any>;
    width: number;
    height: number;
    minWidth = 400;
    minHeight = 500;

    constructor( private ref: MatDialogRef<any> ) {
    }

    ngOnInit() {
        this.template = this.content instanceof TemplateRef ? this.content : null;
        this.width = window.innerWidth * 0.8;
        this.height = window.innerHeight * 0.6;
    }

    close() {
        this.ref.close();
    }

}
