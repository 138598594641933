<div mat-dialog-title>{{ editMode ? 'Edition de la mesure' : 'Nouvelle mesure' }}</div>
<mat-dialog-content>
  <mat-form-field>
    <mat-label>Paramètre</mat-label>
    <mat-select placeholder="Sélectionner le paramètre" [(ngModel)]="measure.parameter">
      <mat-option *ngFor="let row of parameterList" [value]="row.PARAM">{{row.libelle}} </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Valeur</mat-label>
    <input matInput placeholder="Valeur de la mesure" [(ngModel)]="measure.value">
  </mat-form-field>
  <mat-form-field>
    <mat-label>Date de début</mat-label>
    <input [ngModel]="measureDate"
           (ngModelChange)="setDate($event)"
           placeholder="Sélectionner la date"
           [matDatepicker]="pickerStart" matInput>
    <button mat-icon-button matSuffix (click)="pickerStart.open()">
      <mat-icon>calendar_today</mat-icon>
    </button>
    <mat-datepicker #pickerStart></mat-datepicker>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-dialog-close="null" mat-stroked-button>Annuler</button>
  <button mat-raised-button color="primary" (click)="save(measure)">Enregistrer</button>
</mat-dialog-actions>
