<div #osWindowParent
     (mousedown)="focus()"
     class="os-window-parent"
     style="z-index: var(--zIndex);">

  <div (cdkDragEnded)="storeWindowPos($event)"
       (cdkDragStarted)="demaximize()"
       (mousedown)="storeMousePos($event)"
       [cdkDragFreeDragPosition]="this.win.setPosition"
       cdkDrag
       class="os-window">

    <div (cdkDragEnded)="endResize()"
         (cdkDragMoved)="resize($event, 'n')"
         (cdkDragStarted)="startResize()"
         [cdkDragDisabled]="this.win.rules.disableResize"
         [cdkDragFreeDragPosition]="this.win.resize.n"
         cdkDrag
         cdkDragLockAxis="y"
         class="window-resize n"></div>
    <div
            (cdkDragEnded)="endResize()"
            (cdkDragMoved)="resize($event, 'ne')"
            (cdkDragStarted)="startResize()"
            [cdkDragDisabled]="this.win.rules.disableResize"
            [cdkDragFreeDragPosition]="this.win.resize.ne"
            cdkDrag
            class="window-resize ne"></div>
    <div
            (cdkDragEnded)="endResize()"
            (cdkDragMoved)="resize($event, 'e')"
            (cdkDragStarted)="startResize()"
            [cdkDragDisabled]="this.win.rules.disableResize"
            [cdkDragFreeDragPosition]="this.win.resize.e"
            cdkDrag
            cdkDragLockAxis="x"
            class="window-resize e"></div>
    <div
            (cdkDragEnded)="endResize()"
            (cdkDragMoved)="resize($event, 'se')"
            (cdkDragStarted)="startResize()"
            [cdkDragDisabled]="this.win.rules.disableResize"
            [cdkDragFreeDragPosition]="this.win.resize.se"
            cdkDrag
            class="window-resize se"></div>
    <div
            (cdkDragEnded)="endResize()"
            (cdkDragMoved)="resize($event, 's')"
            (cdkDragStarted)="startResize()"
            [cdkDragDisabled]="this.win.rules.disableResize"
            [cdkDragFreeDragPosition]="this.win.resize.s"
            cdkDrag
            cdkDragLockAxis="y"
            class="window-resize s"></div>
    <div
            (cdkDragEnded)="endResize()"
            (cdkDragMoved)="resize($event, 'sw')"
            (cdkDragStarted)="startResize()"
            [cdkDragDisabled]="this.win.rules.disableResize"
            [cdkDragFreeDragPosition]="this.win.resize.sw"
            cdkDrag
            class="window-resize sw"></div>
    <div
            (cdkDragEnded)="endResize()"
            (cdkDragMoved)="resize($event, 'w')"
            (cdkDragStarted)="startResize()"
            [cdkDragDisabled]="this.win.rules.disableResize"
            [cdkDragFreeDragPosition]="this.win.resize.w"
            cdkDrag
            cdkDragLockAxis="x"
            class="window-resize w"></div>
    <div
            (cdkDragEnded)="endResize()"
            (cdkDragMoved)="resize($event, 'nw')"
            (cdkDragStarted)="startResize()"
            [cdkDragDisabled]="this.win.rules.disableResize"
            [cdkDragFreeDragPosition]="this.win.resize.nw"
            cdkDrag
            class="window-resize nw"></div>


    <div class="window-bar">

      <!-- <div (dblclick)="maximize()" -->
      <div 
           cdkDragHandle
           class="window-bar-anchor">

        <div class="window-title">
          <ng-content select="window-title, [window-title], [osWindowTitle]"></ng-content>
        </div>

        <div class="controls-separator"></div>
 
        <div class="controls-container">
          <!-- <button (click)="minimize()" class="win-controls">
            <span class="material-icons">
              minimize
            </span>
          </button> -->
          <!-- <button (click)="maximize()" class="win-controls">
            <span class="material-icons">
              {{ win.state.maximized ? 'close_fullscreen' : 'open_in_full' }}
            </span>
          </button> -->
          <button (click)="close()" class="win-controls">
            <span class="material-icons">
              close
            </span>
          </button>
        </div>
      </div>
    </div>

    <div class="window-content">
        <ng-content select="window-content, [window-content], [osWindowContent]">
        </ng-content>
    </div>
  </div>
</div>
