<ng-container *ngIf="patient">
  <div class="card__person">
    <svg class="svgMenu"><use xlink:href="assets/sprite.svg#back" /></svg>
    <div>
      <mat-icon class="icon-size-xl">
        account_circle
      </mat-icon>
      <div matTooltip="Id Patient: {{patient.id}}">
        <div>Nom, prénom</div>
        <h4>{{patient.name}} {{patient.firstname}}</h4>
      </div>
      <div>
        <div>Description: <b>{{patient.label | slice:0:6}}</b></div>
        <div>Sexe: <b>{{patient.SEXE}}</b></div>
        <div>Naissance: <b>{{patient.birthdate | amDateFormat }}</b></div>
        <div>Age: <b>{{patient.age || 32}}</b></div>
      </div>
      <div class="fxGrow">
        <div><b>Poids : {{patient.weight || '83'}} </b></div>
        <div><b>Taille : {{patient.size || '175'}} </b></div>
        <div><b>IMC : {{patient.weight || '23'}} </b></div>
        <div><b>Groupe Sanguin : A </b></div>
      </div>
    </div>
  </div>

  <div class="page__title"></div>
  <div class="page__container">
    <div class="win-container" *ngIf="activeTabId == 1">
      <os-window *ngFor="let w of windowListOpenedVM"
                 [minWidth]="300"
                 [maximizable]="true"
                 [resizable]="true"
                 (afterResize)="storeSize(w.win, $event)"
                 (afterClose)="close(w.win, $event)"
                 [startPosition]="w.win.position"
                 position="left bottom">
        <div osWindowTitle>{{w.tpl.title}}</div>
        <div osWindowContent>
          <ng-container
                  [ngTemplateOutlet]="parameterDefTpl"
                  [ngTemplateOutletContext]="{groupDateList: w.groupDateList}"
          ></ng-container>
        </div>
      </os-window>
    </div>

    <mat-button-toggle-group>
      <mat-button-toggle
              *ngFor="let tab of tabs" [checked]="tab.checked" [value]="tab.id"
              (change)="activeTabId = $event.source.value">
        {{tab.name}}
      </mat-button-toggle>
      
    </mat-button-toggle-group>
  
    <app-tableau-derive
            *ngIf="patient && (activeTabId == 1 || activeTabId == 2)"
            [patient]="patient"
            (requestOpen)="openWindow($event)"
            [estimation]="getEstimationStatus()"
    ></app-tableau-derive>
    <app-exam-result
            *ngIf="activeTabId === 3"
            [patient]="patient"
    ></app-exam-result>
    <app-event-list
            *ngIf="activeTabId === 4"
            [patientId]="patient.id"
            [eventList]="eventList"
    ></app-event-list>
    <app-measure-list
      *ngIf="activeTabId === 5"
      [patientId]="patient.id"
    >
    </app-measure-list>
  </div>

</ng-container>


<ng-template #parameterDefTpl let-groupDateList="groupDateList">
  <div *ngFor="let e of groupDateList">
    <h4>Date d'examen : {{e.date | date: 'fullDate'}}</h4>
    <ul>
      <li *ngFor="let o of e.list">{{o.parameter}} : {{o.value}}</li>
    </ul>
  </div>
  <div *ngIf="groupDateList.length < 1">
    Aucun résultat
  </div>
</ng-template>
