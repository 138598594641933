export class ReferentialParameter {
    ID: number;
    mean: number;
    ectype: number;
    parameter: string;
    Tolerance_labo_haute_Femme: number;
    Tolerance_labo_base_Femme: number;
    Tolerance_labo_haute_Homme: number;
    Tolerance_labo_base_Homme: number;
    PARAM: string;
    TOLERANCE_LABO_BASE_FEMME: number;
    TOLERANCE_LABO_HAUTE_FEMME: number;
    TOLERANCE_LABO_BASE_HOMME: number;
    TOLERANCE_LABO_HAUTE_HOMME: number;
    unit: string;
    UNITE: string;
    libelle: string;
}
