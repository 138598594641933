import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fury-dashboard-import',
  templateUrl: './dashboard-import.component.html',
  styleUrls: ['./dashboard-import.component.scss']
})
export class DashboardImportComponent implements OnInit {
  activeTabId = 1;
  tabs: any[] =  [
    {name: 'Importer de nouveaux résultats', id: 1, checked: true},
    {name: 'Résultats importés', id: 2, checked: false}];
  constructor() { }

  ngOnInit() {
  }

}
