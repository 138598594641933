import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ImportedFile } from '@app.models';
import { RestApiService } from '@app.services';

@Component({
    selector: 'fury-imported-files',
    templateUrl: './imported-files.component.html',
    styleUrls: [ './imported-files.component.scss' ]
})
export class ImportedFilesComponent implements OnInit {
    dataSource;
    @ViewChild(MatPaginator, { static: true } as any) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true } as any) sort: MatSort;
    importedFilesList: ImportedFile[];
    private url_getuploads = 'api/getuploads';  // URL to web api
    displayedColumns: string[] = [ 'id', 'from_file', 'hprim_format', 'hprim_status', 'patient', 'to_import' ];
    columns = [
        { prop: 'name' }, { name: 'firstname' }, { name: 'sexe' }
    ];

    constructor(
        public restApiService: RestApiService,
        public router: Router
    ) {
    }

    ngOnInit() {
        this.getImportedFiles();
    }

    getImportedFiles(): void {
        this.restApiService.$get<any>(this.url_getuploads).subscribe(res => {
            this.importedFilesList = res;

            this.dataSource = new MatTableDataSource<any>(this.importedFilesList);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
        });
    }

}
