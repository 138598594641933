import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestApiService } from '@app.services';
import { UploadsView } from '@app.models';

@Injectable()
export class AdminUploadsViewService {

    constructor( public apiRestService: RestApiService ) {
    }

    private url_listedoctor = 'api/getUploadsByDoctors';  // URL to web api

    getUploadsByDoctors(): Observable<UploadsView[]> {
        return this.apiRestService.$get<UploadsView[]>(this.url_listedoctor);
    }

}
