import { HttpResponse } from '@angular/common/http';

export class ServerError extends Error {
    public status: number;

    public constructor( msg: string, public response: HttpResponse<any> ) {
        super(msg);
        this.status = response.status;
    }
}
