<mat-card>
  <h2>Importer des relevés de résultats d'examens biologiques </h2>
<div [formGroup]="form" >
 
   

  <div class="form-group">
    <input type="file" hidden="true" (change)="onFileChange($event)" multiple="true" #fileInput >
   
  </div>
  
 </div>




<div class="center">
    <ngx-file-drop (onFileDrop)="dropped($event)"
    (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)" (click)="uploadFileclick()">
        <span>Déposez le(s) fichier(s) ici</span>
    </ngx-file-drop>
  <mat-card  matTooltip="Progression des imports">
    <mat-progress-bar mode="determinate" [value]="progressBarValue">{{progressBarValue}}</mat-progress-bar>
  </mat-card>
    <div class="upload-table">
        <table class="table">
          <thead >
              <tr>
<!--                  <th></th>-->
                  <th>Status</th>
                  <th>Laboratoire</th>
                  <th>Nom</th>
                  <th>Prénom</th>
                  <th>Naissance</th>
                  <th>Date</th>
                  
                  <th>Fichier</th>
<!--                  <th>Actions</th>-->
              </tr>
              
          </thead>
          <tbody class="upload-name-style"> 
            
              
              <tr *ngFor="let file of this.fileContentArray.controls; let i=index" [formGroup]="file">
<!--                  <td><mat-checkbox formControlName ="to_import" [disabled]="file.value.hprim_format==false" [checked]="file.value.hprim_format==true" ></mat-checkbox></td>-->
                  <td><strong><mat-icon style="color:green"   matTooltip="Le fichier a bien été importé" *ngIf="file.value.hprim_format==true">done</mat-icon>
                    <mat-icon style="color:red"  matTooltip="Le format du fichier Hprim est incorrect" *ngIf="file.value.hprim_format==false">close</mat-icon></strong></td>
                  <td><strong>{{ file.value.hprim_header.laboratory }} </strong></td>
                  <td>{{ file.value.hprim_header.name }} </td>
                  <td>{{ file.value.hprim_header.firstname }} </td>
                  <td>{{ file.value.hprim_header.birthdate }}</td>
                  <td>{{ file.value.hprim_header.date }} </td>
                 
                    <td>{{ file.value.from_file }}</td>
<!--                  <td><button mat-button [matMenuTriggerFor]="menu"><mat-icon>more_horiz</mat-icon></button>-->
<!--                    <mat-menu #menu="matMenu">-->
<!--                      <button mat-menu-item (click)='openDialog()'><mat-icon>remove_red_eye</mat-icon>Consulter</button>-->
<!--                      <button mat-menu-item ><mat-icon>close</mat-icon>Supprimer</button>-->
<!--                    </mat-menu></td>-->
              </tr>
          </tbody>
      </table>
    </div>
    <button mat-button  matTooltip="Vide la liste des fichiers importés" class="block" style="background-color:whitesmoke;color:black" (click)="clearFile()" >
        <mat-icon>delete</mat-icon>
    </button>
<!--    <button mat-raised-button class="block" (click)="send()" [disabled]="form.invalid"  >Importer les fichiers sélectionnés <i class="fa fa-spinner fa-spin fa-fw" *ngIf="loading"></i></button>-->
<!-- -->
</div>
</mat-card>


