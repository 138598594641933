import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './core/layout/layout.component';
import { AuthGuardService as AuthGuard } from '@app.services';
import { DashboardLastUploadsComponent } from './pages/apps/dashboard-last-uploads/dashboard-last-uploads.component';
import { AdminDoctorComponent } from './pages/apps/admin-doctor/admin-doctor.component';
import { AdminUploadsViewComponent } from './pages/apps/admin-uploads-view/admin-uploads-view.component';
import { DashboardImportComponent } from './pages/apps/dashboard-import/dashboard-import.component';
import { HomeComponent } from './pages/apps/home/home.component';
import { DashboardPatientComponent } from './pages/apps/dashboard-patient/dashboard-patient.component';
import { TranscodificationLOINCComponent } from './pages/apps/transcodification-loinc/transcodification-loinc.component';

// Do not uncomment these lines ... necessary for linking with '@app.shared' in tsconfig.app.json
import { RegisterModule } from './pages/custom-pages/register/register.module';
import { LoginModule } from './pages/custom-pages/login/login.module';
import { MotionDesignModule } from './pages/custom-pages/motion-design/motion-design.module';
import { PatientModule } from './+app-patient/patient.module';
import { MotionDesignComponent } from './pages/custom-pages/motion-design/motion-design.component';

const routes: Routes = [
    {
        path: 'login',
        loadChildren: () => import('app/pages/custom-pages/login/login.module').then(m => m.LoginModule),
    },
    {
        path: 'register',
        loadChildren: () => import('app/pages/custom-pages/register/register.module').then(m => m.RegisterModule),
    },
    {
        path: 'app-patient',
        loadChildren: () => import('app/+app-patient/patient.module').then(m => m.PatientModule),
    },
    {
        path: 'motion-design',
        loadChildren: () => import('app/pages/custom-pages/motion-design/motion-design.module').then(m => m.MotionDesignModule),
    }
    ,
    {
        path: '',
        canActivate: [ AuthGuard ],
        component: LayoutComponent,
        children: [
            {
                path: 'loinc',
                component: TranscodificationLOINCComponent,
            },
            {
                path: 'patients',
                component: HomeComponent,
            },
            {
                path: 'home',
                component: DashboardLastUploadsComponent,
            },
            {
                path: 'import',
                component: DashboardImportComponent,
            },
            {
                path: 'admin-doctor-view',
                component: AdminDoctorComponent,
            },
            {
                path: 'admin-uploads-view',
                component: AdminUploadsViewComponent,
            },
            {
                path: 'patients/:id/dashboard',
                component: DashboardPatientComponent,
            },
            {
                path: '**',
                redirectTo: 'home'
            },
        ]
    }
];

@NgModule({
    imports: [ RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    relativeLinkResolution: 'legacy'
}) ],
    exports: [ RouterModule ],
    providers: []
})
export class AppRoutingModule {
}
