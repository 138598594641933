<mat-card>

  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Rechercher les docteurs">

  </mat-form-field>
  <div class="mat-elevation-z8">

    <table mat-table matSort [dataSource]="dataSource">

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Id</th>
        <td mat-cell *matCellDef="let row"> {{row.id}} </td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email</th>
        <td mat-cell *matCellDef="let row"> {{row.email}} </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nom</th>
        <td mat-cell *matCellDef="let row"> {{row.name}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="surname">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Prénom</th>
        <td mat-cell *matCellDef="let row"> {{row.surname}} </td>
      </ng-container>

      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Role</th>
        <td mat-cell *matCellDef="let row"> {{row.role}} </td>
      </ng-container>
      <ng-container matColumnDef="see">
        <th mat-header-cell *matHeaderCellDef> Consulter</th>
        <td mat-cell *matCellDef="let row">
          <button mat-button (click)="openDoctorDashboard(row.id)">
            <mat-icon>remove_red_eye</mat-icon>
          </button>

      </ng-container>

      <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date de création</th>
        <td mat-cell *matCellDef="let row"> {{row.created_at}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openDoctorDashboard(row)"></tr>
    </table>
    <mat-paginator [pageSize]=50 [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
  </div>
</mat-card>

