import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RegisterService } from './register.service';
import {MatSnackBar} from '@angular/material/snack-bar';
@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: [ './register.component.scss' ],
})
export class RegisterComponent implements OnInit {

    form: FormGroup;

    inputType = 'password';
    visible = false;
    response;

    constructor( private router: Router,
                 private fb: FormBuilder,
                 private cd: ChangeDetectorRef,
                 private registerService: RegisterService,
                 private _snackBar: MatSnackBar
    ) {
    }

    ngOnInit() {
        this.form = this.fb.group({
            name: [ '', Validators.required ],
            firstName: [ '', Validators.required ],
            email: [ '', Validators.required ],
            password: [ '', Validators.required ],
            passwordConfirm: [ '', Validators.required ],
        });
    }

    send() {
        if (this.form.controls['password'].value !== this.form.controls['passwordConfirm'].value) {
            this.response = 'Les deux mots de passe ne sont pas identiques';
            return ('');
        }
        this.registerService.createAccount(this.form).subscribe(res => {
            if (res == true) {
                this._snackBar.openFromComponent(RegisterSnackBarComponent, {
                    duration: 3 * 1000,
                    horizontalPosition: 'center'
                  });
                this.response = 'Inscription réussi';
            } else {
                this.response = 'Vous êtes déja inscrit';
            }
        });
        setTimeout(() => {
            this.router.navigate([ '/login' ]);
        }, 2000);
    }

    toggleVisibility() {
        if (this.visible) {
            this.inputType = 'password';
            this.visible = false;
            this.cd.markForCheck();
        } else {
            this.inputType = 'text';
            this.visible = true;
            this.cd.markForCheck();
        }
    }

}

@Component({
    selector: 'snack-bar-component-register',
    templateUrl: 'snack-bar-component-register.html',
    
  })
  export class RegisterSnackBarComponent {}