import { Component, OnInit, ViewChild } from '@angular/core';
import { Doctor } from '@app.models';
import { filter } from '@app.utils';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { AdminDoctorService } from './admin-doctor.service';

@Component({
    selector: 'fury-admin-doctor',
    templateUrl: './admin-doctor.component.html',
    styleUrls: [ './admin-doctor.component.scss' ]
})
export class AdminDoctorComponent implements OnInit {

    selectedDoctor: Doctor;
    listDoctor: Doctor[];
    filteredList: Doctor[];
    dataSource;
    @ViewChild(MatPaginator, { static: true } as any) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true } as any) sort: MatSort;

    displayedColumns: string[] = [ 'id', 'email', 'name', 'surname', 'role', 'created_at' ];

    constructor(
        public adminDoctorService: AdminDoctorService,
        public router: Router
    ) {
    }

    ngOnInit() {
        this.getListDoctors();
    }

    filterListDoctors( event ): void {
        const val = event.target.value.toLowerCase();
        // filter our data
        this.filteredList = this.listDoctor.filter(function ( d ) {
            return d.surname.toLowerCase().indexOf(val) !== -1 || !val;
        });
    }

    applyFilter( filterValue: string ) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    openDoctorDashboard( doctorId ) {
        this.router.navigateByUrl('/doctor-dashboard/' + doctorId.id);
    }

    getListDoctors(): void {
        this.adminDoctorService.getListDoctor().subscribe(doctors => {
            this.listDoctor = doctors;
            this.filteredList = doctors;
            this.filteredList = this.listDoctor;
            this.dataSource = new MatTableDataSource<any>(this.filteredList);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
        });


    }

    updateSelectedPatient( selected ) {
        this.selectedDoctor = selected;
    }

    getValueFromSelect( value ) {
        this.selectedDoctor = filter(this.filteredList, [ 'id', value ])[0];
    }

}
