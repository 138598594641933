<h2>Compte rendus augmenté</h2>
<mat-form-field appearance="fill" class="mat-select-width">
  <mat-label>Examen antérieurs</mat-label>
  <mat-select [(ngModel)]="selectExam" (selectionChange)="onChangeExam($event)">
    <mat-option *ngFor="let row of importedExamList" [value]="row">
      {{row.date | date :'fullDate'}}
    </mat-option>
  </mat-select>
</mat-form-field>
<div class="container">
  <div class="grid__1">
    <div class="card info_exam">
      <p class="info" *ngIf="selectExam">
        Date: {{selectExam.date | date :'fullDate'}}
      </p>
      <p class="info">Laboratoire: --</p>
      <p class="info">Prescripteur: --</p>
      <p class="info"> Ville: --</p>
      <button mat-button color="primary">
        Télécharger le compte rendus en PDF
      </button>
      <button mat-button color="primary">
        Télécharger l'ordonnance automatique
      </button>
     
    </div>
    <div class="card text-center">
      <!-- <div class="gauge" id="chartdiv"></div> -->
    </div>
  </div>
  <div class="grid__1">
    <div>
      <div class="card__title">
        Paramètres mesurés à risques
      </div>
      <div class="grid__2">
        <div class="card">
          <h4> Paramètres en écart statistique </h4>
          <div class="card__content">
            <p *ngFor="let param of getParameterDerive('derive')">{{param.parameter}}</p>
          </div>
        </div>
        <div class="card">
          <h4>Paramètres hors valeurs seuils</h4>
          <div class="card__content">
            <p *ngFor="let param of getParameterDerive('HT')">{{param.parameter}}</p>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="card__title">
        Paramètres estimés à risques
      </div>
      <div class="grid__3">
        <div class="card">
          <h4>Paramètres estimés hors valeurs seuils</h4>
          <div class="card__content">
            <p>Troponine I</p>
            <p>T4 Libre</p>
            <p>LDH</p>
            <p>Cortisol</p>
          </div>
        </div>
        <div class="card">
          <h4>Paramètres estimés en écart stastique</h4>
          <div class="card__content">
            <p>Potassium Urinaire</p>
            <p>Prolactine</p>
            <p>TSH</p>
            <p>Phosphore</p>
            <p>Beta-Globuline</p>
          </div>
        </div>
        <div class="card">
          <h4>Recommandations HAS</h4>
          <div class="card__content">
            <p> Mesurer l'hémoglobine glyquée</p>
            <p>Remesurer la CRP dans 3 jours</p>
            <p>Suivre l'évolution du calcium dans 7 jours</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="families">
    <div class="card__title">
      Résultats
    </div>
    <div >
      <div  *ngFor="let fam of families">
        <br>
        <h4>{{fam}}</h4>
        <table class="table">
          <tr *ngFor="let param of getParameterFamily(fam)">
            <td class="fix-width">{{param.parameter}}</td>
            <td class="fix-width">{{param.value}} {{param.unit}}</td>
            <td class="fix-width">[{{param.min}} - {{param.max}}]</td>
          </tr>
        </table>
      </div>
    </div>
  </ng-container>
</div>
