import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

export interface ColDef<T = any> {
    field?: (keyof T)|string;
    suffix?: string;
    headerName?: string;
    dateFormat?: string;
    colId?: (keyof T)|string;
    onClick?: ( x ) => void;
    type?: 'date' | 'text';
    icon?: string;
}

interface InternalColDef<T> {
    colId: (keyof T)|string|any;
    suffix: string;
    field: string;
    headerName: string;
    type: string;
    dateFormat: string;
    icon: string;
    onClick: ( x: T ) => void;
}

@Component({
    selector: 'bio-grid',
    templateUrl: './grid.component.html',
    styles: [
        `
            table {
                width: 100%;
            }
        `
    ],
})
export class GridComponent<T = any> implements OnInit, OnChanges {

    @Input() columnDefs: ColDef[];
    @Input() rowData: T[];
    @Output() rowClick: EventEmitter<T> = new EventEmitter<T>();

    @ViewChild(MatPaginator, { static: true } as any) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true } as any) sort: MatSort;
    datasource: MatTableDataSource<T>;
    columns_parameters: string[];
    myCols: InternalColDef<T>[];
    private initialized = false;

    ngOnInit() {
        this.myCols = this.columnDefs.map(x => {
            return {
                colId: x.colId || x.field,
                field: String(x.field),
                headerName: String(x.headerName || x.field),
                type: 'onClick' in x ? 'button' : x.type || 'text',
                onClick: x.onClick,
                dateFormat: x.dateFormat || 'fullDate',
                icon: x.icon,
                suffix: x.suffix,
            };
        });
        this.columns_parameters = this.myCols.map(x => x.colId);
        this.datasource = new MatTableDataSource<any>(this.rowData || []);
        this.datasource.paginator = this.paginator;
        this.datasource.sort = this.sort;
        this.initialized = true;
    }

    ngOnChanges( changes: SimpleChanges ) {
        if (this.initialized) {
            this.datasource.data = this.rowData || [];
        }
    }

    clickOnRow( row: T ) {
        this.rowClick.emit(row);
    }

    clickOnCell( jsEvent: Event, col: InternalColDef<T>, row: T ) {
        jsEvent.stopImmediatePropagation();
        col.onClick(row);
    }

    setFilter( filterValue ) {
        this.datasource.filter = filterValue.trim().toLowerCase();
    }

    setPaginator( paginator: MatPaginator ) {
        this.datasource.paginator = paginator;
    }

}
