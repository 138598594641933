import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestApiService } from '@app.services';
import { Doctor } from '@app.models';

@Injectable()
export class AdminDoctorService {

    constructor( public apiRestService: RestApiService ) {
    }

    private url_listedoctor = 'api/getDoctorList';  // URL to web api

    getListDoctor(): Observable<Doctor[]> {
        return this.apiRestService.$get<Doctor[]>(this.url_listedoctor);
    }

}
