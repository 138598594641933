<div class="sidenav">
  <div class="toolbar">
     <img src="/assets/img/logo_text.png">
  </div>
  <div class="sidenav-items">
    <app-sidenav-item [item]="homeItem" [count]="emergenciesCount"></app-sidenav-item>
    <app-sidenav-item [item]="patientItem"></app-sidenav-item>
    <app-sidenav-item *ngFor="let item of items" [item]="item"></app-sidenav-item>
    <div class="fxGrow"></div>
    <app-sidenav-item [item]="disconnectItem"></app-sidenav-item>
  </div>
</div>
