<div class="graph__container" *ngIf="no_graph==false">
  <!-- <button mat-icon-button (click)="prev()">
   <mat-icon>chevron_left</mat-icon>
 </button> -->
  <div>
    <canvas id="graphCanvas"></canvas>
  </div>
  <!-- <button mat-icon-button (click)="next()">
      <mat-icon>chevron_right</mat-icon>
    </button> -->
  <div>
    <h3>Paramètres personnalisants</h3>
    <ng-container *ngIf="res.parameter_personnalisant.length>0">
      <p *ngFor="let par of res.parameter_personnalisant">{{par | titlecase}}</p>
    </ng-container>
    <ng-container
            *ngIf="res.parameter_personnalisant.length==0 && this.ref_model_patient.model!==this.string_no_derive">
      <p>Aucun paramètre personnalisant n'a été utilisé pour optimiser la population de référence</p>
    </ng-container>
    <ng-container *ngIf="this.ref_model_patient.model===this.string_no_derive">
      <p>Ce paramètre ne présente pas de calcul de niveau d’écart statistique car il n’atteint pas les objectifs de
        performance visés. Le traitement suivant a alors été appliqué :</p>
      <ul>
        <li>Si le résultat du patient est à l’intérieur des valeurs seuils de référence du laboratoire :
          le paramètre est affiché dans le niveau d’écart statistique "Optimum personnalisé".
        </li>
        <li>Si le résultat du patient est à l’extérieur des valeurs seuils de référence du laboratoire : le paramètre
          est affiché "Hors valeur seuil haute/basse"
        </li>
      </ul>
    </ng-container>
    <h3>Période</h3>
    <mat-button-toggle-group [ngModel]="periodTypeId" (ngModelChange)="setPeriodType($event)"
                             name="fontStyle" aria-label="Font Style">
      <mat-button-toggle [value]="p.id" *ngFor="let p of periodList">{{p.label}}</mat-button-toggle>
    </mat-button-toggle-group>

    <h3>Zoom</h3>

    <button mat-icon-button (click)="zoomOut()">
      <mat-icon>zoom_out</mat-icon>
    </button>
    <button mat-icon-button (click)="zoomIn()">
      <mat-icon>zoom_in</mat-icon>
    </button>

    <button mat-raised-button (click)="resetZoom()">Réinitialiser le zoom</button>
    <button mat-raised-button (click)="zoomShowAll()">Afficher tout l'historique</button>

    <div class="fxCenter">
      <h3>Evénements</h3>
      <button mat-raised-button color="primary" (click)="addEvent(eventTpl)">
        Ajouter
      </button>
    </div>
    <div class="evt__container">
      <div class="evt" *ngFor="let e of eventList">
        <div><b>{{e.event_date | date:'fullDate' }}</b></div>
        <div> {{e.event_label || e.description}}</div>
      </div>
    </div>
    <div *ngIf="false">
      <button mat-raised-button (click)="setLogarithmicY()">
        {{yIsLog ? 'Axe Y logarithmique' : 'Axe Y linéaire'}}
      </button>
    </div>
  </div>
</div>

<div class="no_graph " *ngIf="no_graph==true">
  <p>
    Le graphique pour le paramètre {{parameter}} n'est pas disponible actuellement.
  </p>
</div>

<ng-template #eventTpl>
  <app-event-detail [event]="newEvent" (requestSave)="saveEvent($event)"></app-event-detail>
</ng-template>
