import { Component, OnInit } from '@angular/core';
import { UploadsView } from '@app.models';
import { AdminUploadsViewService } from './admin-uploads-view.service';
import { Chart } from 'chart.js';
import { filter, max, uniq, map, sum } from '@app.utils';

@Component({
    selector: 'fury-admin-uploads-view',
    templateUrl: './admin-uploads-view.component.html',
    styleUrls: [ './admin-uploads-view.component.scss' ]
})
export class AdminUploadsViewComponent implements OnInit {

    uploads: UploadsView[];
    uploadsByDoctor: UploadsView[];
    barChart: any;
    maxTRUE;
    maxFALSE;
    dates;
    doctors: any[];
    selectedDoctor;
    rate = -1;

    constructor( public adminUploadViewService: AdminUploadsViewService ) {
    }

    ngOnInit() {
        this.adminUploadViewService.getUploadsByDoctors().subscribe(res => {
                this.uploads = res;
                const uploadsTRUE = map(this.uploads, 'count');
                this.uploadsByDoctor = this.uploads;
                this.maxTRUE = max(this.getCount(this.filterHprimFormat('TRUE')));
                this.maxFALSE = max(this.getCount(this.filterHprimFormat('FALSE')));
                this.dates = uniq(map(this.uploads, 'date'));
                this.doctors = this.getDoctorIdandName();

                this.initChart();
            }
        );


    }

    initChart() {
        Chart.defaults.groupableBar = Chart.helpers.clone(Chart.defaults.bar);
        Chart.controllers.groupableBar = Chart.controllers.bar.extend({
            calculateBarX: function ( index, datasetIndex ) {
                // position the bars based on the stack index
                const stackIndex = this.getMeta().stackIndex;
                return Chart.controllers.bar.prototype.calculateBarX.apply(this, [ index, stackIndex ]);
            },

            hideOtherStacks: function ( datasetIndex ) {
                const meta = this.getMeta();
                const stackIndex = meta.stackIndex;

                this.hiddens = [];
                for (let i = 0; i < datasetIndex; i++) {
                    const dsMeta = this.chart.getDatasetMeta(i);
                    if (dsMeta.stackIndex !== stackIndex) {
                        this.hiddens.push(dsMeta.hidden);
                        dsMeta.hidden = true;
                    }
                }
            },

            unhideOtherStacks: function ( datasetIndex ) {
                const meta = this.getMeta();
                const stackIndex = meta.stackIndex;

                for (let i = 0; i < datasetIndex; i++) {
                    const dsMeta = this.chart.getDatasetMeta(i);
                    if (dsMeta.stackIndex !== stackIndex) {
                        dsMeta.hidden = this.hiddens.unshift();
                    }
                }
            },

            calculateBarY: function ( index, datasetIndex ) {
                this.hideOtherStacks(datasetIndex);
                const barY = Chart.controllers.bar.prototype.calculateBarY.apply(this, [ index, datasetIndex ]);
                this.unhideOtherStacks(datasetIndex);
                return barY;
            },

            calculateBarBase: function ( datasetIndex, index ) {
                this.hideOtherStacks(datasetIndex);
                const barBase = Chart.controllers.bar.prototype.calculateBarBase.apply(this, [ datasetIndex, index ]);
                this.unhideOtherStacks(datasetIndex);
                return barBase;
            },

            getBarCount: function () {
                const stacks = [];

                // put the stack index in the dataset meta
                Chart.helpers.each(this.chart.data.datasets, function ( dataset, datasetIndex ) {
                    const meta = this.chart.getDatasetMeta(datasetIndex);
                    if (meta.bar && this.chart.isDatasetVisible(datasetIndex)) {
                        let stackIndex = stacks.indexOf(dataset.stack);
                        if (stackIndex === -1) {
                            stackIndex = stacks.length;
                            stacks.push(dataset.stack);
                        }
                        meta.stackIndex = stackIndex;
                    }
                }, this);

                this.getMeta().stacks = stacks;
                return stacks.length;
            },
        });

        const helpers = Chart.helpers;
        this.constructChart();

    }

    filterHprimFormat( filtered ) {
        const res = filter(this.uploadsByDoctor, el => el.hprim_format === filtered);
        return (res);
    }

    filterOnDoctor( doctor ) {
        const res = filter(this.uploadsByDoctor, el => el.doctor === doctor);
        return (res);
    }

    getCount( array ) {
        const res = map(array, 'count');
        return (res);
    }

    removeChoice() {
        this.uploadsByDoctor = this.uploads;
        this.selectedDoctor = 0;
        this.rate = -1;
        this.maxTRUE = max(this.getCount(this.filterHprimFormat('TRUE')));
        this.maxFALSE = max(this.getCount(this.filterHprimFormat('FALSE')));
        this.barChart.destroy();
        this.constructChart();
    }

    getDoctorName( doctorId ) {
        const res = uniq(map(filter(this.uploadsByDoctor, x => x.doctor === doctorId), 'name'));
        return (res);
    }

    onDoctorChange( change ) {
        this.uploadsByDoctor = filter(this.uploads, res => res.doctor === change.source.value);
        this.maxTRUE = max([ max(this.getCount(this.filterHprimFormat('TRUE'))), 0 ]);
        this.maxFALSE = max([ max(this.getCount(this.filterHprimFormat('FALSE'))), 0 ]);
        this.rate = sum(this.getCount(this.filterHprimFormat('TRUE'))) / (sum(this.getCount(this.filterHprimFormat('TRUE'))) +
            sum(this.getCount(this.filterHprimFormat('FALSE'))));
        this.rate = Math.round(this.rate * 100);
        this.barChart.destroy();
        this.constructChart();


    }

    getDoctorIdandName() {
        const doctor = uniq(map(this.uploads, 'doctor'));
        const result = [];
        doctor.forEach(( el ) => result.push({ doctor: el, name: this.getDoctorName(el) }));

        return (result);
    }


    constructData() {
        const res = {};
        res['labels'] = uniq(map(this.uploadsByDoctor, 'date'));
        const doctors = uniq(map(this.uploadsByDoctor, 'doctor'));
        const datasets = [];
        doctors.forEach(( el ) => {
            const doctorsData = this.filterOnDoctor(el);
            const doctorsDataTRUE = filter(doctorsData, t => t.hprim_format === 'TRUE');
            const doctorsDataFALSE = filter(doctorsData, t => t.hprim_format === 'FALSE');
            datasets.push({
                label: this.getDoctorName(el) + ' OK',
                backgroundColor: this.getRandomColor(el, 1),
                data: this.getCount(doctorsDataTRUE),
                stack: doctors.indexOf(el),
            });

            datasets.push({
                label: this.getDoctorName(el) + ' KO',
                backgroundColor: this.getRandomColor(el, 0.2),
                data: this.getCount(doctorsDataFALSE),
                stack: doctors.indexOf(el),
            });
        });
        res['datasets'] = datasets;
        return (res);
    }

    getRandomColor( id, opacity ) {
        const colorArray = [
            '#63b598', '#ce7d78', '#ea9e70', '#a48a9e', '#c6e1e8', '#648177', '#0d5ac1',
            '#f205e6', '#1c0365', '#14a9ad', '#4ca2f9', '#a4e43f', '#d298e2', '#6119d0',
            '#d2737d', '#c0a43c', '#f2510e', '#651be6', '#79806e', '#61da5e', '#cd2f00',
            '#9348af', '#01ac53', '#c5a4fb', '#996635', '#b11573', '#4bb473', '#75d89e',
            '#2f3f94', '#2f7b99', '#da967d', '#34891f', '#b0d87b', '#ca4751', '#7e50a8',
            '#c4d647', '#e0eeb8', '#11dec1', '#289812', '#566ca0', '#ffdbe1', '#2f1179',
            '#935b6d', '#916988', '#513d98', '#aead3a', '#9e6d71', '#4b5bdc', '#0cd36d',
            '#250662', '#cb5bea', '#228916', '#ac3e1b', '#df514a', '#539397', '#880977',
            '#f697c1', '#ba96ce', '#679c9d', '#c6c42c', '#5d2c52', '#48b41b', '#e1cf3b',
            '#5be4f0', '#57c4d8', '#a4d17a', '#225b8', '#be608b', '#96b00c', '#088baf',
            '#f158bf', '#e145ba', '#ee91e3', '#05d371', '#5426e0', '#4834d0', '#802234',
            '#6749e8', '#0971f0', '#8fb413', '#b2b4f0', '#c3c89d', '#c9a941', '#41d158',
            '#fb21a3', '#51aed9', '#5bb32d', '#807fb', '#21538e', '#89d534', '#d36647',
            '#7fb411', '#0023b8', '#3b8c2a', '#986b53', '#f50422', '#983f7a', '#ea24a3',
            '#79352c', '#521250', '#c79ed2', '#d6dd92', '#e33e52', '#b2be57', '#fa06ec',
            '#1bb699', '#6b2e5f', '#64820f', '#1c271', '#21538e', '#89d534', '#d36647',
            '#7fb411', '#0023b8', '#3b8c2a', '#986b53', '#f50422', '#983f7a', '#ea24a3',
            '#79352c', '#521250', '#c79ed2', '#d6dd92', '#e33e52', '#b2be57', '#fa06ec',
            '#1bb699', '#6b2e5f', '#64820f', '#1c271', '#9cb64a', '#996c48', '#9ab9b7',
            '#06e052', '#e3a481', '#0eb621', '#fc458e', '#b2db15', '#aa226d', '#792ed8',
            '#73872a', '#520d3a', '#cefcb8', '#a5b3d9', '#7d1d85', '#c4fd57', '#f1ae16',
            '#8fe22a', '#ef6e3c', '#243eeb', '#1dc18', '#dd93fd', '#3f8473', '#e7dbce',
            '#421f79', '#7a3d93', '#635f6d', '#93f2d7', '#9b5c2a', '#15b9ee', '#0f5997',
            '#409188', '#911e20', '#1350ce', '#10e5b1', '#fff4d7', '#cb2582', '#ce00be',
            '#32d5d6', '#17232', '#608572', '#c79bc2', '#00f87c', '#77772a', '#6995ba',
            '#fc6b57', '#f07815', '#8fd883', '#060e27', '#96e591', '#21d52e', '#d00043',
            '#b47162', '#1ec227', '#4f0f6f', '#1d1d58', '#947002', '#bde052', '#e08c56',
            '#28fcfd', '#bb09b', '#36486a', '#d02e29', '#1ae6db', '#3e464c', '#a84a8f',
            '#911e7e', '#3f16d9', '#0f525f', '#ac7c0a', '#b4c086', '#c9d730', '#30cc49',
            '#3d6751', '#fb4c03', '#640fc1', '#62c03e', '#d3493a', '#88aa0b', '#406df9',
            '#615af0', '#4be47', '#2a3434', '#4a543f', '#79bca0', '#a8b8d4', '#00efd4',
            '#7ad236', '#7260d8', '#1deaa7', '#06f43a', '#823c59', '#e3d94c', '#dc1c06',
            '#f53b2a', '#b46238', '#2dfff6', '#a82b89', '#1a8011', '#436a9f', '#1a806a',
            '#4cf09d', '#c188a2', '#67eb4b', '#b308d3', '#fc7e41', '#af3101', '#ff065',
            '#71b1f4', '#a2f8a5', '#e23dd0', '#d3486d', '#00f7f9', '#474893', '#3cec35',
            '#1c65cb', '#5d1d0c', '#2d7d2a', '#ff3420', '#5cdd87', '#a259a4', '#e4ac44',
            '#1bede6', '#8798a4', '#d7790f', '#b2c24f', '#de73c2', '#d70a9c', '#25b67',
            '#88e9b8', '#c2b0e2', '#86e98f', '#ae90e2', '#1a806b', '#436a9e', '#0ec0ff',
            '#f812b3', '#b17fc9', '#8d6c2f', '#d3277a', '#2ca1ae', '#9685eb', '#8a96c6',
            '#dba2e6', '#76fc1b', '#608fa4', '#20f6ba', '#07d7f6', '#dce77a', '#77ecca' ];

        let res = colorArray[id];
        res = this.convertHex(res, opacity);
        return (res);
    }

    convertHex( hex, opacity ) {
        hex = hex.replace('#', '');
        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);

        const result = 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
        return result;
    }

    constructChart() {
        // const data = {
        //   labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        //   datasets: [
        //     {
        //       label: 'My First dataset',
        //       backgroundColor: 'rgba(99,255,132,0.2)',
        //       data: [59, 80, 81, 56, 55, 40, 65],
        //       stack: 1
        //     },
        //     {
        //       label: 'My Second dataset',
        //       backgroundColor: 'rgba(99,132,255,0.2)',
        //       data: [80, 81, 56, 55, 40, 65, 60],
        //       stack: 2
        //     },
        //     {
        //       label: 'My Third dataset',
        //       backgroundColor: 'rgba(255,99,132,0.2)',
        //       data: [60, 59, 80, 81, 56, 55, 40],
        //       stack: 2
        //     }
        //   ]
        // };

        const data = this.constructData();
        let d = new Date(data['labels'][data['labels'].length - 1]);
        d.setDate(d.getDate() + 1);
        const maxDate = d.toString();


        d = new Date(data['labels'][0]);
        d.setDate(d.getDate() - 1);
        const minDate = d.toString();
        const canvas: any = document.getElementById('graphCanvas');
        const ctx = canvas.getContext('2d');
        this.barChart = new Chart(ctx, {
            type: 'groupableBar',
            data: data as any,
            options: {
                scales: {
                    yAxes: [ {
                        ticks: {
                            max: this.maxTRUE + this.maxFALSE,
                        },
                        stacked: true,
                    } ],
                    xAxes: [ {
                        type: 'time',
                        time: {
                            unit: 'day',
                            min: minDate,
                            max: maxDate,
                        },
                    }
                    ]
                }
            }
        });

    }


}
