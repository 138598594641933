import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { IUser } from '@app.models';

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    private url_login = environment.backend + 'login';
    private url_logout = environment.backend + 'api/logout';
    private token: string;
    private user: IUser;

    constructor( private http: HttpClient ) {
        this.token = localStorage.getItem('token');
        let currentUser = localStorage.getItem('currentUser')
        this.user = JSON.parse(currentUser);
        // if(currentUser === undefined){
        //   this.user = {}
        // }else{
           
        // }
       
    }

    login$(email: string, password: string) {
        return this.http.post<any>(this.url_login, { email, password }).pipe(
            tap(res => {
                this.token = res.token;
                this.user = res.user;
                localStorage.setItem('token', this.token);
                localStorage.setItem('currentUser', JSON.stringify(this.user));
            })
        );
    }

    async logout() {
        try {
            await this.http.post(this.url_logout, null, { headers: this.getAuthHeaders() }).toPromise();
        } catch (e) {
        }
        localStorage.removeItem('token');
        localStorage.removeItem('currentUser');
    }

    isAuthenticated(): boolean {
        return !!this.token;
    }

    getUser(): IUser {
        return this.user;
    }

    isAdmin(): boolean {
        const user = this.getUser();
        if (user) {
            return ((user.role[0] && user.role[0] === 'admin'));
        } else {
            return false;
        }
    }

    getBearerToken() {
        return this.token ? `bearer ${this.token}` : '';
    }

    getAuthHeaders() {
        return new HttpHeaders().set('Authorization', this.getBearerToken());
    }

}
