<div class="page__title">
  <mat-button-toggle-group class="toggle-group">
    <mat-button-toggle
            *ngFor="let tab of tabs" [checked]="tab.checked" [value]="tab.id"
            (change)="updateActiveChoice($event) ">
      {{tab.libelle}}
    </mat-button-toggle>
    
  </mat-button-toggle-group>
</div>
<div class="page__container">
  <div class="container">
    <div class="days__list">
      <h2><mat-icon>description</mat-icon> Mails du laboratoire</h2>
      <div class="scroll-active">
        <mat-list>
          <ng-container *ngFor="let date of differentDates">
            <div mat-subheader>
              {{date | date: 'fullDate' | titlecase }}
            </div>
            <ng-container *ngFor="let row of filterUploadsOnDate(date)">
              <mat-list-item *ngIf="row.date==date && row.name" (click)="clickOnExam(row)"
                             class="item__person" [class.active]="selectedExam === row">
                <mat-icon mat-list-icon [ngStyle]="{'color': row.Color}" matTooltip="id résultat: {{row.examId}}">
                  account_circle_outline
                </mat-icon>
                <div class="urgent"  [ngStyle]="{'font-weight':row.seen ===1? 'normal':'bold'}" *ngIf="row.n_urgent>0" mat-line>Urgent</div>
                <div [ngStyle]="{'font-weight':row.seen ===1? 'normal':'bold'}" mat-line>Nom: {{row.name | uppercase}}</div>
                <div [ngStyle]="{'font-weight':row.seen ===1? 'normal':'bold'}" mat-line>Prénom: {{row.firstname | titlecase}}</div>
                
              </mat-list-item>
            </ng-container>
            <mat-divider></mat-divider>
          </ng-container>
        </mat-list>
        <button mat-raised-button *ngIf="examsNotShowed>0" (click)="loadMoreResults()">
          Charger des résultats plus anciens ({{examsNotShowed}})
        </button>
      </div>
    </div>
    <div class="days__list column__measure" *ngIf="selectedExam">
      <div class="header__measures fxCenter" (click)="goToPatient(selectedExam.patientId)">
        <div class="fxCenter">
          <mat-icon color="accent">account_circle_outline</mat-icon>
          <b>{{selectedExam.name | titlecase}} {{selectedExam.firstname |titlecase}}</b>
          <div class="point"></div>
          <div>{{selectedExam.date | date: 'fullDate' | titlecase }}</div>
        </div>
        <button mat-button class="button-patient" (click)="goToPatient(selectedExam.patientId)">
          Voir la fiche du patient
        </button>
      </div>
      <div class="measures__table">
        <table mat-table matSort [dataSource]="mesures_ds">
          <ng-container matColumnDef="parameter">
            <th mat-header-cell  *matHeaderCellDef mat-sort-header> Analyse</th>
            <td mat-cell *matCellDef="let row">
              {{row.parameter}}
            </td>
          </ng-container>
          <ng-container matColumnDef="family">
            <th mat-header-cell matTooltip="Famille de l'analyse" *matHeaderCellDef mat-sort-header> Famille</th>
            <td mat-cell *matCellDef="let row">
              <p>{{row.family}}</p>
            </td>
          </ng-container>
          <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Résultat</th>
            <td mat-cell *matCellDef="let row">
              <p>{{row.value}} {{row.unit}}</p>
            </td>
          </ng-container>
          <ng-container matColumnDef="tolerance">
            <th mat-header-cell matTooltip="Valeurs seuils fournies par le laboratoire qui a émis ce résultat"
                *matHeaderCellDef mat-sort-header> Valeurs seuils
            </th>
            <td mat-cell *matCellDef="let row">
              <p>{{row.tolerance}}</p>
            </td>
          </ng-container>
          <ng-container matColumnDef="st_value">
            <th mat-header-cell
                matTooltip="Plus la gravité est élévée, plus la mesure est éloignée de l'optimum laboratoire"
                *matHeaderCellDef mat-sort-header> Gravité
            </th>
            <td mat-cell *matCellDef="let row">
              <p>{{row.st_value}}</p>
            </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
            <td mat-cell *matCellDef="let row">
              <strong>
                <mat-icon style="color:green" matTooltip="Le paramètres est compris entre dans les valeurs de référence"
                          *ngIf="row.in_tolerance==true">done
                </mat-icon>
                <mat-icon style="color:red" matTooltip="Le paramètre 
                est hors valeurs de référence"
                          *ngIf="row.in_tolerance==false">
                  report_problem
                </mat-icon>
                <mat-icon style="color:orange"
                          matTooltip="Aucune information sur les valeurs de référence n'est disponible pour ce résultat"
                          *ngIf="row.in_tolerance==undefined">help_outline
                </mat-icon>
              </strong>
            </td>
            
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columns_parameters; sticky:true"></tr>
          <tr (click)="goToPatient(selectedExam.patientId)"  mat-row *matRowDef="let row; columns: columns_parameters;"   ></tr>
        </table>
      </div>
      <div class="measures__table" *ngIf="showChart">
        <div id="chartdiv"></div>
      </div>
    </div>
  </div>
</div>
