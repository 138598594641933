export class PatientExam {
    id: number;
    patientId: string;
    doctorId: string;
    date: string;
    laboratory: string;
    file: string;
    created_at: string;
    modified_at: string;
    read: number;
}
