import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SidenavItem } from './sidenav-item.interface';

@Component({
    selector: 'app-sidenav-item',
    templateUrl: './sidenav-item.component.html',
    styleUrls: [ './sidenav-item.component.scss' ],
})
export class SidenavItemComponent {

    @Input() item: SidenavItem;
    @Input() count: number;

    constructor( private router: Router ) {
    }

    isActive() {
        if (this.item.routeOrFunction && typeof this.item.routeOrFunction !== 'function') {
            const url = this.router.parseUrl(this.item.routeOrFunction);
            return this.router.isActive(url,
                { paths: 'subset', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' });
        } else {
            return false;
        }
    }

    async handleClick() {
        if (typeof this.item.routeOrFunction === 'string' || this.item.routeOrFunction instanceof String) {
            await this.router.navigate([ this.item.routeOrFunction ]);
        } else if (typeof this.item.routeOrFunction === 'function' || this.item.routeOrFunction instanceof Function) {
            await this.item.routeOrFunction();
        } else {
            throw Error('Could not determine what to do, Sidenav-Item has no routeOrFunction set AND does not contain any subItems');
        }
    }

}
