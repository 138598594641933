<div mat-dialog-title>{{ editMode ? 'Edition de l\'événement' : 'Nouvel événement' }}</div>
<mat-dialog-content>
  <mat-form-field>
    <mat-label>Titre</mat-label>
    <input matInput placeholder="Décrivez le nom de l'événement" [(ngModel)]="event.event_label">
  </mat-form-field>
  <mat-form-field>
    <mat-label>Type</mat-label>
    <mat-select placeholder="Type de l'événement" [(ngModel)]="event.event_type">
      <mat-option *ngFor="let row of typeList" [value]="row.id">{{row.label}} </mat-option>
    </mat-select>
  </mat-form-field>
  <!--    Date picker comme dans google agenda pour début et fin -->
  <mat-form-field>
    <mat-label>Date de début</mat-label>
    <input [ngModel]="eventDate"
           (ngModelChange)="setDate($event)"
           placeholder="Sélectionner la date de début"
           [matDatepicker]="pickerStart" matInput>
    <button mat-icon-button matSuffix (click)="pickerStart.open()">
      <mat-icon>calendar_today</mat-icon>
    </button>
    <mat-datepicker #pickerStart></mat-datepicker>
  </mat-form-field>
  <!-- <mat-form-field>
    <mat-label>Date de fin</mat-label>
    <input [(ngModel)]="event.date"
           placeholder="Sélectionner la date de fin"
           [matDatepicker]="pickerStart" matInput>
    <button mat-icon-button matSuffix (click)="pickerStart.open()">
      <mat-icon>calendar_today</mat-icon>
    </button>
    <mat-datepicker #pickerStart></mat-datepicker>
  </mat-form-field> -->
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-dialog-close="null" mat-stroked-button>Annuler</button>
  <button mat-raised-button color="primary" (click)="saveEvent(event)">Enregistrer</button>
</mat-dialog-actions>
