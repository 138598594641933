import { ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { filter, map, uniq, groupBy, orderBy } from '@app.utils';
import { IFamilyGroup, Patient, PatientEvents, PatientQuali, ResultDerive } from '@app.models';
import { IWinUserVM, PatientService, UserStateService } from '@app.services';
import { ActivatedRoute } from '@angular/router';

interface IA {
    title: string;
}

interface IParameterDef {
    date: Date;
    criteriaList: any[];
}

const list = [
    { parameter: 'Présence de schizocytes', value: '' },
    { parameter: 'Présence de corps de heinz', value: '' },
    { parameter: 'Morphologie des plaquettes', value: '' },
    { parameter: 'Présence de blastes ( texte )', value: '' },
];

const criteriaList = [
    { parameter: 'RAI', value: 'Présence' },
    { parameter: 'Groupe sanguin', value: '---------' },
    { parameter: 'RH1', value: '---------' },
    { parameter: 'RH2', value: '---------' },
];

@Component({
    selector: 'app-dashboard-patient',
    templateUrl: './dashboard-patient.component.html',
    styleUrls: [ './dashboard-patient.component.scss' ]
})
export class DashboardPatientComponent implements OnInit, OnDestroy {

    patient: Patient;
    eventList: PatientEvents[];
    resultDerive: ResultDerive[];
    disabledLaunchButton = false;
    patientId: string;
    activeTabId = 1;

    tabs: { name, id, checked }[] = [
        { name: 'Écart statistiques', id: 1, checked: true },
        { name: 'Estimations', id: 2, checked: false },
        { name: 'Evénements', id: 4, checked: false },
        { name: 'Mesures cliniques', id: 5, checked: false },
        { name: 'Comptes-rendus augmentés', id: 3, checked: false }
    ];

    @ViewChild('hemato') hemato: TemplateRef<any>;
    @ViewChild('biochimie') biochimie: TemplateRef<any>;

    windowList: IWinUserVM[];
    windowListVM: { tpl: IA, win: IWinUserVM, groupDateList: IParameterDef[] }[];
    windowListOpenedVM: { tpl: IA, win: IWinUserVM, groupDateList: IParameterDef[] }[];

    constructor( private patientService: PatientService,
                 private route: ActivatedRoute,
                 private cdr: ChangeDetectorRef,
                 private userState: UserStateService ) {
    }

    async ngOnInit() {
        await this.init();
    }

    ngOnDestroy() {
        this.userState.saveFor(this.windowList, this.patientId);
    }

    getEstimationStatus() {
        if (this.activeTabId == 1) {
            return 0;
        } else if (this.activeTabId == 2) {
            return 1;
        }
    }

    async init() {
        const id = this.patientId = this.route.snapshot.paramMap.get('id');
        this.patient = await this.patientService.getPatientById(id).toPromise();
        this.eventList = await this.patientService.getPatientEventsById(this.patient).toPromise();
        const labelById: { [key: string]: IA } = {
            'hemato': { title: 'Hématologie' },
            'biochimie': { title: 'Biochimie' },
            'micro': { title: 'Microbiologie' },
            'medicaments': { title: 'Médicaments' },
            'auto': { title: 'Auto' },
            'hla': { title: 'HLA' },
            'fertilite': { title: 'Fertilité' },
        };
        this.windowList = this.userState.getFor(id);
        this.windowListVM = this.windowList.map(x => ({
            win: x,
            tpl: labelById[x.id],
            groupDateList: this.getList(this.patient.quali, x.id)
        })).filter(x => !!x.tpl);
        this.windowListOpenedVM = this.windowListVM
            .filter(x => x.win.opened || this.patient.quali.some(y => y.analyzed === '0' && y.family == x.win.id));
    }

    async close( winVM: IWinUserVM, evt ) {
        winVM.opened = false;
        this.userState.saveFor(this.windowList, this.patientId);
        const ids = this.patient.quali.filter(y => y.analyzed === '0' && y.family == winVM.id).map(x => x.id);
        await this.patientService.windowHasBeenRead$(ids).toPromise();
        this.windowListOpenedVM = this.windowListOpenedVM.filter(x => x.win !== winVM);
    }

    openWindow( familyGroup: IFamilyGroup ) {
        const winVM = this.windowListVM.find(x => x.win.id == familyGroup.id);
        if (winVM && this.windowListOpenedVM.indexOf(winVM) < 0) {
            this.windowListOpenedVM = [...this.windowListOpenedVM, winVM];
            winVM.win.opened = true;
            this.userState.saveFor(this.windowList, this.patientId);
            this.cdr.detectChanges();
        }
    }

    storeSize( winVM: IWinUserVM, evt ) {
        winVM.position = evt.position;
    }

    getListeCorrelations( patientId ): void {
        this.patientService.getListCorrelations(patientId).subscribe(res => {
            this.resultDerive = res;
        });
    }

    launchAnalyze( patient ): void {
        this.disabledLaunchButton = true;
        this.patientService.launchAnalyze(patient.id).subscribe(res => {
            this.disabledLaunchButton = false;
        });
        this.getListeCorrelations(this.patient.id);
    }

    launchEstimationParametres( patient ): void {
        this.patientService.launchEstimationParametres(patient.Numpatient).subscribe();
    }

    launchEstimationArchetypes( patient ): void {
        this.patientService.launchEstimationArchetypes(patient.Numpatient).subscribe();
    }

    getDeriveBasse(): ResultDerive[] {
        return filter(this.resultDerive, [ 'etat_derive', 'Derive basse' ]);
    }

    getDeriveHaute(): ResultDerive[] {
        return filter(this.resultDerive, [ 'etat_derive', 'Derive haute' ]);
    }

    getDeriveImportanteBasse(): ResultDerive[] {
        return filter(this.resultDerive, [ 'etat_derive', 'Derive Importante basse' ]);
    }

    getDeriveImportanteHaute(): ResultDerive[] {
        return filter(this.resultDerive, [ 'etat_derive', 'Derive Importante haute' ]);
    }

    getDeriveBonneBasse(): ResultDerive[] {
        return filter(this.resultDerive, [ 'etat_derive', 'Bon bas' ]);
    }

    getPossibleDeriveBasse(): ResultDerive[] {
        return filter(this.resultDerive, [ 'etat_derive', 'Possible derive basse' ]);
    }

    getPossibleDeriveHaute(): ResultDerive[] {
        return filter(this.resultDerive, [ 'etat_derive', 'Possible derive haute' ]);
    }

    getDeriveBonneHaute(): ResultDerive[] {
        return filter(this.resultDerive, [ 'etat_derive', 'Bon haut' ]);
    }

    getDeriveExcellente(): ResultDerive[] {
        return filter(this.resultDerive, [ 'etat_derive', 'Excellent' ]);
    }

    getFamilles(): string[] {
        return uniq(map(this.resultDerive, 'famille'));
    }

    getDerives(): string[] {
        return uniq(map(this.resultDerive, 'etat_derive'));
    }

    getParametersByDeriveAndFamilles( derive: string, famille: string ) {
        return filter(this.resultDerive, { etat_derive: derive, famille: famille });
    }

    private getList( quali: PatientQuali[], family: string ) {
        const qualiList = quali.filter(x => x.family == family).map(x => {
            return {
                date: new Date(x.date),
                item: x,
            };
        });

        const grouped = groupBy(orderBy(qualiList, 'date', 'desc'), (x) => x.date.toString());
        const hematoList = [];
        // tslint:disable-next-line:forin
        for (const i in grouped) {
            const myList = grouped[i].map((x: any) => ({ parameter: x.item.parameter, value: x.item.value }));
            hematoList.push({ date: i, list: myList });
        }
        return hematoList;
    }

}
