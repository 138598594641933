<mat-button-toggle-group name="fontStyle" aria-label="Font Style">
  <mat-button-toggle *ngFor="let tab of tabs"
                     [checked]="tab.checked"
                     [value]="tab.id"
                     (change)="activeTabId = $event.source.value">
    {{tab.name}}
  </mat-button-toggle>
</mat-button-toggle-group>

<mat-card>
  <app-file-import *ngIf="activeTabId==1"  ></app-file-import>
  <fury-imported-files *ngIf="activeTabId==2"></fury-imported-files>
</mat-card>
