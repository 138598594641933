export const MESSAGES = {
    PASSWORD_CONFIRM: 'Mot de passe (confirmer)',
    PLEASE_REPEAT_PASSWORD_ABOVE: 'Veuillez réecrire votre mot de passe',
    CREATE_ACCOUNT: 'Créer',
    ALREADY_HAVE_ACCOUNT: `J'ai déja un compte`,
    FIRST_NAME: `Prénom`,
    SIGN_IN_HERE: `Se connecter`,
    BACK_BUTTON_TEXT: `Retour`,
    NEXT_BUTTON_TEXT: `Suivant`,
    ADD_PART_BUTTON_TEXT: `Ajouter l'article`,
    SAVE_PART_BUTTON_TEXT: `Enregistrer`,
    CANCEL_BUTTON_TEXT: `Annuler`,
    SELECT_BUTTON_TEXT: `Sélectionner`,
    VALIDATE_BUTTON_TEXT: `Valider`,

    NAME: `Nom`,
    PASSWORD: `Mot de passe`,
    EMAIL: `E-mail`,
    LOGIN: `Connexion`,
    SIGNUP: `Inscription`,

    LOGIN_TITLE: `Connexion`,
    LOGIN_ERROR: `Connexion impossible. Veuillez vérifier les informations de votre compte et réessayer.`,
    LOGIN_BUTTON: `Connexion`,
    SIGNUP_TITLE: `Inscription`,
    SIGNUP_ERROR: `Inscription impossible. Veuillez vérifier les informations de votre compte et réessayer.`,
    SIGNUP_BUTTON: `Inscription`,

    MENU_CONNECT: `Connexion`,
    MENU_MAIN: `Accueil`,

    DONE_BUTTON: `Ok`,
    CANCEL_BUTTON: `Annuler`,
    DELETE_BUTTON: `Supprimer`,

    EVENT_SAVED: `L'événement a été enregistré`,
    EVENT_DELETED: `L'événement a été supprimé`,
    MEASURE_SAVED: `La mesure a été enregistrée`,
    MEASURE_DELETED: `La mesure a été supprimée`,

};

