import { Injectable } from '@angular/core';
import { OsConfigData } from './commons';

@Injectable({
    providedIn: 'root'
})
export class OsConfigService {

    private _config: OsConfigData = {
        theme: 'arc',
        variant: 'light'
    };
    private _zIndex = 1;

    constructor() {
    }

    getConfig(): OsConfigData {
        return this._config;
    }

    setConfig( config: OsConfigData ) {
        this._config = config;
    }

    getZIndex(): number {
        return this._zIndex;
    }

    setZIndex( zIndex: number ) {
        this._zIndex = zIndex;
    }
}
