
  <!--   <mat-form-field>
      <mat-label>Patient</mat-label>
      <mat-select [(value)]="selectedPatient">
        <mat-option *ngFor="let patient of filteredList" [value]="patient">
          {{patient.Numpatient}}
        </mat-option>
      </mat-select>
    </mat-form-field> -->


  <div class="mat-elevation-z8">


    <table mat-table matSort [dataSource]="dataSource" >

      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

      <!-- Position Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
        <td mat-cell *matCellDef="let row"> {{row.id}} </td>
      </ng-container>

      <ng-container matColumnDef="from_file">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nom du fichier </th>
        <td mat-cell *matCellDef="let row"> {{row.from_file}} </td>
      </ng-container>

      <ng-container matColumnDef="hprim_format">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Format Hprim Correct </th>
        <td mat-cell *matCellDef="let row"> {{row.hprim_format}} </td>
      </ng-container>

      <ng-container matColumnDef="hprim_status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Erreur </th>
        <td mat-cell *matCellDef="let row"> {{row.hprim_status}} </td>
      </ng-container>

      <ng-container matColumnDef="patient">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Id Patient </th>
        <td mat-cell *matCellDef="let row"> {{row.patient}} </td>
      </ng-container>

      <ng-container matColumnDef="to_import">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> A importer </th>
        <td mat-cell *matCellDef="let row"> {{row.to_import}} </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
    </table>
    <mat-paginator [pageSize]=50 [pageSizeOptions]="[5, 10, 20, 100]" showFirstLastButtons></mat-paginator>
  </div>



<!-- <div class="upload-table">
  <table class="table">
    <thead >
        <tr>
            <th>Patient</th>
            <th>Sexe</th>
            <th>Poids</th>
            <th>Taille</th>
            <th>Groupe Sanguin</th>
        </tr>

    </thead>
    <tbody class="upload-name-style">


        <tr *ngFor="let patient of filteredList" (click)="updateSelectedPatient(patient)" >

            <td><strong>{{ patient.Numpatient }} </strong></td>
            <td>{{ patient.SEXE }} </td>
            <td>{{ patient.Poids }} </td>
            <td>{{ patient.Taille }}</td>
            <td>{{ patient.groupeSanguin }} </td>

        </tr>
    </tbody>
</table>
</div>


<app-dashboard-patient *ngIf='selectedPatient' [patient]="selectedPatient"></app-dashboard-patient>-->
