<mat-card>
  <h2>Import fichier CSV: chapitres et sous chapitres </h2>
  <p>
    Status transcodification: En cours
  </p>

  <div [formGroup]="form">
    <div class="form-group">
      <input type="file" hidden="true" (change)="onFileChange($event)" multiple="true" #fileInput>
    </div>
  </div>

  <div class="center">
    <ngx-file-drop (onFileDrop)="dropped($event)"
                   (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)" (click)="uploadFileclick()">
      <span>Déposez le(s) fichier(s) ici</span>
    </ngx-file-drop>
    <button mat-button color="yellow">Exporter CSV Chapitres/Sous-chapitres transcodés</button>
  </div>
</mat-card>

<mat-card>
  <h2>Import fichier CSV: examens prescriptibles </h2>
  <p>
    Status transcodification: En cours
  </p>

  <div [formGroup]="form">
    <div class="form-group">
      <input type="file" hidden="true" (change)="onFileChange($event)" multiple="true" #fileInput>
    </div>
  </div>

  <div class="center">
    <ngx-file-drop (onFileDrop)="dropped($event)"
                   (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)" (click)="uploadFileclick()">
      <span>Déposez le(s) fichier(s) ici</span>
    </ngx-file-drop>
    <button mat-button color="yellow">Exporter CSV examens prescriptibles transcodés</button>
  </div>
</mat-card>

<mat-card>
  <h2>Import fichier CSV: analyses résultats </h2>
  <p>
    Status transcodification: En cours
  </p>

  <div [formGroup]="form">
    <div class="form-group">
      <input type="file" hidden="true" (change)="onFileChange($event)" multiple="true" #fileInput>
    </div>
  </div>

  <div class="center">
    <ngx-file-drop (onFileDrop)="dropped($event)"
                   (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)" (click)="uploadFileclick()">
      <span>Déposez le(s) fichier(s) ici</span>
    </ngx-file-drop>
    <button mat-button color="yellow">Exporter CSV analyses résultats transcodés</button>
  </div>
</mat-card>

