import { Injectable } from '@angular/core';
import { Observable, of, zip } from 'rxjs';
import { ExamResults, Patient, PatientEvents, PatientExam, PatientListItem, PatientMeasure, ResultDerive, toExamResult } from '@app.models';
import { RestApiService } from '../rest.api.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class PatientService {

    constructor( private restApi: RestApiService ) {
    }

    getListPatients(): Observable<PatientListItem[]> {
        return this.restApi.$get<PatientListItem[]>('api/listpatient');
    }

    getListPatientsByDoctorId( doctorId ): Observable<PatientListItem[]> {
        return this.restApi.$get<PatientListItem[]>('api/getDoctorListFromAdmin/' + doctorId);
    }

    getPatientById( patientId ): Observable<Patient> {
        return this.restApi.$get<Patient>('api/getpatientbyid/' + patientId).pipe(
            map(u => {
                return {
                    ...u,
                    birthdate: new Date(u.birthdate),
                    created_at: new Date(u.created_at),
                };
            })
        );
    }

    createEvent$( event: Partial<PatientEvents> ): Observable<PatientEvents> {
        return this.restApi.$post<PatientEvents>('api/create-patient-event', event).pipe(
            map(list => list[0])
        );
    }

    updateEvent$( event: PatientEvents ): Observable<PatientEvents> {
        return this.restApi.$put<PatientEvents>(`api/update-patient-event/${event.id}`, event);
    }

    windowHasBeenRead$( idList: string[] ): Observable<any> {
        return this.restApi.$post<any>(`api/update-exam-result-read`, idList);
    }

    deleteEvent$( eventId: number ): Observable<any> {
        return this.restApi.$delete(`api/delete-event/${eventId}`);
    }

    getMeasureList$( patientId: number ): Observable<PatientMeasure[]> {
        return this.restApi.$get(`api/get-patient-measure-all/${patientId}`);
    }

    createMeasure$( measure: Partial<PatientMeasure> ): Observable<PatientMeasure> {
        return this.restApi.$post<PatientMeasure>('api/create-patient-measure', measure);
    }

    updateMeasure$( measure: PatientMeasure ): Observable<PatientMeasure> {
        return this.restApi.$put<PatientMeasure>(`api/update-patient-measure/${measure.id}`, measure);
    }

    deleteMeasure$( measureId: number ): Observable<PatientMeasure> {
        return this.restApi.$delete(`api/delete-measure/${measureId}`);
    }

    getPatientEventsById( patient: { events } ): Observable<PatientEvents[]> {
        return of(patient.events || []);
    }

    getListCorrelations( patientId: string ): Observable<ResultDerive[]> {
        return this.restApi.$post<ResultDerive[]>('api/getcorrelation', { valeur: patientId });
    }

    launchAnalyze( patient: string ): Observable<string[]> {
        return this.restApi.$post<string[]>('api/launchanalyse', { patient });
    }

    launchEstimationParametres( patient: string ): Observable<string[]> {
        return this.restApi.$post<string[]>('api/estimationparametres', { patient });
    }

    launchEstimationArchetypes( patient: string ): Observable<string[]> {
        return this.restApi.$post<string[]>('api/estimationarchetype', { patient });
    }

    /**
     * Used in "Compte rendus augmenté"
     * @param examId
     */
    getExamResultList( examId: number ): Observable<ExamResults[]> {
        return this.restApi.$get<ExamResults[]>('api/get-exam-results/' + examId).pipe(
            map(x => (x || []).map(toExamResult))
        );
    }

    getExamListFor( patientId ): Observable<PatientExam[]> {
        return this.restApi.$get<PatientExam[]>('api/getpatientexam/' + patientId);
    }

    getPatientExamResults() {
        return this.restApi.$get<any>('api/getpatientexamresults');
    }

}
